import { customerRoutes, customerRedirects } from './customer-routes'
import manseRoutes from './manse-routes'
import MansePage from '@/pages/manse/index.vue'
import MypagePage from '@/pages/mypage/inquiryView.vue'

export const redirects = [...customerRedirects]
export const routes = [
  {
    path: '/manse/:id/:pgmode',
    component: MansePage,
    meta: {
      layout: 'manse',
    },
  },

  {
    path: '/manse/:id',
    component: MansePage,
    meta: {
      layout: 'manse',
    },
  },
  {
    path: '/manse',
    component: MansePage,
    meta: {
      layout: 'manse',
    },
  },
  {
    path: '/mypage/inquiryView/:boardId/:id',
    component: MypagePage,
    meta: {
      layout: 'mypage',
    },
  },
  ...manseRoutes,
  ...customerRoutes,
  {
    path: '/schedule_app',
    component: () => import('@/pages/schedule_app/index.vue'),
  },
]
