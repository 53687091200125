<script setup>
  import { getGrouplist, saveGroup, delGroup, modifyGroup } from '@/api/UserGroup';

  //popup
  import PopGroupDel from '@/pages/saju/components/PopGroupDel.vue';

  const isDialogVisibleSetting = ref(true);
  const emit = defineEmits();

  const popGroupName = ref('');
  const delSelectedId = ref(null);
  const isPopGroupDelBox = ref(false);

  const close = () => {
    emit('close');
  };
  const listData = ref({});
  const fixedUserGroupIds = ref(null);
  const getGroupListData = async () => {
    try {
      const params = {};
      const data = await getGrouplist(params); // 그룹리스트 조회
      listData.value = data;
      //01> 미지정 그룹 아이디 가져오기
      fixedUserGroupIds.value = listData.value.filter(item => item.isFixed).map(item => item.userGroupId);
    } catch (error) {
      console.error('Failed to load users:', error);
    }
  }
  onMounted(async () => {
    await getGroupListData();
  });
  const change = (selectItem) => {
    const selectedGroup = selectItem.userGroupId;
    const filteredData = listData.value.filter((item) => item.userGroupId === selectedGroup);
    emit('change', filteredData);
  }

  const handleAdd = () => {
    if(popGroupName.value === '' || popGroupName.value === null){
      alert('그룸명을 등록해주세요.');
      popGroupName.focus();
      return;
    }
    const formParams = {};
    formParams.name = popGroupName.value;
    saveGroup(formParams)
      .then(() => {
        alert('등록 되었습니다.');
        popGroupName.value = '';
        getGroupListData();
        emit('refresh');
      })
      .catch((error) => {
        alert(error.response.data?.message);
      });
  }

  function openDelmsg (id) {
    isPopGroupDelBox.value = true;
    delSelectedId.value = id;
  }
  function handleDel(){
    const formParams = {};
    formParams.groupIds = delSelectedId.value;
    formParams.undesignated = fixedUserGroupIds.value;

    delGroup(formParams)
      .then(() => {
        dcancdelPopGroup();
        getGroupListData();
        emit('refresh');
      })
      .catch((error) => {
        alert(error.response.data?.message);
      });
  }
  function dcancdelPopGroup (){
    isPopGroupDelBox.value = false;
  }

  const editingIndex = ref(-1);
  function handleMod(index) {
    if (editingIndex.value === index) {
      editingIndex.value = -1;
    } else {
      // 편집 모드로 변경
      editingIndex.value = index;
    }
  }

  function handleModSave(id, index) {
    const { name } = listData.value[index];
    if (name === '' || name === null) {
      alert('그룹명을 등록해주세요.');
      return;
    }
    const formParams = {};
    formParams.name = String(name);
    formParams.userGroupId = parseInt(id, 10);

    modifyGroup(formParams)
      .then(() => {
        alert('수정 되었습니다.');
        popGroupName.value = '';
        getGroupListData();
        emit('refresh');
        editingIndex.value = -1;
      })
      .catch((error) => {
        alert(error.response.data?.message);
      });
  }


</script>
<template>
  <VDialog
    v-model="isDialogVisibleSetting"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
  >
    <VCard class="br16">
      <DialogCloseBtn
        variant="text"
        size="default"
        @click="close"
      />
      <div
        class="pop-inner"
      >
        <div class="pop-tit-box">
          <div class="">
            그룹관리
          </div>
          <div
            class="close-button"
            @click="close()"
          >
            Close
          </div>
        </div>
        <div class="pop-body pd-large">
          <div class="pop-search-box">
            <VTextField
              v-model="popGroupName"
              class="input-box"
              variant="underlined"
              clearable
              maxlength="15"
              placeholder="그룹명을 입력해주세요"
              @keyup.enter="getUserGroupList(isListType)"
              @focus="wordFocus=true"
              @blur="wordFocus=false"
            ></VTextField>
            <VBtn
              :class="[ 'obtn',{ 'disabled-button': !popGroupName }]"
              :disabled="!popGroupName"
              @click="handleAdd()"
            >
              그룹추가
            </VBtn>
          </div>
          <div
            v-if="listData?.length > 0"
            class="group-set-list scroll"
          >
            <div
              v-for="(item, index) in listData"
              :key="index"
              class="area-item"
            >
              <div
                v-if="editingIndex === index"
                class="name"
              >
                <VTextField
                  v-model="item.name"
                  class="input-box"
                  variant="underlined"
                  clearable
                  maxlength="15"
                  @keydown="event => handleKeyDown2(event, item)"
                ></VTextField>
              </div>
              <div
                v-else
                class="name"
              >
                {{ item.name }}
              </div>
              <div
                v-if="!item.isFixed"
                class="btn-r"
              >
                <button
                  v-if="editingIndex === -1 || editingIndex !== index"
                  class="btn-sm"
                  @click="handleMod(index)"
                >
                  수정
                </button>
                <button
                  v-if="editingIndex === index"
                  class="btn-sm sumbit"
                  @click="handleModSave(item.userGroupId, index)"
                >
                  확인
                </button>
                <button
                  v-if="editingIndex === -1 || editingIndex !== index"
                  class="btn-sm orange2"
                  @click="openDelmsg(item.userGroupId)"
                >
                  삭제
                </button>
                <button
                  v-if="editingIndex === index"
                  class="btn-sm orange2"
                  @click="editingIndex=-1"
                >
                  취소
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </VCard>

  </VDialog>

  <!-- 그룹 삭제확인 메세지 -->
  <PopGroupDel
    v-if="isPopGroupDelBox"
    @del="handleDel()"
    @dcancel="dcancdelPopGroup()"
  />
</template>