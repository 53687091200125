<template>
  <span class="icon">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M19.7557 5.74227C18.7797 4.95251 17.5456 4.55233 16.2919 4.619C15.0381 4.68567 13.8535 5.21445 12.9667 6.10327L12.0397 7.03027L11.8167 6.80727L11.1167 6.10727C10.2573 5.15849 9.05748 4.58808 7.7791 4.52045C6.50072 4.45282 5.24746 4.89347 4.2927 5.74627C3.84572 6.19321 3.49114 6.72381 3.24923 7.30779C3.00732 7.89177 2.88281 8.51767 2.88281 9.14977C2.88281 9.78187 3.00732 10.4078 3.24923 10.9918C3.49114 11.5757 3.84572 12.1063 4.2927 12.5533L5.2197 13.4803L12.0267 20.2873L18.8287 13.4763L19.7557 12.5493C20.6185 11.626 21.0984 10.4094 21.0984 9.14577C21.0984 7.8821 20.6185 6.66558 19.7557 5.74227Z"
        fill="currentColor"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>

  </span>
</template>

<script>
export default {
  name: 'IconLike',
};
</script>
