<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_7321_71761)">
        <path d="M2.5 5.83464C2.5 5.39261 2.67559 4.96868 2.98816 4.65612C3.30072 4.34356 3.72464 4.16797 4.16667 4.16797H15.8333C16.2754 4.16797 16.6993 4.34356 17.0118 4.65612C17.3244 4.96868 17.5 5.39261 17.5 5.83464V14.168C17.5 14.61 17.3244 15.0339 17.0118 15.3465C16.6993 15.659 16.2754 15.8346 15.8333 15.8346H4.16667C3.72464 15.8346 3.30072 15.659 2.98816 15.3465C2.67559 15.0339 2.5 14.61 2.5 14.168V5.83464Z" fill="#575757" stroke="#575757" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M3.33301 6.25L9.99967 10.8333L16.6663 6.25" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_7321_71761">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </span>
</template>
