<script setup>
const emit = defineEmits()

const PopMansePrintBox = ref(true)
const close = () => {
  emit('close')
}
function open() {
  PopMansePrintBox.value = true
}
const printFrame = ref(null)

function printDiv() {
  const printWindow = printFrame.value.contentWindow || printFrame.value.contentDocument.defaultView
  printWindow.print()
}
</script>
<template>
  <VDialog
    v-model="PopMansePrintBox"
    width="659px"
    height="932px"
    max-width="100%"
    class="dialog-st10"
    :style="{ overflow: 'hidden', 'user-select': 'none' }"
    @click:outside="close"
  >
    <VCard class="br16">
      <div class="pop-pr-inner">
        <div class="pop-tit-box flex-sb pop-right-close">
          <div class="pre-tit">사주 결과 미리보기</div>
          <div class="com-btn-box">
            <button class="sBtn h26" type="button" @click="printDiv">출력하기</button>
          </div>
          <DialogCloseBtn class="dialog-close" variant="text" size="default" @click="close" />
        </div>
        <div class="pop-body-1">
          <div class="print-wrap">
            <iframe
              ref="printFrame"
              name="printDiv"
              src="/manse/print"
              width="100%"
              frameborder="0"
              allowfullscreen
              style="overflow: hidden; display: block; width: 100%; height: 100%"

            />
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
<style scoped>
.dialog-content {
  max-height: 500px; /* 원하는 최대 높이 설정 */
  overflow: hidden; /* 스크롤바 숨기기 */
}
</style>
