<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import IconCheck01 from '@/components/svg/IconCheck01.vue'
import IconCheck02 from '@/components/svg/IconCheck02.vue'

const router = useRouter()
const goRepo = () => {
  router.push('/mypage/subscrRepo')
}
const goPoint = () => {
  router.push('/mypage/subscrPoint')
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">서비스 안내</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>서비스 안내</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-tit-box">
        <div class="tit">낭만 저장소 & 요금 안내</div>
        <div class="path">
          <ul>
            <li>
              한번 구독으로 앱과 PC버전 만세력을 한번에 편리하게 이용할 수 있어요. 정기구독으로
              편리하게 만세력 서비스를 이용하세요!
            </li>
          </ul>
        </div>
      </div>
      <div class="gap-xxxl"></div>
      <div class="tb-relative">
        <table class="tb-subscr">
          <colgroup>
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th>제공 서비스</th>
              <th class="th-point">
                <div class="tb-red-board"></div>
                저장소 회원
              </th>
              <th>일반 회원</th>
              <th>게스트</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">역학달력</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 역학달력, 사주시계</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck01 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">사주 입력/조회</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 역학달력, 사주시계</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck01 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">보안 저장소</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 사주저장, 정보수정</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-gray"><IconCheck02 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">그룹 만들기</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 기본그룹, 즐겨찾기, 그룹추가관리</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black">기본그룹 제공</span>
              </td>
              <td>
                <span class="icon-gray"><IconCheck02 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">메모 작성</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 메모 등록, 관리</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black">간단 메모 제공</span>
              </td>
              <td>
                <span class="icon-gray"><IconCheck02 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">공유하기</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 복사 공유, SNS 공유</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-gray"><IconCheck01 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">인쇄하기</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- 인쇄출력 A, B, C, D type</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black">기본형 제공</span>
              </td>
              <td>
                <span class="icon-black">기본형 제공</span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">라이프 캘린더</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">- My 라이프 캘린더</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black"><IconCheck02 /></span>
              </td>
              <td>
                <span class="icon-gray"><IconCheck02 /></span>
              </td>
            </tr>
            <tr>
              <td>
                <div class="my-tb-flex">
                  <span class="tit1">사주 분석</span><i class="icon-search-sm"></i>
                </div>
                <div class="txt1">사주 분석 이용권 제공</div>
              </td>
              <td>
                <span class="icon-red"><IconCheck01 /></span>
              </td>
              <td>
                <span class="icon-black">이용권 구매 시 사용</span>
              </td>
              <td>
                <span class="icon-gray"><IconCheck02 /></span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="gap-xxxl"></div>
      <div class="subscr-desc-point">
        <i class="icon-point"></i>
        <div class="txt1">
          <span>낭만저장소/컨텐츠 이용 시</span> <span class="txt3">충천 포인트 사용</span>
          <div class="txt2" @click="goPoint">지금 바로 포인트 충전하러 가기</div>
        </div>
      </div>
      <div class="gap-xxxl"></div>
      <div class="com-btn-box">
        <VBtn type="submit" style="width: 260px" @click="goRepo"> 결제 바로 가기 </VBtn>
      </div>
    </div>
  </div>
</template>
