import { createStore } from "vuex"

// Import modules
// import AppModule from "./modules/app"
import UserModule from "./modules/user"
import UserManager from "./modules/queries/users-manager";
import boards from "./modules/boards"
// import ErrorModule from "./modules/error"
// import QueriesModule from "./modules/queries"

const store = createStore({
  modules: {
    // app: AppModule,
    user: UserModule,
    UserManager,
    boards,
    // error: ErrorModule,
    // queries: QueriesModule,
  },
  getters: {
    params: (state) => state.params,
  },
  state: {
    params: null,
    menu_roles: {},
  },
  mutations: {
    setParams(state, payload) {
      state.params = payload
    },
  },
})

export default store
