<script setup>
import { useRouter } from "vue-router"
import { useStore } from "vuex"
import articleApi from "@/api/ArticleApi"
import boardApi from "@/api/BoardApi"
import { BoardTitleBox } from "@/components/board"

const props = defineProps({
  board: {
    type: String,
    default: "",
  },
  boardId: {
    type: Number,
    default: 0,
  },
  articleId: {
    type: String,
    default: 0,
  },
})

const router = useRouter();
const store = useStore()
const userId =  store.getters['user/id']

const selectedCategory = ref(null);
const categoryData = ref([]);
const articleTitle = ref(null);
const articleContent = ref(null);

const defaultOption = { categoryId: null, categoryCtn: '카테고리' };
const formattedCategoryData = computed(() => {
  return categoryData.value.map(category => ({
    categoryId: category.categoryId,
    categoryCtn: category.categoryCtn
  }));
});

const contentPlaceholder =
`1. 개인정보(이름, 연락처, 주민번호 등)가 포함되지 않도록 작성해 주세요.
2. 문의하기에서는 사주 결과를 제공하지 않습니다.
3. 등록된 글은 1:1 문의 리스트에서 확인해 주세요.`

onMounted(async () => {
  await getCategory();
});

/** 게시판 카테고리 요청 */
const getCategory = async () => {
  try {
    const result = await boardApi().categoryById(props.boardId);
    categoryData.value = result;
  } catch (error) {
    alert(error.response.data?.message);
  }
};

/** 취소 버튼 클릭 */
const goViewList = () => {
  router.push(`/customer/${props.board}`);
};

/** 등록 버튼 클릭 */
const save = async () => {
  if (!articleTitle.value || !selectedCategory.value || !articleContent.value) {
    alert('모든 필드를 채워주세요.');
    return;
  }

  let article = {
    userId,
    boardId: props.boardId,
    systemId: import.meta.env.VITE_APP_SYSTEMID,
    articleTtl: articleTitle.value,
    articleCtn: articleContent.value,
    articleType: `${selectedCategory.value}`,
    boardCategory: {
      categoryId: selectedCategory.value,
    },
    files: [],
  };

  try {
    await articleApi().save(article);
    alert('접수 되었습니다.');
    goViewList();
  } catch (e) {
    alert(e.message);
  }
};
</script>

<template>
  <div class="">
    <BoardTitleBox :board="board" page-type="write"/>

    <div class="board-view-tit-box st-reg">
      <div class="tit">제목</div>
      <div class="input-form">
        <VSelect
          class="select-box"
          v-model="selectedCategory"
          :items="[defaultOption, ...formattedCategoryData]"
          item-title="categoryCtn"
          item-value="categoryId"
          />
        <VTextField
          v-model="articleTitle"
          type="text"
          class="input-box"
          placeholder="제목을 입력해주세요."
          clearable
        />
      </div>
    </div>

    <div class="essay-content-box st-reg">
      <div class="tit">내용</div>
      <div class="input-form">
        <v-textarea
          v-model="articleContent"
          :placeholder="contentPlaceholder"
          outlined
          class="textarea custom-textarea"
        />
      </div>
    </div>

    <section class="btn-com gap-xl right">
      <button class="btn-line w-small bg-w" @click="goViewList()">취소</button>
      <button class="btn-line w-small bg-orange" @click="save()">등록</button>
    </section>
  </div>
</template>