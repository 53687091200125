<script setup>

definePage({ meta: { layout: 'manse' } })

const router = useRouter()
const goToHome = () => {
    router.push('/main');
}
const goToSaju = () => {
    router.push('/manse');
}

onMounted(() => {
    history.pushState(null, null, location.href);
    window.onpopstate = () => {
    history.go(1);
  };
})

onUnmounted(() => {
  // onpopstate 리스너 제거
  window.onpopstate = null;
});
</script>

<template>
<div class="withdraw-body">
    <div class="notice-withdraw">
    <div class="top">
        <div class="logo"></div>
        <div class="tit">회원 탈퇴가 완료되었습니다</div>
        <div class="txt">
            낭만 만세력을 이용해주셔서 감사합니다.<br />
            더 나은 서비스로 찾아뵙겠습니다
        </div>
        <div @click="goToSaju" class="guest-mode">게스트로 만세력 보기</div>
    </div>
        <button
            class="home"
            @click="goToHome">
        홈으로 이동
        </button>
    </div>
</div>

</template>
<style lang="scss" scoped>
.withdraw-body{
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    .notice-withdraw{
        width: 560px;
        height: 431px;
        display: inline-flex;
        padding: 60px 80px;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        border-radius: 20px;
        border: 1px solid var(--Gray-300, #EBEBEB);
        background: var(--gray-100-white, #FFF);
        .logo{
            background: url('@manseImages/logo_b.png') center center no-repeat;
            width: 140px !important;
            height: 53px !important;
            background-size: contain;
            margin-bottom: 40px;
        }
        .tit{
            color: var(--gray-900-black, #2C2C2E);
            font-family: Pretendard;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
            margin-bottom: 16px;
        }
        .txt{
            color: var(--Gray-700, #575757);
            text-align: center;
            font-family: Pretendard;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: -0.375px;
            margin-bottom: 32px;
        }
        .guest-mode{
            cursor: pointer;
            color: var(--orange-300-main, #EB4C10);
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 18px;
            letter-spacing: -0.35px;
            text-decoration-line: underline;
        }
        .home{
            display: flex;
            width: 400px;
            padding: 14px 20px;
            justify-content: center;
            align-items: center;
            gap: 4px;
            border-radius: 6px;
            background: var(--orange-300-main, #EB4C10);
            color: var(--gray-100-white, #FFF);
            text-align: center;
            font-family: Pretendard;
            font-size: 15px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
        }
    }
}
.top{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    width: 100%;
    height: 223px;
    }
</style>