<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_5006_86735)">
      <path
        d="M4.41387 1.99961L8.30176 5.8875L4.41387 9.77538"
        stroke="currentColor"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5006_86735">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script>
export default {
  name: "IconNext",
}
</script>
