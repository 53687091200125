<script setup>
import { getUserInfoAPI, updateAdditionalInfoAPI } from '@/api/myPage';
import {
  CardContainer,
  CardHeader,
  CardItem,
  BloodType,
  MBTI,
  Survey,
  MoreinfoNotice,
} from '@/components/mypage';
import { formatDateTime } from '@/composables/dataFormatter';
import { DEV_MODE } from '@/main';
import { onMounted } from 'vue';

definePage({ meta: { layout: 'mypage' } });

// Card의 동작 상태
// 'view': 데이터 표시 모드 / 'edit': 수정 모드
const mbtiCardMode = ref('view');
const bloodTypeCardMode = ref('view');
const joinPathMode = ref('view');
const interestMode = ref('view');
const understandingMode = ref('view');
const surveyCardMode = computed(() => {
  const state = joinPathMode.value === 'view'
    || interestMode.value === 'view'
    || understandingMode.value === 'view';
  if (state) return 'view';
  else return 'edit';
});

/** 'MBTI' 데이터 */
const userMbti = ref('');
/** '혈액형' 데이터 */
const userBloodType = ref({
  bloodType: '',
  rh: '',
});
const userJoinPath = ref('');
const userInterest = ref('');
const userUnderstanding = ref('');
/** '최종수정일' 데이터 객체 */
const updatedAtObject = ref({
  mbti: '',
  bloodType: '',
  joinPath: '',
  interest: '',
  understanding: '',
})

onMounted(async () => {
  await refreshUpdatedAt({});
});

/** updatedAt 갱신 함수 */
const refreshUpdatedAt = async ({code}) => {  
  try {
    const result = await getUserInfoAPI();
    
    result.additionalInfos.forEach((info) => {
      const { code: infoCode, value, updatedAt } = info;

      switch(infoCode) {
        case 'mbti':
          if (!code || code === 'mbti') {
            userMbti.value = value || '';
            updatedAtObject.value.mbti = formatDateTime(updatedAt);
          }
          break;
        case 'blood_type':
          if (!code || code === 'blood_type') {            
            userBloodType.value.bloodType = value || '';            
            updatedAtObject.value.bloodType = formatDateTime(updatedAt);
          }
          break;
        case 'rh':
          if (!code || code === 'rh') {
            userBloodType.value.rh = value || '';
            updatedAtObject.value.rh = formatDateTime(updatedAt);
          }
          break;
        case 'join_path':
          if (!code || code === 'join_path') {
            userJoinPath.value = value || null;
            updatedAtObject.value.joinPath = updatedAt;
          }
          break;
        case 'interest':
          if (!code || code === 'interest') {
            userInterest.value = value || null;
            updatedAtObject.value.interest = updatedAt;
          }
          break;
        case 'understanding':
          if (!code || code === 'understanding') {
            userUnderstanding.value = value || null;
            updatedAtObject.value.understanding = updatedAt;
          }
          break;
        default:
          break;
      }
    });
  } catch (e) {
    DEV_MODE && console.log('Error refreshing updatedAt:', e);
  }
};

/** 부가정보 수정 요청 */
const updateAdditionalInfo = async ({data}) => {
  try {
    await updateAdditionalInfoAPI({data});
    await refreshUpdatedAt({code: data[0].code});
  } catch (e) {
    DEV_MODE && console.log('moreinfo updateAdditionalInfo error:', e);
  }
}

/** 최신 survey 업데이트일 계산 */
const latestSurveyUpdate = computed(() => {
  const dateStrings = [
    updatedAtObject.value.joinPath,
    updatedAtObject.value.interest,
    updatedAtObject.value.understanding,
  ].filter(dateStr => dateStr);  

  const validDates = dateStrings
    .filter(str => str) // 빈 문자열 제거
    .map(str => new Date(str))
    .filter(date => !isNaN(date)); // 유효한 날짜만

  if (validDates.length === 0) return null;

  const latestDate = new Date(Math.max(...validDates));

  // UTC 시간에 9시간을 더함
  const utc = latestDate.getTime() + (latestDate.getTimezoneOffset() * 60000);
  const kstTime = new Date(utc + (9 * 60 * 60 * 1000));

  const year = kstTime.getFullYear();
  const month = String(kstTime.getMonth() + 1).padStart(2, '0');
  const day = String(kstTime.getDate()).padStart(2, '0');
  const hour = String(kstTime.getHours()).padStart(2, '0');
  const minute = String(kstTime.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day} ${hour}:${minute}`;
});
</script>

<template>
  <CardContainer>
    <CardHeader title="부가 정보">
      <template v-slot:path>
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>MY</li>
          <li>부가 정보</li>
        </ul>
      </template>
    </CardHeader>

    <CardItem 
      title="MBTI(성격유형)"
      :updated-at="updatedAtObject.mbti"
      :card-mode="mbtiCardMode"
    >
      <template v-slot:icon></template>
      <template v-slot:content>
        <MBTI
          v-model="mbtiCardMode"
          :user-mbti="userMbti"
          @update-mbti="updateAdditionalInfo"
        />
      </template>
    </CardItem>

    <CardItem
      title="혈액형"
      :updated-at="updatedAtObject.bloodType"
      :card-mode="bloodTypeCardMode"
    >
      <template v-slot:content>
        <BloodType
          v-model="bloodTypeCardMode"
          :user-blood-type="userBloodType"
          @update-blood-type="updateAdditionalInfo"
        />
      </template>
    </CardItem>

    <CardItem
      title="기타"
      :updated-at="latestSurveyUpdate"
      :card-mode="surveyCardMode"
    >
      <template v-slot:content>
        <Survey
          :join-path-mode="joinPathMode"
          :interest-mode="interestMode"
          :understanding-mode="understandingMode"
          :user-join-path="userJoinPath"
          :user-interest="userInterest"
          :user-understanding="userUnderstanding"
          @update:joinPathMode="joinPathMode = $event"
          @update:interestMode="interestMode = $event"
          @update:understandingMode="understandingMode = $event"
          @update-survey="updateAdditionalInfo"
        />
      </template>
    </CardItem>

    <CardItem>
      <template v-slot:content>
        <MoreinfoNotice />
      </template>
    </CardItem>
  </CardContainer>
</template>

<style scoped>
@media (max-width: 960px) {
  .card-content {
    flex: 1 1 100%; /* 작은 화면에서는 한 줄에 하나씩 배치 */
  }
}
</style>