<script setup>
import {popupData as detailExpList} from '../data/popupExp.js'

const emit = defineEmits()
const props = defineProps({
    currentCardIndex: {
        type: Number,
        required: true
    }
})

const gellaryBackExp = ref(detailExpList);

const PopManseDetailBox = ref(true)
const close = () => {
  emit('close')
}
function open() {
  PopManseDetailBox.value = true
}
// const Titles = ref(['합', '충', '형/파/해', '신살/공망/태월', '육친', '원진/귀문/현침']) // '현침' 임시 비활성화
const Titles = ref(['합', '충', '형/파/해', '신살', '육친', '원진/귀문/공망/태월'])

const index = ref(0);

/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault();
};

watch(
  () => props.currentCardIndex,
  (newIndex) => {
    index.value = newIndex;
  },
  { immediate: true }
);

onMounted(() =>{
  document.addEventListener("contextmenu", disableRightClick);
  index.value = props.currentCardIndex
  
})

onBeforeUnmount(() => {
  document.removeEventListener("contextmenu", disableRightClick);
});

const tabClick = (newIndex) =>{
    index.value = newIndex;
}

// const activeIndex = ref(0); // tab 사용시 (index로 쓰고 있는 것들 중 activeIndex로 바꿔야하는 것들 있음)

const goToNext = () => {
  if (index.value < Titles.value.length - 1) {
    index.value++;
  }
};



</script>
<template>
  <VDialog
    v-model="PopManseDetailBox"
    width="800px"
    height="848px"
    max-width="100%"
    class="dialog-st10 dialog-saju-keyword"
    :style="{ overflow: 'hidden' }"
  >
    <VCard class="br16">
      <div class="pop-cd-inner">
        <div class="pop-tit-box flex-sb pop-right-close">
          <div class="detail-tit">{{ Titles[index]}}</div>
          <!-- <DialogCloseBtn class="dialog-close" variant="text" size="default" @click="close" /> -->
          <button class="dial-close" @click="close"></button>
        </div>
        <div class="pop-detail-body scroll">
            <!-- <div class="tab-title">
                <div v-for="(title, tabIndex) in Titles" :key="tabIndex" class="keyword-title" @click="tabClick(tabIndex)">{{ title }}</div>
            </div> -->
        
        <div class="">
            <div v-for="(item, i) in gellaryBackExp[index]?.popupContent" :key="i" class="data-exp-box">
                <span class="title">{{ item.title }}</span>
                <div v-html="item.content" class="content-d"></div>
            </div>
            <div class="next-btn-area">
              <!-- <button class="next-title" @click="goToNext">{{ Titles[index + 1] }}에 대한 설명으로 넘어가기</button> -->
            </div>
        </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>
<style lang="scss" scoped>
.tab-title{
    display: flex;
    flex-direction: row;
}
.keyword-title{
    background-color: #ebebeb;
    margin: 0px 3px;
    cursor: pointer;
    border-radius: 10px;
}
</style>
