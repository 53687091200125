<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_1_2395)">
        <line
          x1="16.0607"
          y1="16"
          x2="20.6569"
          y2="20.5962"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <circle cx="10.5" cy="10.5" r="7.5" stroke="currentColor" stroke-width="1.5" />
      </g>
      <defs>
        <clipPath id="clip0_1_2395">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </span>
</template>
