<script setup>
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

//push 테스트

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const props = defineProps({
  prData: {
    type: Object,
    default: null,
  },
  prIndex: {
    type: Number,
    default: 0,
  },
  prGalleryDataTitle: {
    type: Array,
    default: [],
  },
  prBirthSkyTxt: {
    type: Array,
    default: [],
  },
  prBirthEarthTxt: {
    type: Array,
    default: [],
  },
})

const skyTxt = ref(props.prBirthSkyTxt)
const earthTxt = ref(props.prBirthEarthTxt)

onMounted(() => {
  //dataSet(props.prDataAll); // 초기 데이터 설정
})

watch(
  () => props.prBirthSkyTxt,
  (newVal) => {
    skyTxt.value = newVal
  },
  { deep: true },
)

watch(
  () => props.prBirthEarthTxt,
  (newVal) => {
    earthTxt.value = newVal
  },
  { deep: true },
)

/** 조사 구분 */
// 한글 유니코드 값을 통해 종성 유무를 인식하여 '이' 또는 '가'
const checkJongsung = (name) =>{
  const lastChar = name.charCodeAt(name.length - 1);
  const isThereLastChar = (lastChar - 0xac00) % 28;
  return isThereLastChar ? '이' : '가';
}

</script>
<template>
  <div
    class="data-detail-top"
    :class="prIndex === 5 && (subIndex === 0 || subIndex === 1) ? 'col2' : ''"
    v-for="(item, subIndex) in prData"
    :key="subIndex"
  >
    <span class="txt1" v-if="prIndex !== 3">{{ prGalleryDataTitle[prIndex][subIndex] }}</span>
    <span v-if="Array.isArray(item) && item.length > 0 && item[0][0] === 'none'" class="data-none none-gap">
      {{ prGalleryDataTitle[prIndex][subIndex] }}{{checkJongsung(prGalleryDataTitle[prIndex][subIndex])}} 없습니다.
    </span>
    <span v-else>
      <div class="" v-if="prIndex !== 5">
        <div
          class="card-top-4fr-list"
          v-for="(subItem, ssubIndex) in item.slice(0, -1)"
          :key="ssubIndex"
        >
          <span v-for="(value, valueIndex) in subItem" :key="valueIndex">
            <span v-if="ssubIndex === 0 && valueIndex < 4">
              <span v-if="prIndex === 2 && subIndex === 0">
                <span
                  class="txt2 txt-round detail"
                  :class="[
                    five.getColorFive(earthTxt[valueIndex]),
                    prLangMode === 'CHNKOR' ? 'chnkor' : '',
                    item[item.length - 1][valueIndex] === 0 ? 'none' : '',
                  ]"
                  >{{ earthTxt[valueIndex] }}</span
                >
              </span>
              <span v-else>
                <span v-if="prIndex === 3">{{ valueIndex }} {{ earthTxt }}</span>
                <span
                  class="txt2 txt-round detail"
                  :class="[
                    five.getColorFive(skyTxt[valueIndex]),
                    prLangMode === 'CHNKOR' ? 'chnkor' : '',
                    item[item.length - 1][valueIndex] === 0 ? 'none' : '',
                  ]"
                  v-if="subIndex === 0"
                  >{{ skyTxt[valueIndex] }}</span
                >
              </span>

              <span
                class="txt2 txt-round detail"
                :class="[
                  five.getColorFive(earthTxt[valueIndex]),
                  prLangMode === 'CHNKOR' ? 'chnkor' : '',
                  item[item.length - 1][valueIndex] === 0 ? 'none' : '',
                ]"
                v-if="subIndex === 1"
                >{{ earthTxt[valueIndex] }}</span
              >
            </span>
            <span class="txt3" v-if="prIndex !== 4">
              <span v-if="subIndex === 1 && prIndex === 3"></span>
              <span v-else>{{ value }}</span>
            </span>
          </span>
        </div>
      </div>
      <div class="detail-gap"></div>

      <div class="card-top-4fr-list" v-if="prIndex === 3 || prIndex === 4">
        <div class="" v-for="(subItem, ssubIndex) in item" :key="ssubIndex">
          <div v-for="(item, index) in subItem" :key="index">
            <div>
              <span v-for="(value, valueIndex) in item" :key="valueIndex">
                {{ value }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-top-4fr-list" v-if="prIndex === 5">
        <div class="" v-for="(subItem, ssubIndex) in item" :key="ssubIndex">
          <div v-for="(item, index) in subItem" :key="index">
            <div>
              <span v-if="subIndex === 0 || subIndex === 1">
                <span v-for="(value, valueIndex) in item" :key="valueIndex">
                  <span
                    class="txt2 txt-round detail"
                    :class="[five.getColorFive(value), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
                    >{{ value }}</span
                  >
                </span>
              </span>
              <span v-else>
                <span v-for="(value, valueIndex) in item" :key="valueIndex">
                  {{ value }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>
