import { getUserInfoAPI } from '@/api/myPage';
import { DEV_MODE } from '@/main';
import { defineStore } from 'pinia';

export const useMyInfoStore = defineStore('myinfo', () => {
  const myInfo = reactive({
    name: '',
    nickname: '',
    isUseNickname: false,
    displayName: '',
  });

  /** 이름 또는 닉네임 중 선택된 항목 */
  const displayName = computed(() => {
    // return myInfo.isUseNickname ? myInfo.nickname : 
    return myInfo.name;
  });

  const setMyInfo = ({name, nickname, isUseNickname}) => {
    myInfo.name = name;
    myInfo.nickname = nickname;
    myInfo.isUseNickname = isUseNickname;
  };

  const fetchMyInfo = async () => {
    try {
      const result = await getUserInfoAPI();

      if (result) {
        setMyInfo({
          name: result.name,
          nickname: result.nickname,
          isUseNickname: result.isUseNickname,
        });
      } else {
        setMyInfo({
          name: '회원',
          nickname: '',
          isUseNickname: false,
        });
      };
    } catch (e) {
      DEV_MODE && console.log('useMyInfoStore fetchMyInfo error:', e);
    }
  };

  return {
    displayName,
    myInfo,
    setMyInfo,
    fetchMyInfo
  };
});