import axios from "axios"

const boardApi = () => {
  const endpoint = import.meta.env.VITE_APP_GW_URL
  const systemId = import.meta.env.VITE_APP_SYSTEMID

  const findAll = async () => {
    const response = await axios.get(`${endpoint}/board`)
    return response.data
  }

  const findById = async (boardId) => {
    const response = await axios.get(`${endpoint}/board/${boardId}/systems/${systemId}`)
    return response.data
  }

  /** 게시판 카테고리 요청 */
  const categoryById = async (boardId) => {
    const response = await axios.get(
      `${endpoint}/board/${boardId}/systems/${systemId}/boardCategorys`
    )
    return response.data
  }

  return {
    findAll,
    findById,
    categoryById,
  }
}

export default boardApi
