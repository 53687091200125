<script setup>
import IconGooglePlay from '@/components/svg/IconGooglePlay.vue';
import IconAppStore from '@/components/svg/IconAppStore.vue';

function openGooglePlay() {
  window.open('https://play.google.com/store/apps/details?id=com.twofivedosajuapp&hl=ko');
}

function openAppStore() {
  window.open('https://apps.apple.com/kr/app/%EB%82%AD%EB%A7%8C-%EB%A7%8C%EC%84%B8%EB%A0%A5/id6462405118')
}

function openPage(page){
  window.open(`/documents/${page}`);
}
</script>
<template>
  <div class="footer-wrap">
    <div class="inner-base">
      <section class="address-sec">
        <div class="ft-logo">
          <i class="logo">낭만 만세력</i>
        </div>
        <div class="info-area">
          <ul>
            <li><em>주식회사 단감소프트</em></li>
            <li><em>대표이사</em>임성웅</li>
            <li>
              <em>주소</em> 경기도 수원시 영통구 신원로 55, 709호, 710호(신동, 테크트리 영통)
            </li>
          </ul>
          <ul>
            <li><em>대표번호 </em>070-5165-3635</li>
            <li><em>팩스 </em>070-5165-0777</li>
            <li><em>사업자등록번호 </em>303-81-79157</li>
            <li><em>통신판매업 신고번호 </em>제 2023-수원영통-1500호</li>
          </ul>
        </div>
        <div class="copyright">Copyright ⓒ DANGAMSOFT All Right Reserved</div>
      </section>
      <section class="info-sec">
        <div class="link-area">
          <ul>
            <!-- <li>
              <span style="cursor: pointer;" @click="openPage('ServiceIntro')">서비스소개</span>
            </li> -->
            <li>
              <!-- <router-link to="/documents/Usage"> 이용약관 </router-link> -->
               <span style="cursor: pointer;" @click="openPage('Usage')">이용약관</span>
            </li>
            <li>
              <!-- <router-link to="/documents/Privacy"> 개인정보처리방침 </router-link> -->
              <span style="cursor: pointer;" @click="openPage('Privacy')">개인정보처리방침</span>
            </li>
            <li>
              <router-link to="/customer/notice"> 낭만센터 </router-link>
            </li>
          </ul>
        </div>
        <div class="store-area">
          <div class="google-play" style="cursor: pointer;" @click="openGooglePlay">
            <IconGooglePlay class="goggle-icon" />
          </div>
          <div class="app-store" style="cursor: pointer;" @click="openAppStore">
            <IconAppStore />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
