<script setup>
import moment from 'moment'
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

import PrintItemComponent from '@/pages/manse/components/PrintItemComponent.vue'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()

definePage({ meta: { layout: 'print' } })

onMounted(() => {
  setData() // 초기 데이터 설정
})

const dataOrder = ref(['luck', 'hour', 'day', 'month', 'year'])
const dataOrderTit = ref(['현재대운', '시', '일', '월', '년'])
const rsData = ref({})
const rsFormData = ref({})
const firstSegment = ref({})
const secondSegment = ref({})
const thirdSegment = ref({})

const formattedDate = () => {
  const curDate = moment()
  return curDate.format('YYYY.MM.DD HH:mm:ss')
}

const currentDate = ref(formattedDate())

const gongmangTxt = (gong) => {
  return gong?.map((item) => item?.value).join('')
}
const taeTxt = (tae) => {
  return tae?.map((item) => item?.value).join('')
}

function setData() {
  const sortMode = sessionStorage.getItem('sortMode')
  if (sortMode === 'asc') {
    dataOrder.value = [dataOrder.value[0], ...dataOrder.value.slice(1).reverse()]
    dataOrderTit.value = [dataOrderTit.value[0], ...dataOrderTit.value.slice(1).reverse()]
  }

  rsData.value = JSON.parse(sessionStorage.getItem('printData'))
  rsFormData.value = JSON.parse(sessionStorage.getItem('printformData'))

  if (rsData.value) {
    const totalLength = rsData.value.hundredsyears.reduce(
      (acc, item) => acc + (item.value ? item.value.length : 0),
      0,
    )

    const segmentLength = Math.ceil(totalLength / 3)
    firstSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter((v) => v.age <= segmentLength)
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])
    secondSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter(
        (v) => v.age > segmentLength && v.age <= segmentLength * 2,
      )
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])

    thirdSegment.value = rsData.value?.hundredsyears.reduce((acc, item) => {
      const filteredValue = item.value.filter((v) => v.age > segmentLength * 2)
      if (filteredValue.length > 0) {
        acc.push({ ...item, value: filteredValue })
      }
      return acc
    }, [])
  }
}
</script>
<template>
  <div class="print-wrap1 printDiv">
    <div class="inner-box">
      <div class="logo" />
      <div class="name-box">
        <div class="name-age-box">
          <div v-if="rsData.inputdata?.name" class="name3">
            {{ rsData.inputdata?.name }} ({{
              rsData?.inputdata?.gender === 1 || rsData?.inputdata?.gender === '1' ? '남' : '여'
            }})
          </div>
          <div v-else class="name3">
            - ({{
              rsData?.inputdata?.gender === 1 || rsData?.inputdata?.gender === '1' ? '남' : '여'
            }})
          </div>
          <div class="age">
            <!-- {{ inputdata.name }} -->
            <!-- {{ rsData?.transdata?.age }} 세 -->
            {{ rsData?.transdata?.kor_age }}세 (만{{ rsData?.transdata?.man_age }}세)
          </div>
        </div>
        <div class="birth-box">
          <div class="">
            <span class="tits orange">양력</span>
            <span class="txts ml5"
              >{{ useFun.formatCustomDate(rsData?.transdata?.solar) }} ({{
                rsData?.transdata?.week
              }}) 
              
              <!-- {{ useFun.getTimeTxt2(rsData?.inputdata?.hour) }}:{{
                useFun.getTimeTxt2(rsData?.inputdata?.min)
              }} -->

              {{useFun.getBirthTimeTxt(useFun.getTimeTxt2(rsData?.inputdata?.hour) + ':' + useFun.getTimeTxt2(rsData?.inputdata?.min)) }}
            </span>
          </div>
          <div class="">
            <span class="tits blue">음력</span>
            <span class="txts ml5">{{ useFun.formatCustomDate(rsData?.transdata?.lunar) }}</span>
          </div>
          <div class="w-wide">
            <span class="tits">옵션</span>
            <span class="txts ml5">
              <span v-if="rsData?.transdata?.bornArea?.county">
                {{ rsData?.transdata?.bornArea.county }}, {{ rsData?.transdata?.bornArea.city }},
              </span>
              {{ useFun.formatOption4Txt(rsFormData?.option4) }}
              {{ useFun.formatOption1Txt(rsFormData?.option1) }}
              {{ useFun.formatOption2Txt(rsFormData?.option2) }}
            </span>
          </div>
        </div>
      </div>

      <div class="print-hd-box" style="flex-wrap: wrap">
        <div
          class="pt-hd-wrap"
          style="width: 100%; display: flex; justify-content: space-between; align-items: center"
        >
          <div class="left-table" style="width: 35%">
            <table width="100%" class="prtTable">
              <tr>
                <td v-for="(item, index) in dataOrderTit" :key="index" class="fntTit">
                  {{ item }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dataOrder"
                  :key="index"
                  class="fntNor"
                  bgcolor="#F8F8F8"
                >
                  {{ rsData?.birthganji?.sky[item]?.six }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dataOrder"
                  :key="index"
                  style="color: #fff"
                  class="h-big"
                  :class="five.getColorFive(rsData?.birthganji?.sky?.[item]?.value)"
                >
                  {{ rsData?.birthganji?.sky?.[item]?.value }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dataOrder"
                  :key="index"
                  style="color: #fff"
                  class="h-big"
                  :class="five.getColorFive(rsData?.birthganji?.earth?.[item]?.value)"
                >
                  {{ rsData?.birthganji?.earth?.[item]?.value }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dataOrder"
                  :key="index"
                  class="fntNor"
                  bgcolor="#F8F8F8"
                >
                  {{ rsData?.birthganji?.earth[item]?.six }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dataOrder"
                  :key="index"
                  class="fntNor"
                  bgcolor="#F8F8F8"
                >
                  {{ rsData?.birthganji?.earth[item]?.twelve }} /
                  {{ rsData?.birthganji?.earth[item]?.sal[0] }}
                </td>
              </tr>
              <tr>
                <td
                  v-for="(item, index) in dataOrder"
                  :key="index"
                  class="fntNor"
                  bgcolor="#F8F8F8"
                >
                  <div
                    :class="
                      rsData.jijanggan?.[item]?.jiji?.length > 2 ? 'txt-space' : 'txt-space two'
                    "
                  >
                    <span v-for="(item2, index2) in rsData.jijanggan?.[item]?.jiji" :key="index2">
                      {{ item2 }}
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="right-table" style="width: 62%">
            <!-- <div class="first-daeun">대운최초변기일 : 1975년 11월 14일</div> -->
            <div style="display: flex">
              <table
                v-for="(item, index) in rsData?.daeuns?.sky"
                :key="index"
                class="prtTable pd-none st02"
                width="10%"
                :class="{ active: rsData.selectdaeun === item.num }"
              >
                <tr>
                  <td
                    class="fntNor"
                    :class="{ fntActive: rsData.selectdaeun === item.num }"
                    bgcolor="#F8F8F8"
                  >
                    {{ item.num }}
                  </td>
                </tr>
                <tr>
                  <td class="fntNor-Y">
                    {{ item.year }}
                  </td>
                </tr>
                <tr>
                  <td class="fntNor02">
                    {{ item.six }}
                  </td>
                </tr>
                <tr>
                  <td class="fntBig">
                    <span
                      class="ganji-txt"
                      :class="[five.getFiveClass(five.getGanjiColorFive(item?.value))]"
                    >
                      {{ item.value }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="fntBig">
                    <span
                      class="ganji-txt"
                      :class="[
                        five.getFiveClass(five.getGanjiColorFive(rsData.daeuns.earth[index].value)),
                      ]"
                    >
                      {{ rsData.daeuns.earth[index].value }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="fntNor02">
                    {{ rsData.daeuns.earth[index].six }}
                  </td>
                </tr>
              </table>
            </div>

            <div class="daeun-etc-line">
              <div class="gong-tae">
                <span>공망 : {{ gongmangTxt(rsData.gongmang) }}</span>
                <div class="gong-tae-line"></div>
                <span>태월 : {{ taeTxt(rsData.taewon) }}</span>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="print-btm-box">
        <div class="box01">
          <PrintItemComponent class="box-box" :pr-item="firstSegment" />
        </div>
        <div class="box01">
          <PrintItemComponent class="box-box" :pr-item="secondSegment" />
        </div>
        <div class="box01">
          <PrintItemComponent class="box-box" :pr-item="thirdSegment" />
        </div>
        <div class="table-bottom-line"></div>
        <div class="print-btm-box-3">
        <div class="date-btm-box">출력일 : {{ currentDate }}</div>
        <!-- <div class="logo" /> -->
      </div>
      </div>

  
    </div>
  </div>
</template>
<style>
body {
  -webkit-print-color-adjust: exact;
  padding: 0;
  background: transparent;
}
.v-application {
  background: transparent;
  padding: 0;
  margin: 0;
}
/* 스크롤바 스타일 적용 */
body::-webkit-scrollbar {
  width: 6px;
}

body::-webkit-scrollbar-track {
  border-radius: 12px;
  background: #FFF;
}

body::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background: #EBEBEB;
}

@media print {
  body{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .print-wrap1{
    margin: 0 !important;
    padding: 0 !important;
    height: auto !important;
    transform: scale(1.1);
    transform-origin: center top !important;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-top: 10px !important;
    padding: 0;
  }
  .inner-box{
    height: auto;
  }
  /* .v-application__wrap {
    display: block;
  } */
}
</style>
