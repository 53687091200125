<script setup>
const popSearchNoneBox = ref(true)

const emit = defineEmits(["close"])

const close = () => {
  popSearchNoneBox.value = false
  emit("close")
}
</script>

<template>
  <div>
    <v-dialog v-model="popSearchNoneBox" max-width="325px" content-class="dialog-st1">
      <div class="pop-inner none-pd" style="height: 176px">
        <div class="pop-msg-box">
          <div class="msg-body-box">
            <div>검색 결과가 없습니다.<br />검색어를 확인하고 다시 검색해 주세요.</div>
          </div>
          <div class="msg-btm-box">
            <button class="com-btn-nor" @click="close">확인</button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
