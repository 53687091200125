import axios from 'axios'

class AuthApi {
  constructor() {
    this.endpoint = import.meta.env.VITE_APP_GW_URL
    this.systemId = import.meta.env.VITE_APP_SYSTEMID
  }

  async login(username, password) {
    const params = new URLSearchParams()
    params.append('grant_type', 'password')
    params.append('client_id', 'dangam')
    params.append('client_secret', 'dg2021')
    params.append('username', username)
    params.append('password', password)
    params.append('system_id', this.systemId)

    //01> 토큰생성
    const response1 = await this.userToken(params)
    let userData = { ...response1 }
    return userData

    //02> 전체메뉴리스트받아오기(Tree)
    //const response2 = await this.treeMenu(response1)
    //02> 메뉴권한받아오기(Auth)
    //const response3 = await this.userMenu(response1)

    //useCookie("accessToken").value = response1.access_token

    //let userData = { ...response1, treeMenu: response2, userMenu: response3 }
  }

  //01> 액세스토큰
  async userToken(params) {
    return axios.post(`${this.endpoint}/oauth/token`, params).then((response) => {
      return response.data
    })
  }

  //02> 전체메뉴트리구조
  async treeMenu(data) {
    //headers : 정의
    axios.defaults.headers.common['Authorization'] = `bearer ${data.access_token}`
    axios.defaults.headers.common['authUserId'] = data.id
    return axios
      .get(`${this.endpoint}/menus/tree?isSideShow=false&systemId=${this.systemId}`)
      .then((response) => {
        return response.data
      })
  }

  //03> 메뉴권한 전체
  async userMenu(data) {
    //headers : 정의
    axios.defaults.headers.common['Authorization'] = `bearer ${data.access_token}`
    axios.defaults.headers.common['authUserId'] = data.id

    return axios
      .get(`${this.endpoint}/auths?userId=${data.id}&systemId=${this.systemId}`)
      .then((response) => {
        return response.data
      })
  }

  async getAuth(menuId) {
    axios
      .get(`/auths/pages?menuId=${menuId}`)
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async getAuthAll(userId) {
    return axios.get(`${this.endpoint}/auths?userId=${userId}`).then((response) => {
      return response.data
    })
  }

  async saveAuthAll(auth) {
    console.log('auth')
    console.log(auth)
    return axios.put(`${this.endpoint}/auths`, auth).then((response) => {
      return response.data
    })
  }

  async refresh(refresh_token) {
    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('client_id', 'dangam')
    params.append('client_secret', 'dg2021')
    params.append('refresh_token', refresh_token)
    return axios
      .create()
      .post(`${this.endpoint}/oauth/refresh`, params)
      .then((response) => response.data)
  }
}

export default new AuthApi()
