<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import { onMounted } from 'vue'
import axios from 'axios'
import { useRoute } from 'vue-router'

const route = useRoute()
const customerKey = ref('')
const authKey = ref('')

const radioContent = [
  {
    title: '베이직',
    desc: '100건',
    value: 'basic',
    price: 990,
    icon_class: 'ico-basic icon-subscr',
  },
  {
    title: '클래식',
    desc: '200건',
    value: 'classic',
    price: 1980,
    icon_class: 'ico-classic icon-subscr',
  },
  {
    title: '파워',
    desc: '300건',
    value: 'power',
    price: 2970,
    icon_class: 'ico-power icon-subscr',
  },
  {
    title: '오케스트라',
    desc: '무제한',
    value: 'orchestra',
    price: 5900,
    icon_class: 'ico-orchestra icon-subscr',
  },
]

const radioContent2 = [
  {
    title: '월간 정기 구독권',
    discount: '',
    per: '',
    price: '월 900',
    value: 'month',
    icon_class: '',
  },
  {
    title: '연간 정기 구독권',
    discount: '11,800원',
    per: '20%',
    price: '연 9500',
    value: 'year',
    icon_class: '',
  },
]

const generateOrderId = () => {
  const now = new Date()
  const dateString = now.toISOString().replace(/[-T:\.Z]/g, '') // YYYYMMDDHHMMSS 형식
  return `order_${dateString}` // "order_" 접두사를 추가
}
const apiSecretKey = ref('test_sk_GePWvyJnrKRdXARPBpjOVgLzN97E')

const doBill = async (billingKey) => {
  const billingUrl = `https://api.tosspayments.com/v1/billing/{${billingKey}}` // 여기에 billingKey URL을 입력하세요.
  const authHeader = 'Basic ' + btoa(`${apiSecretKey.value}:`)

  const data = {
    customerKey: customerKey.value,
    amount: 4900,
    orderId: generateOrderId(),
    orderName: '토스 프라임 구독',
    customerEmail: 'customer@email.com',
    customerName: '박토스',
    taxFreeAmount: 0,
  }

  const headers = {
    Authorization: authHeader,
    'Content-Type': 'application/json',
  }

  try {
    const response = await axios.post(billingUrl, data, { headers })
    console.log('응답 데이터:', response.data)
    // 성공적으로 호출된 후 처리할 내용
  } catch (error) {
    console.error('API 호출 오류:', error)
    // 오류 처리
  }
}

const getBillKey = async () => {
  const billingKeyUrl = `https://api.tosspayments.com/v1/billing/authorizations/issue` // 여기에 billingKey URL을 입력하세요.
  const authHeader = 'Basic ' + btoa(`${apiSecretKey.value}:`)

  const data = {
    authKey: 'YR_avhA77PygFeLL3brEn',
    customerKey: customerKey.value,
  }

  const headers = {
    Authorization: authHeader,
    'Content-Type': 'application/json',
  }

  try {
    const response = await axios.post(billingKeyUrl, data, { headers })
    console.log('응답 데이터:', response.data)
    // 성공적으로 호출된 후 처리할 내용
    await doBill(response.data.billingKey)
  } catch (error) {
    console.error('API 호출 오류:', error)
    // 오류 처리
  }
}

// 컴포넌트가 마운트될 때 doBill 호출
onMounted(() => {
  customerKey.value = route.query.customerKey
  authKey.value = route.query.authKey
  getBillKey()
  //doBill()
})
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">낭만 저장소</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 저장소</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="finish-box">
        <div class="com-btn-box"><i class="icon-check"></i></div>
        <div class="gap-xxl"></div>
        <div class="fnt-big">저장소 이용권 신청이 완료되었습니다!</div>
        <div class="gap-xxl"></div>
        <div class="com-btn-box">
          <VBtn type="submit" style="width: 100%" @click="handleSubmit"> 만세력 이용하기 </VBtn>
        </div>
        <div class="gap-xxl"></div>
        <div class="">
          신청이 완료된 내역은 마이페이지에서 확인하실 수 있으며, 환불은 신청 후 1일 이내에
          가능합니다. <button class="btn-txt-line">환불정책 바로가기</button>
        </div>
      </div>
    </div>
  </div>
</template>
