<template>
    <span class="icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="102" height="24" viewBox="0 0 102 24" fill="none">
  <g clip-path="url(#clip0_8886_41488)">
    <path d="M16.3324 12.7617C16.3049 9.72736 18.8217 8.25139 18.9317 8.18274C17.5083 6.11637 15.3009 5.82804 14.5239 5.80058C12.6741 5.60836 10.8724 6.91271 9.93032 6.91271C8.98823 6.91271 7.50977 5.82804 5.94879 5.8555C3.93396 5.88983 2.0429 7.05001 1.0183 8.86237C-1.11344 12.5489 0.475047 17.9722 2.52426 20.9585C3.54887 22.4139 4.73851 24.0546 6.31325 23.9928C7.83984 23.931 8.41747 23.018 10.2604 23.018C12.1033 23.018 12.6259 23.9928 14.2282 23.9585C15.8785 23.931 16.9031 22.4894 17.8934 21.0203C19.0761 19.3521 19.5506 17.6976 19.5644 17.6152C19.5231 17.6015 16.3737 16.3933 16.3393 12.7685" fill="#F8F8F8"/>
    <path d="M13.3136 3.85126C14.1388 2.82838 14.6958 1.42792 14.5445 0C13.3549 0.0480549 11.8626 0.823799 11.0031 1.83295C10.2467 2.7254 9.56588 4.17391 9.73779 5.54005C11.0787 5.64302 12.454 4.86041 13.3136 3.85126Z" fill="#F8F8F8"/>
    <path d="M38.5088 19.4085H36.3702L35.2012 15.7288H31.1234L30.0094 19.4085H27.9258L31.9623 6.88672H34.4585L38.5088 19.4085ZM34.8505 14.1911L33.7915 10.9302C33.6815 10.5938 33.4683 9.8112 33.1657 8.57551H33.1245C32.9938 9.11098 32.8013 9.90045 32.5331 10.9302L31.4878 14.1911H34.8574H34.8505Z" fill="#F8F8F8"/>
    <path d="M48.8718 14.7948C48.8718 16.3257 48.4592 17.5408 47.6134 18.4332C46.8638 19.2296 45.9355 19.6209 44.8284 19.6209C43.6318 19.6209 42.7723 19.1953 42.2497 18.344V23.0808H40.2348V13.36C40.2348 12.3921 40.2073 11.4104 40.1592 10.3943H41.9265L42.0365 11.8291H42.0709C42.7448 10.7513 43.7625 10.209 45.1172 10.209C46.1899 10.209 47.0701 10.6346 47.7853 11.4721C48.5142 12.3165 48.8718 13.4218 48.8718 14.8017V14.7948ZM46.8294 14.8566C46.8294 13.9779 46.6369 13.257 46.2381 12.6872C45.8048 12.0969 45.2203 11.7948 44.4914 11.7948C43.9963 11.7948 43.5493 11.9664 43.1436 12.2891C42.7448 12.6186 42.4697 13.0442 42.3528 13.5797C42.2909 13.8268 42.2565 14.0259 42.2565 14.1907V15.701C42.2565 16.3531 42.4628 16.9161 42.8617 17.3623C43.2605 17.8085 43.79 18.0419 44.4295 18.0419C45.1859 18.0419 45.7705 17.7467 46.1968 17.1701C46.6231 16.5866 46.8363 15.8245 46.8363 14.8634L46.8294 14.8566Z" fill="#F8F8F8"/>
    <path d="M59.2692 14.7948C59.2692 16.3257 58.8567 17.5408 58.0108 18.4332C57.2613 19.2296 56.3261 19.6209 55.2258 19.6209C54.0293 19.6209 53.1697 19.1953 52.6471 18.344V23.0808H50.6323V13.36C50.6323 12.3921 50.6048 11.4104 50.5566 10.3943H52.3239L52.4339 11.8291H52.4683C53.1422 10.7513 54.16 10.209 55.5146 10.209C56.5874 10.209 57.4676 10.6346 58.1827 11.4721C58.9117 12.3165 59.2692 13.4218 59.2692 14.8017V14.7948ZM57.22 14.8566C57.22 13.9779 57.0275 13.257 56.6286 12.6872C56.1954 12.0969 55.6109 11.7948 54.882 11.7948C54.3869 11.7948 53.9399 11.9664 53.5342 12.2891C53.1354 12.6186 52.874 13.0442 52.7434 13.5797C52.6815 13.8268 52.6471 14.0259 52.6471 14.1907V15.701C52.6471 16.3531 52.8534 16.9161 53.2523 17.3623C53.6511 17.8085 54.1806 18.0419 54.8201 18.0419C55.5765 18.0419 56.1679 17.7467 56.5874 17.1701C57.0206 16.5866 57.2269 15.8245 57.2269 14.8634L57.22 14.8566Z" fill="#F8F8F8"/>
    <path d="M70.4229 15.9002C70.4229 16.9711 70.0515 17.8361 69.3089 18.502C68.4905 19.2297 67.3559 19.5936 65.8981 19.5936C64.5503 19.5936 63.4707 19.3327 62.6523 18.811L63.1199 17.1428C64.0001 17.6576 64.9697 17.9254 66.0219 17.9254C66.7783 17.9254 67.3697 17.7537 67.7891 17.4174C68.2155 17.081 68.4287 16.621 68.4287 16.0512C68.4287 15.5432 68.2499 15.1176 67.9129 14.7743C67.5622 14.4242 66.9846 14.1016 66.18 13.8064C63.9658 12.9826 62.8655 11.7881 62.8655 10.2229C62.8655 9.19997 63.2575 8.35558 64.0277 7.69654C64.7978 7.04436 65.8293 6.71484 67.1084 6.71484C68.243 6.71484 69.1988 6.90706 69.9553 7.3121L69.4464 8.9391C68.7312 8.55466 67.9335 8.36244 67.0327 8.36244C66.3244 8.36244 65.7674 8.54093 65.3686 8.87732C65.0385 9.19311 64.8666 9.56381 64.8666 10.01C64.8666 10.5043 65.0591 10.9162 65.4442 11.232C65.7743 11.5272 66.3863 11.8499 67.2665 12.2C68.3461 12.6325 69.1369 13.1405 69.6458 13.724C70.1615 14.3075 70.416 15.0352 70.416 15.9002" fill="#F8F8F8"/>
    <path d="M77.0793 11.8919H74.8651V16.2718C74.8651 17.3839 75.257 17.94 76.0341 17.94C76.3917 17.94 76.6874 17.9057 76.9281 17.8439L76.9899 19.3679C76.5911 19.5121 76.0754 19.5876 75.429 19.5876C74.6313 19.5876 74.0193 19.3404 73.5723 18.8599C73.1253 18.3793 72.8984 17.5624 72.8984 16.4297V11.8782H71.585V10.3748H72.8984V8.7203L74.872 8.12305V10.3679H77.0862V11.8782" fill="#F8F8F8"/>
    <path d="M87.0504 14.8223C87.0504 16.209 86.6516 17.3486 85.8608 18.241C85.0287 19.1541 83.9216 19.614 82.5463 19.614C81.171 19.614 80.1601 19.1747 79.3693 18.3028C78.5785 17.431 78.1865 16.3188 78.1865 14.9802C78.1865 13.6415 78.5922 12.4401 79.4037 11.5477C80.222 10.6552 81.3154 10.209 82.6907 10.209C84.066 10.209 85.0837 10.6483 85.8952 11.5339C86.6653 12.3783 87.0504 13.4836 87.0504 14.8291V14.8223ZM84.9668 14.8703C84.9668 14.0396 84.788 13.3326 84.4304 12.7422C84.0041 12.0213 83.4058 11.6644 82.6219 11.6644C81.838 11.6644 81.1985 12.0213 80.779 12.7422C80.4145 13.3326 80.2426 14.0534 80.2426 14.9115C80.2426 15.7696 80.4214 16.4561 80.779 17.0396C81.2122 17.7605 81.8242 18.1175 82.6013 18.1175C83.3783 18.1175 83.9697 17.7536 84.4098 17.0259C84.7743 16.4149 84.9599 15.701 84.9599 14.8703H84.9668Z" fill="#F8F8F8"/>
    <path d="M93.5834 12.145C93.3908 12.1106 93.1777 12.0969 92.9438 12.0969C92.2356 12.0969 91.6854 12.3578 91.3072 12.8933C90.9772 13.3601 90.8052 13.9642 90.8052 14.6713V19.4081H88.7904V13.2228C88.7904 12.1793 88.7767 11.2319 88.7285 10.3807H90.4752L90.5508 12.1106H90.6127C90.8259 11.5134 91.1559 11.0328 91.6167 10.6759C92.0636 10.3532 92.5519 10.1953 93.0676 10.1953C93.2533 10.1953 93.4252 10.209 93.5696 10.2296V12.145" fill="#F8F8F8"/>
    <path d="M102 14.4713C102 14.8283 101.972 15.1303 101.924 15.3775H95.8933C95.9208 16.2699 96.2096 16.9564 96.7666 17.4233C97.2755 17.842 97.9288 18.048 98.7333 18.048C99.6273 18.048 100.439 17.9038 101.168 17.6223L101.484 19.0159C100.624 19.3866 99.6135 19.572 98.4514 19.572C97.0485 19.572 95.9483 19.1532 95.1506 18.3363C94.3529 17.5125 93.9541 16.4004 93.9541 15.0205C93.9541 13.6406 94.3254 12.5285 95.075 11.6223C95.852 10.6544 96.911 10.1738 98.2313 10.1738C99.5516 10.1738 100.521 10.6544 101.188 11.6223C101.725 12.4049 101.993 13.3455 101.993 14.4713H102ZM100.081 13.9496C100.102 13.3592 99.9642 12.8374 99.6891 12.4049C99.3384 11.8489 98.8089 11.5674 98.0938 11.5674C97.4405 11.5674 96.9041 11.842 96.4984 12.3844C96.1684 12.8168 95.9621 13.3455 95.907 13.9427H100.081V13.9496Z" fill="#F8F8F8"/>
  </g>
  <defs>
    <clipPath id="clip0_8886_41488">
      <rect width="102" height="24" fill="white"/>
    </clipPath>
  </defs>
</svg>
    </span>
</template>