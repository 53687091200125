<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import subscrGuide from '@/pages/mypage/components/subscrGuide.vue'
</script>

<template>
  <subscrGuide />
</template>
