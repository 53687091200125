<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'manse' } })
//Alert
import InquiryWriteComponent from '@/components/board/BoardWriteInquiry.vue'
</script>

<template>
  <div class="wide-wrap">
    <div class="inner-wrap">
      <div class="my-info-wrap">
        <div class="my-tit-box com-bg-my-box h-small st-guest">
          <div class="tit fnt-big">문의하기</div>
          <div class="path">
            문의하신 내용은 휴대폰 번호(sms) 또는 이메일(e-mail) 로 안내드리고 있습니다.
          </div>
        </div>
        <InquiryWriteComponent />
      </div>
    </div>
  </div>
</template>
<style>
.mypage-body .board-inquiry-box {
  display: none;
}
.mypage-body .board-tit-box {
  display: none;
}
</style>
