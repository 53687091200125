const ENDPOINT = import.meta.env.VITE_APP_AWS_S3_ENDPOINT
const SHARE_THUMBNAIL_PATH = import.meta.env.VITE_SHARE_THUMBNAIL_PATH
const THUMBNAIL_URL = `${ENDPOINT}${SHARE_THUMBNAIL_PATH}`

export const shareTitlePrefix = {
  EssayBoard: `[낭만 만세력] `,
};

export const shareMessagePreset = {
  EssayBoard: `[낭만 만세력]\n낭만 에세이 공유해요!`,
};

/** Branch 링크 생성 */
export const createBranchLink = async data => {  
  const {contentId, page, contentTitle, login, imageUrl} = data;

  const linkProps = {
    // channel: 'web',
    // feature: 'share',
    // campaign: 'content-sharing',
    // stage: 'new user',
    // tags: ['essay', 'share'],
    data: {
      // 앱 내 branch 리스너 형식에 맞게 작성되었음
      '$canonical_identifier': `${page}/${contentId}`,
      '$og_title': `${shareTitlePrefix[page]}${contentTitle}`,
      '$og_image_url': login && imageUrl ? imageUrl : THUMBNAIL_URL,
    }
  }
  const url = await generateLink(linkProps)  

  return url
};

const generateLink = (linkProps) => {
  return new Promise((resolve, reject) => {
    branch.link(linkProps, (err, link) => {
      if (err) {
        reject(err);
      } else {
        resolve(link);
      }
    });
  });
}

export const modifyEssayContent = (messageLength, item) => {
  let content = '';
  if (item.firstTitle) content += `\n\n${item.firstTitle}`;
  if (item.secondTitle) content += `\n\n${item.secondTitle}`;
  if (item.thirdTitle) content += `\n\n${item.thirdTitle}`;
  if (item.fourthTitle) content += `\n\n${item.fourthTitle}`;
  if (item.contents) content += replaceEssayContentTag(`\n\n${item.contents}`);
  if (content.length + messageLength > 500)
    content = content.substring(0, 500 - messageLength - 6) + '...';
  return content;
};

export const replaceEssayContentTag = (contents) => {
  if (!contents) return;
  return contents
    .replace(/<div>|&nbsp;|<p>|<br>|<img [^>]*>/g, '')
    .replace(/<\/div>|<\/p>|&nbsp;/g, '\n');
};