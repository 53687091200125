<script setup>
import fiveMixin from '@/composables/fiveMixin';
import { watch } from 'vue';
const emit = defineEmits();

const { ...five } = fiveMixin();

const props = defineProps({
  prBirthData: {
    type: Object,
    default: null,
  },
  prLangMode: {
    type: String,
    default: '',
  },
  prType: {
    type: String,
    default: '',
  },
  prCurNum: {
    type: Number,
    default: 0,
  },
});

/** 선택된 대운/세운/월운 업데이트 */
const updateSelectedBlylml = (typ, luckText) => {
  emit('updateBlylml', { typ, luckText });
};

/** 대운적용 API 호출 */
const applyDaeun = ({flag}) => {
  emit('applyDaeun', {flag});
};

/** 대운/세운/월운 직접 클릭 */
const clickDaeun = (typ, item) => {
  emit('clickDaeun', typ, item, props.prBirthData);
};

/** 선택된 대운/세운/월운의 한글 간지 추출 */
const createLuckText = ({birthData}) => {  
  let data = birthData || props.prBirthData;
  const luckSky = data.sky.find(item => item.num === props.prCurNum);
  const luckEarth = data.earth.find(item => item.num === props.prCurNum);
  let luckSkyValue = luckSky ? luckSky.value : ' ';
  let luckEarthValue = luckEarth ? luckEarth.value : ' ';

  if (props.prLangMode === 'CHN') {
    luckSkyValue = five.getKorTxt(luckSkyValue, 'sky');
    luckEarthValue = five.getKorTxt(luckEarthValue, 'earth');
  }

  return luckSkyValue + luckEarthValue;
};

watch(() => props.prBirthData, (newBirthData, oldBirthData) => {    
  // 선택된 대운/세운/월운 한글 텍스트 추출 및 업데이트
  const luckText = createLuckText({ birthData: newBirthData });
  updateSelectedBlylml(props.prType, luckText);

  const birthDataChanged = newBirthData !== oldBirthData;
  if (props.prType === 'month' && birthDataChanged) {
    applyDaeun({ flag: true });
  }
}, { immediate: true });
</script>

<template>
  <div class="data-0102-box">
    <ul
      v-for="(item, index) in prBirthData.sky"
      :key="index"
      class="birth-item-box"
      :class="{ 'birth-item-box': true, 'active': prCurNum === item.num }"
      @click="clickDaeun(prType, item)"
    >
      <!--|| currentYear === item.year-->
      <li>
        <span class="no">{{ item.num }}  </span>
      </li>
      <li v-if="item.year">
        <span class="no-year">{{ item.year }}</span>
      </li>
      <li><span class="txts">{{ item.six }}</span></li>
      <li>
        <span
          class="txt-round"
          :class="[five.getColorFive(item.value), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(item.value, 'sky') }} </em><em class="txt-main">{{ item.value }}</em>

        </span>
      </li>
      <li>
        <span
          class="txt-round"
          :class="[five.getColorFive(prBirthData.earth[index].value), prLangMode === 'CHNKOR' ? 'chnkor' : '']"
        >
          <em
            v-if="prLangMode === 'CHNKOR'"
            class="chn"
          > {{ five.getHanjaTxt(prBirthData.earth[index].value, 'earth') }} </em>
          <em class="txt-main">{{ prBirthData.earth[index].value }}  </em>
        </span>
      </li>
      <li><span class="txts">{{ prBirthData.earth[index].six }}</span></li>
      <li><span class="txts txt-earth-02">{{ prBirthData.earth[index].twelve }}</span></li>
      <li><span class="txts txt-earth-02">{{ prBirthData.earth[index].sal[0] }}</span></li>
    </ul>
  </div>
</template>