<script setup>
import IconError01 from '@/components/svg/IconError01.vue'

definePage({ meta: { layout: 'manse' } })

const router = useRouter()
const goLinkManse = () => {
  router.push('/main')
}
</script>
<template>
  <div class="error-page">
    <div class="icon-error"><IconError01 /></div>
    <div class="gap-xxxl"></div>
    <div class="tit">다시 확인해 주세요!</div>
    <div class="txt1">
      찾으시려는 페이지의 주소를 잘못 입력하였거나<br />
      비정상적인 경로로 접속하였습니다.
    </div>
    <div class="txt2">입력하신 페이지의 주소와 경로가 정확한지 한 번 더 확인 후 이용해 주세요!</div>
    <div class="gap-xxxl"></div>
    <div class="btn-box">
      <VBtn type="submit" style="width: 280px" @click="goLinkManse"> 낭만만세력 홈 이동하기 </VBtn>
    </div>
  </div>
  <div class="empty-page">blank</div>
</template>
<style lang="scss">
@import '@/assets/nangman_style/error.scss';
</style>
