<script setup>
import { useStore } from "vuex"
import { useRouter } from "vue-router"
import articleApi from "@/api/ArticleApi"
import boardApi from "@/api/BoardApi"
import { formatCustomDate } from "@/composables/functions"
import { BoardTitleBox } from "@/components/board"

const props = defineProps({
  board: {
    type: String,
    default: "",
  },
  boardId: {
    type: Number,
    default: 0,
  },
  articleId: {
    type: String,
    default: "",
  },
})

const router = useRouter()
const store = useStore()

const listData = ref({})
const boardTitle = ref(null)
const comment = ref(null)
// const memberInfo = ref(null)
const memberId = ref(null)

const getBoardInfo = async () => {
  try {
    const result = await boardApi().findById(props.boardId)
    boardTitle.value = result.boardTtl
  } catch (error) {
    alert(error.response.data?.message)
  }
}

const getView = async () => {
  try {
    const result = await articleApi().get(props.boardId, props.articleId, {})

    if (!result) {
      router.back();
      return;
    }

    listData.value = result?.data
    comment.value = listData.value.comments[0]?.curseMasking
    // memberInfo.value = store.getters[""]
    memberId.value = store.getters["user/id"]
  } catch (error) {
    alert(error)
  }
}

const goViewList = () => {
  router.push({
    path: `/customer/${props.board}`,
  })
}

const del = async () => {
  if (confirm("삭제하시겠습니까?")) {
    try {
      const result = await articleApi().delete(props.boardId, props.articleId, {})
      if (result) {
        goViewList()
      }
    } catch (error) {
      alert(error.response.data?.message)
    }
  }
}

onMounted(() => {
  getView()
  getBoardInfo()
})
</script>

<template>
  <div class="">
    <BoardTitleBox :board="board" />

    <div class="board-view-tit-box">
      <div class="tit">
        {{ listData.articleTtl }}
      </div>
      <div class="date">
        {{ formatCustomDate(listData.createdAt) }}
      </div>
    </div>
    <div class="article-content-box">
      <div class="" v-html="listData.articleCtn" />
    </div>
    <div class="board-del-btn-box">
      <button v-if="memberId === listData.userId" class="btn-del-line-3" @click="del()">
        삭제
      </button>
    </div>
    <div v-if="comment" class="board-answer-box">
      <div class="answer-tit">만세력 운영진 답변</div>
      <div class="answer-cont">
        <div class="" v-html="comment" />
      </div>
    </div>
    <section class="btn-com gap-xl ceneter">
      <button class="btn-line w-large bg-w" @click="goViewList()">목록으로</button>
    </section>
  </div>
</template>