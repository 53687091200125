<!-- src/layouts/MypageLayout.vue -->
<script setup>
import { useRouter } from 'vue-router'
import HeaderComponent from '@/layouts/Header.vue'
import FooterComponent from '@/layouts/Footer.vue'

const router = useRouter()
function goLink(path) {
  router.push(path)
}

/** 마우스 우클릭 방지 */
const disableRightClick = (event) => {
  event.preventDefault();
};

onMounted(async () => {
  document.addEventListener("contextmenu", disableRightClick);
});

onBeforeUnmount(() => {
  document.removeEventListener("contextmenu", disableRightClick);
});
</script>
<template>
  <div class="manse-layout">
    <header>
      <HeaderComponent />
    </header>
    <main class="manse-main">
      <RouterView />
    </main>
    <footer>
      <FooterComponent />
    </footer>
  </div>
</template>
