<script setup>
import { getGroupUserlist } from '@/api/UserGroup'
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'
import PopShareBox from '@/pages/manse/components/PopShareBox.vue'
import useAuth from '@/composables/useAuth'
import AlertComponent from '@/pages/manse/components/Alert.vue'
import PopQrBox from './PopQrBox.vue';
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'

const emit = defineEmits()
const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()
const router = useRouter()
const { userInfo } = useAuth()

const props = defineProps({
  prLangMode: {
    type: String,
    default: '',
  },
  prPgmode: {
    type: String,
    default: '',
  },
  prData: {
    type: Object,
    default: null,
  },
})

const dialogShare = ref(null)
const isPopShareBox = ref(false)
const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref('')
const isPopQrBox = ref(false)

const dialogConfirm = ref(null)

const shareOpen = () => {
  if (Object?.keys(props.prData).length < 1) {
    showDialogAlert('사주조회 후 이용하여 주시기 바랍니다.', '', '/manse');
    return
  }
  isPopShareBox.value = true
}
const closePopShareBox = () => {
  isPopShareBox.value = false
}

const closePopQrBox = () => isPopQrBox.value = false;

onMounted(async () => {
  if (userInfo) {
    await fetchFavList()
  }
})
const favList = ref({})
const fetchFavList = async () => {
  try {
    const param = {}
    param.size = 3
    param.page = 1
    const data = await getGroupUserlist(param) // 저장목록 조회
    favList.value = data?.content
  } catch (error) {
    console.error('Failed to load users:', error)
  } finally {
  }
}
function goView(id) {
  router.push(`/manse/${id}/rs`)
}
function print() {
  if (!userInfo) {
    const nextUrl = '/auth/login';
    // showDialogAlert('로그인 후 편리하게 이용할 수 있습니다.', '', nextUrl);
    showDialogConfirm(
      '로그인 후 편리하게 이용할 수 있습니다.',
      '',
      '로그인',
      () => router.push(nextUrl)
    )
    return
  } else {
  emit('printclick');
  }
}
function appDown() {
  window.open('https://play.google.com/store/apps/details?id=com.twofivedosajuapp', '_blank')
}

const qrOpen = () => {
  isPopQrBox.value = true;
}

function goLogin() {
  router.push(`/auth/login`)
}

const showDialogAlert = (title, text = '', nextUrl = '') => {
  dialogTitle.value = title
  dialogText.value = text
  dialogNextUrl.value = nextUrl
  dialogAlert.value.openAlertDialog()
}

const showDialogConfirm = (title, title2 = '', text = '', onConfirm = () => {}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달

  dialogConfirm.value.openConfirmDialog()
}
</script>
<template>
  <div>
    <section class="manse-right-box" :class="{ modeRs: prPgmode === 'rs' }">
      <div class="manse-floating">
        <section v-if="prPgmode !== 'rs'" class="latest-box">
          <div class="tit-box">최근 등록사주</div>
          <div v-if="favList && Object.keys(favList).length > 0" class="">
            <div
              v-for="(item, index) in favList"
              :key="index"
              class="row-item-box"
              @click="goView(item.guestUserId)"
            >
              <div class="name-box">
                <span class="tit1"
                  >{{ five.formatGanJi(item?.sajugangi, 2) }}
                  {{ five.formatGanJi(item?.sajugangi, 6) }}</span
                >
                <span class="tit2">({{ useFun.getGenderTxt(item.gender) }})</span>
              </div>
              <div class="year-box">
                {{ useFun.getYear(item.birth) }}년 / {{ useFun.getAge(item.birth) }}세
              </div>
            </div>
          </div>
          <div v-else class="fav-none-box">
            <div class="txt-none">최근 등록된<br />사주가 <br />없습니다.</div>
            <button v-if="!userInfo" class="btn-line" @click="goLogin()">로그인</button>
          </div>
        </section>
        <section class="app-down-box">
          <button class="btn-down-right" @click="shareOpen()">
            <i class="icon-share" />공유하기
          </button>
          <button class="btn-down-right" @click="print()"><i class="icon-print" />출력하기</button>
          <button class="btn-down-app" @click="qrOpen()">
            <i class="icon-down" />앱 다운로드
          </button>
        </section>
      </div>
    </section>

    <!-- 공유하기 -->
    <PopShareBox
      ref="dialogShare"
      v-if="isPopShareBox"
      :pr-data="prData"
      @close="closePopShareBox()"
      @click:outside="closePopShareBox()"
      @share="share"
    />

    <AlertComponent
    ref="dialogAlert"
    :title="dialogTitle"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
  <ConfirmComponent
    ref="dialogConfirm"
    :title="dialogTitle"
    :title2="dialogTitle2"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />

  <PopQrBox
  v-if="isPopQrBox"
  @closePopQrBox="closePopQrBox()"
  />
  </div>
</template>
