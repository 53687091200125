<script setup>
import useJoin from '@/composables/useJoin';
import { CheckField, Consent, Inputbox, PopupModal, Timer } from '@/components/auth';

definePage({ meta: { layout: 'auth' } });

const {
  joinStep,
  displayText,
  authNotice,
  joinData,
  userData,
  joinType,
  nameValidation,
  mobileValidation,
  authNoValidation,
  userIdValidation,
  userPwdValidation,
  pwdCheckValidation,
  terms,
  marketingConsent,
  sendButtonText,
  isSendDisabled,
  isSentAuth,
  showAuthNo,
  timerState,
  authNo,
  agreeAll,
  termsAndPolicies,
  timerEnded,
  checkUserIdDuplication,
  toggleAgreeAll,
  toggleMarketingConsent,
  showPopup,
  popupMessage,
  popupButtonText,
  showConsent,
  consentHeader,
  consentContent,
  consentButtonText,
  onClickSend,
  isConfirmDisabled,
  inputHandler,
  onClickConfirm,
  confirmPopup,
  closePopup,
  closeConsent,
  clickConsent,
} = useJoin();

let scrollPosition = 0;
const open = () => {
    scrollPosition = window.scrollY; // 현재 스크롤 위치 저장
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`; // 스크롤 위치 고정
    document.body.style.width = '100%'; // 가로 스크롤 방지
  };
const close = () => {
  document.body.style.position = ''; // 원래 상태로 복구
  document.body.style.top = '';
  window.scrollTo(0, scrollPosition); // 저장한 스크롤 위치로 복구
};
// 팝업 열렸을 때, 스크롤 방지
watch(
  [() => showPopup.value, () => showConsent.value],
  ([popupNewValue, consentNewValue]) => {
    if (popupNewValue || consentNewValue) open();
    else close();
  }
);
</script>

<template>
  <div class="inner">
    <div class="content">
      <div class="step-indicator">
        <div v-if="joinStep !== 3" class="step-active">
          <div class="step" :class="{ active: joinStep === 1 }">
            1
          </div>
          <div class="line"></div>
          <div class="step" :class="{ active: joinStep === 2 }">
            2
          </div>
        </div>
        <div v-if="joinStep === 3" class="step-complete">
          <img src="../../assets/images_manse/check_circle.png" alt="Logo" width="80" height="80" />
          <img src="../../assets/images_manse/logo_b.png" alt="Logo" width="140" height="53" />
        </div>
      </div>
      <div class="form-title">{{ displayText.formTitle }}</div>
      <div class="form-description" v-html="displayText.formDescription"></div>

      <div v-if="joinStep === 1" class="input-group">
        <Inputbox 
          v-model="joinData.name"
          data-type="name"
          type="text"
          placeholder="이름을 입력해주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="nameValidation.validationMessage"
        />
        <Inputbox 
          v-model="joinData.mobileNo"
          :max-length="11"
          data-type="mobileNo"
          type="text"
          placeholder="휴대폰 번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="mobileValidation.validationMessage"
        >
          <template #outer>
            <button 
              v-if="true"
              class="btn action-btn"
              :class="{ active: !isSendDisabled }"
              :disabled="isSendDisabled"
              @click="onClickSend">
              {{ sendButtonText }}
            </button>
          </template>
        </Inputbox>
        <Inputbox
          v-if="showAuthNo"
          v-model="authNo"
          data-type="authNo"
          type="text"
          placeholder="인증번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="authNoValidation.validationMessage"
        >
          <template #inner>
            <Timer 
              v-if="isSentAuth"
              :timer-state="timerState"
              :duration="180"
              @timer-ended="timerEnded"
            />
          </template>
        </Inputbox>
        <div v-if="isSentAuth" class="auth-notice" v-html="authNotice"></div>
      </div>

      <div v-if="joinStep === 2" class="input-group">
        <Inputbox
          v-model="userData.userId"
          data-type="userId"
          type="text"
          placeholder="아이디를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="userIdValidation.validationMessage"
          @blur="checkUserIdDuplication"
        />
        <Inputbox
          v-model="userData.userPwd"
          :max-length="15"
          data-type="userPwd"
          type="password"
          placeholder="비밀번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="userPwdValidation.validationMessage"
        />
        <Inputbox
          v-model="userData.pwdCheck"
          :max-length="15"
          data-type="pwdCheck"
          type="password"
          placeholder="비밀번호를 한 번 더 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="pwdCheckValidation.validationMessage"
        />
      </div>

      <div v-if="joinStep === 2" class="terms-container">
        <CheckField
          v-model="agreeAll"
          :class="'terms-header'"
          icon-type="type0"
          label="전체 약관에 동의합니다."
          :toggle-checkbox="toggleAgreeAll"
        />
        <div class="terms-list" >
          <CheckField
            v-for="(term, index) in terms"
            :key="index"
            v-model="term.agreed"
            :class="'terms-item'"
            :label="term.text"
            :required-indicator="term.required"
            :optional-indicator="!term.required"
            :show-view-button="index !== 0"
            :toggle-checkbox="index === 3 ? toggleMarketingConsent : () => {}"
            @view="() => clickConsent(term.content, index)"
          />
        </div>
        <div class="marketing-consent" >
          <CheckField
            v-for="(item, index) in marketingConsent" 
            :key="index"
            v-model="item.agreed"
            :class="'marketing-item'"
            :label="item.text"
          />
        </div>
      </div>

      <button 
        class="confirm-btn btn"
        :class="{ active: isConfirmDisabled}"
        :disabled="!isConfirmDisabled"
        @click="onClickConfirm"
      >
        {{ displayText.confirmButton }}
      </button>

      <div v-if="joinStep === 1" class="login-link">
        이미 가입하셨나요? <router-link to="/auth/login" class="login-btn">로그인하기</router-link>
      </div>      
    </div>
  </div>

  <Consent 
    v-if="showConsent"
    :header="consentHeader"
    :message="consentContent"
    :button-text="consentButtonText"
    @close="closeConsent"
    @confirm="closeConsent"
  />

  <PopupModal 
    v-if="showPopup" 
    :message="popupMessage" 
    :buttonText="popupButtonText" 
    :isVisible="showPopup"
    :add-on="joinStep === 2"
    @close="closePopup"
    @confirm="confirmPopup"
  />
</template>