<script setup>
import Inspire from '@/components/Inspire.vue'

definePage({ meta: { layout: 'empty' } })
</script>

<template>
  <Inspire />
</template>

<style lang="scss">
@import '@/assets/nangman_style/temporary.scss';
// @use "@nstyles/temporary.scss";
</style>
