<script setup>
import { IconSearch } from "@/components/svg"

const props = defineProps({
  searchKeywords: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
})
const emit = defineEmits(["update:searchKeywords", "search"])

const modelValue = ref(props.searchKeywords)

watch(
  () => props.searchKeywords,
  (newValue) => {
    modelValue.value = newValue
  }
)

watch(modelValue, (newValue) => {
  emit("update:searchKeywords", newValue)
})

const onSearch = () => {
  emit("search", modelValue.value)
}
</script>

<template>
  <div class="search-box">
    <VTextField
      class="input"
      clearable
      variant="underlined"
      :placeholder="placeholder"
      v-model="modelValue"
      @keyup.enter="onSearch"
    />
    <span class="btn-box" style="cursor: pointer" @click="onSearch">
      <IconSearch />
    </span>
  </div>
</template>

<style lang="scss" scoped>
.input {
  border: 0;
}
.btn-box {
  margin-top: 10px;
}
</style>