<template>
  <span class="icon">
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_7321_71744)">
        <path d="M16.5 17.5C17.0523 17.5 17.5069 17.0503 17.4335 16.5029C17.216 14.8819 16.4727 13.3661 15.3033 12.1967C13.8968 10.7902 11.9891 10 10 10C8.01088 10 6.10322 10.7902 4.6967 12.1967C3.52731 13.3661 2.78396 14.8819 2.56655 16.5029C2.49313 17.0503 2.94772 17.5 3.5 17.5L10 17.5H16.5Z" fill="#575757"/>
        <path d="M9.99967 10.8333C12.3009 10.8333 14.1663 8.96785 14.1663 6.66667C14.1663 4.36548 12.3009 2.5 9.99967 2.5C7.69849 2.5 5.83301 4.36548 5.83301 6.66667C5.83301 8.96785 7.69849 10.8333 9.99967 10.8333Z" fill="#575757" stroke="#575757" stroke-width="1.5"/>
      </g>
      <defs>
        <clipPath id="clip0_7321_71744">
          <rect width="20" height="20" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </span>
</template>
