<script setup>
import { IconPrev, IconNext } from "@/components/svg"

// Props 정의
const props = defineProps({
  totalPages: {
    type: Number,
    required: true,
  },
  currentPage: {
    type: Number,
    required: true,
  },
})

// Emits 정의
const emit = defineEmits(["page-changed"])

// 페이지 변경 메서드
const changePage = (page) => {
  emit("page-changed", page)
}
</script>

<template>
  <div class="pagination">
    <button :disabled="props.currentPage === 1" class="pg-prev">
      <IconPrev />
    </button>
    <button
      v-for="page in props.totalPages"
      :key="page"
      :class="{ active: props.currentPage === page }"
      @click="changePage(page)"
    >
      {{ page }}
    </button>
    <button class="pg-next" disabled>
      <IconNext />
    </button>
  </div>
</template>
