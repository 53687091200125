<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
//Alert
import InquiryWriteComponent from '@/components/board/BoardWriteInquiry.vue'
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit fnt-big">문의하기</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>문의</li>
          <li>문의하기</li>
        </ul>
      </div>
    </div>
    <InquiryWriteComponent />
  </div>
</template>
<style>
.mypage-body .board-inquiry-box {
  display: none;
}
.mypage-body .board-tit-box {
  display: none;
}
</style>
