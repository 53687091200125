<script setup>
definePage({ meta: { layout: 'customer' } })

import { getBoardName } from '@/composables/functions'
import Gnb from '@/components/Gnb.vue'
import { BoardList, BoardView, BoardWrite, EssayList } from '@/components/board'
import { useStore } from 'vuex';

const store = useStore()
const board = ref(null)
const boardId = ref(0)
const articleId = ref(0)
const route = useRoute()

const setData = () => {
  boardId.value = getBoardName(route.params.board ?? 'notice', 'boardId')
  board.value = route.params.board ?? 'notice'
  articleId.value = route.params.articleId ?? 0
}

onMounted(() => {
  setData()
})

onUnmounted(() => {
  store.dispatch('saveCurrentBoard', '');
  store.dispatch('saveCurrentBoardPage', 1);
  // 낭만 에세이 페이지 초기화
  // const essayFocus = store.state.boards.essayFocus;
  // if (!essayFocus?.list && !essayFocus.view) {
  //   store.dispatch('saveCurrentEssayPage', 1);
  // }
})

watch(
  () => route.params.board,
  (newBoard) => {
    const currentBoard = store.state.boards.currentBoard;
    if (currentBoard !== newBoard) {
      store.dispatch('saveCurrentBoardPage', 1);
      store.dispatch('saveCurrentEssayPage', 1);
    }
    setData();
  },
)
watch(
  () => route.params.boardId,
  () => setData(),
)
watch(
  () => route.params.articleId,
  () => setData(),
)
</script>

<template>
  <section class="inner-base-2">
    <nav class="cust-gnb">
      <Gnb />
    </nav>
    <section class="cust-body">
      <div v-if="boardId">
        <BoardList v-if="!articleId && board !== 'essay'" :board="board" :board-id="boardId" />
        <BoardView
          v-if="articleId && articleId !== 'write' && board !== 'essay'"
          :board="board"
          :board-id="boardId"
          :article-id="articleId"
        />
        <BoardWrite
          v-if="articleId === 'write' && board !== 'essay'"
          :board="board"
          :board-id="boardId"
          :article-id="articleId"
        />
        <EssayList v-if="!articleId && board === 'essay'" :board="board" :board-id="boardId" />
      </div>
    </section>
  </section>
</template>
