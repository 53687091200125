<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'manse' } })

import subscrGuide from '@/pages/mypage/components/subscrGuide.vue'
</script>

<template>
  <div class="wide-wrap">
    <div class="inner-wrap">
      <subscrGuide />
    </div>
  </div>
</template>
