<script setup>
import { Inputbox, PopupModal, Timer } from '@/components/auth';
import useFindId from '@/composables/useFindId';
import searchIcon from '../../assets/images_manse/icon_search_lg.svg';

definePage({ meta: { layout: 'auth' } });

const {
  findStep,
  displayText,
  authNotice,
  findUserData,
  foundUserData,
  nameValidation,
  mobileValidation,
  authNoValidation,
  isSendDisabled,
  sendButtonText,
  isSentAuth,
  showAuthNo,
  isConfirmDisabled,
  timerState,
  showPopup,
  popupMessage,
  popupButtonText,
  inputHandler,
  onClickSend,
  onClickConfirm,
  onClickResetPassword,
  timerEnded,
  closePopup,
} = useFindId();

let scrollPosition = 0;
const open = () => {
    scrollPosition = window.scrollY; // 현재 스크롤 위치 저장
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`; // 스크롤 위치 고정
    document.body.style.width = '100%'; // 가로 스크롤 방지
  };
const close = () => {
  document.body.style.position = ''; // 원래 상태로 복구
  document.body.style.top = '';
  window.scrollTo(0, scrollPosition); // 저장한 스크롤 위치로 복구
};
// 팝업 열렸을 때, 스크롤 방지
watch(
  () => showPopup.value,
  (popupNewValue) => {
    if (popupNewValue) open();
    else close();
  }
);
</script>

<template>
  <div class="inner">
    <div class="content">
      <div class="icon">
        <component :is="searchIcon" />
      </div>
      <div class="form-title">{{ displayText.formTitle }}</div>
      <div class="form-description" v-html="displayText.formDescription"></div>

      <div v-if="findStep === 1" class="input-group">
        <Inputbox
          v-model="findUserData.name"
          data-type="name"
          type="text"
          placeholder="이름을 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="nameValidation.validationMessage"
        />
        <Inputbox 
          v-model="findUserData.mobileNo"
          :max-length="11"
          data-type="mobileNo"
          type="text"
          placeholder="휴대폰 번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="mobileValidation.validationMessage"
        >
          <template #outer>
            <button 
              v-if="true"
              class="btn action-btn"
              :class="{ active: !isSendDisabled }"
              :disabled="isSendDisabled"
              @click="onClickSend">
              {{ sendButtonText }}
            </button>
          </template>
        </Inputbox>
        <Inputbox
          v-if="showAuthNo"
          v-model="findUserData.authNo"
          data-type="authNo"
          type="text"
          placeholder="인증번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="authNoValidation.validationMessage"
        >
          <template #inner>
            <Timer
              v-if="isSentAuth"
              :timer-state="timerState"
              :duration="180"
              @timer-ended="timerEnded"
            />
          </template>
        </Inputbox>
        <div v-if="isSentAuth" class="auth-notice" v-html="authNotice"></div>
      </div>

      <div v-if="findStep === 2" class="found-data">
        <div class="found-id">{{ foundUserData.loginId }}</div>
        <div class="created-at">{{ foundUserData.createdAt }} 가입</div>
      </div>

      <div style="width: 100%; display: flex; flex-direction: column; gap: 8px;">
        <button 
          class="confirm-btn btn"
          :class="{ active: isConfirmDisabled}"
          :disabled="!isConfirmDisabled"
          @click="onClickConfirm"
        >
          {{ displayText.confirmButton }}
        </button>
        <button
          v-if="findStep === 2"
          class="confirm-btn action-btn btn"
          :class="{ active: true}"
          @click="onClickResetPassword"
        >
          비밀번호 재설정 
        </button>
      </div>
    </div>
  </div>

  <PopupModal
    v-if="showPopup" 
    :message="popupMessage" 
    :buttonText="popupButtonText" 
    :isVisible="showPopup"
    :add-on="findStep === 2"
    @close="closePopup"
    @confirm="confirmPopup"
  />
</template>