<script setup>
import { checkEmailDuplicationAPI, checkPasswordAPI, getUserInfoAPI, mypageResetEmailAPI, mypageResetInfoAPI, mypageResetMobileAPI, mypageResetPasswordAPI, updateBasicInfoAPI } from '@/api/myPage';
import { ViewTab, AuthTab, EditTab, DeleteTab, EditModal } from '@/components/mypage';
import { formatDateTime, formatMobileNo } from '@/composables/dataFormatter';
import { DEV_MODE } from '@/main';
import { checkAuthNoAPI, checkMobileDuplicationAPI, sendAuthAPI, sendMeAuthAPI } from '@/api/Join';
import { emailValidationCheck, validateMobile, validateUserId } from '@/composables/validationCheck';
import { watch } from 'vue';
import { PopupModal } from '@/components/auth';
import { useMyInfoStore } from '@/store/modules/myinfo';
import Snackbar from '../manse/components/Snackbar.vue';

definePage({ meta: { layout: 'mypage' } });

const myInfoStore = useMyInfoStore();

/**
 * 내 정보 페이지 탭
 * 
 * 'view' | 'auth' | 'edit' | 'delete'
 */
const myInfoTab = ref('view');

/** 내 정보 > 'view' 정보 */
const accountInfo = reactive({
  userId: '',
  password: '********',
  name: '',
  nickname: '',
  email: '',
  mobileNo: '',
  createdAt: '',
  loggedAt: '',
});

/** 
 * 인증 수단 탭 
 * 
 * 0: 비밀번호 인증, 1: 휴대폰 문자 인증
 */
const authMethodTab = ref(0);

/** AuthTab - 인증 단계 사용자 입력값 */
const userAuthInfo = reactive({
  userPwd: '',
  mobileNo: '',
  authNo: '',
  sendKey: '',
});

/** AuthTab - 인증 단계 Status 관리 */
const authStatus = ref({
  /** 인증번호 발송 상태 */
  isSentAuth: false,
  /** 인증번호 입력창 활성화 여부 */
  showAuthNo: false,
  /** 타이머 동작 상태  */
  timerState: 'stop',
});
/** AuthTab - 인증번호 발송 버튼 텍스트 */
const sendButtonText = computed(() => {
  // 인증번호 발송전 또는 발송후 시간 만료된 경우
  if (!authStatus.value.isSentAuth) return '인증번호 발송';
  // 인증번호 발송후, 발송후 입력값 변경
  if (authStatus.value.isSentAuth) return '재발송';
});
/** AuthTab - 제출 버튼 활성화 여부 */
const isConfirmDisabled = computed(() => {
  if (myInfoTab.value === 'auth') {
    if (authMethodTab.value === 0 
      && userPwdValidation.isValid
      && userAuthInfo.userPwd
      && userAuthInfo.userPwd.length > 7) {
      return false;
    }
    if (authMethodTab.value === 1 
      && authNoValidation.isValid
      && userAuthInfo.authNo
      && authStatus.value.isSentAuth) {
      return false;
    }
  }
  return true;
});

const userEditData = reactive({
  loginId: '',
  /** 제출용 비밀번호 */
  loginPwd: '',
  mobileNo: '',
  authNo: '',
  name: '',
  sendKey: '',
  /** 새 비밀번호 */
  userPwd: '',
  /** 새 비밀번호 확인 */
  pwdCheck: '',
  email: '',
  nickname: '',
});

/** EditTab - 비밀번호 재설정 상태 */
const showEditPassword = ref(false);

/** EditTab - 이메일 재설정 정보 */
const editEmailInfo = reactive({
  sendKey: '',
  authNo: '',
  isSentAuth: false,
  showAuthNo: false,
  timerState: 'stop',
});
/** EditTab - 이메일 재설정 상태 */
const showEditEmail = ref(false);
/** EditTab - 선택된 이메일 도메인 항목  */
const selectedDomain = ref('직접 입력');
/** EditTab - '이메일 재설정' 버튼 텍스트 */
const editEmailButtonText = computed(() => {
  if (accountInfo.email !== null) return '이메일 재설정';
  if (showEditEmail.value) return '인증번호 발송';
  return '인증번호 발송';
});
/** EditTab - '이메일 재설정' 버튼 활성화 상태 */
const editEmailButtonActive = computed(() => {
  if (accountInfo.email === null) return userEditData.email !== '';
  if (showEditEmail.value) return userEditData.email !== '';
  return true;
});

/** EditTab - 휴대폰 번호 재설정 정보 */
const editMobileInfo = reactive({
  sendKey: '',
  authNo: '',
  isSentAuth: false,
  showAuthNo: false,
  timerState: 'stop',
})
/** EditTab - 휴대폰 번호 재설정 상태 */
const showEditMobileNo = ref(false);
/** EditTab - '휴대폰 번호 재설정' 버튼 텍스트 */
const editMobileButtonText = computed(() => {
  return '휴대폰 번호 재설정';
});

// 팝업 상태 관리
const showPopup = ref(false);
const popupMessage = ref('');
const popupButtonText = ref('확인');

/** 스낵바 상태관리 */
const snackbar = ref(null);

/** 사용자 입력 - 닉네임 유효성 상태 */
const nicknameValidation = reactive({
    data: 'nickname',
    isValid: false,
    validationMessage: '',
  });
/** 사용자 입력 - 비밀번호 유효성 상태 */
const userPwdValidation = reactive({
  data: 'userPwd',
  isValid: false,
  validationMessage: '',
});
/** 사용자 입력 - 비밀번호 확인 유효성 상태 */
const pwdCheckValidation = reactive({
  data: 'pwdCheck',
  isValid: false,
  validationMessage: '',
});
/** 사용자 입력 - 이메일 유효성 상태 */
const emailValidation = reactive({
  data: 'email',
  isValid: false,
  validationMessage: '',
});
/** 사용자 입력 - 휴대폰 번호 유효성 상태 */
const mobileValidation = reactive({
  data: 'mobileNo',
  isValid: false,
  validationMessage: '',
});
/** 사용자 입력 - 인증번호 유효성 상태 */
const authNoValidation = reactive({
  data: 'authNo',
  isValid: false,
  validationMessage: '',
});

onMounted(async () => {
  await refreshUpdatedAt({});
  console.log('myinfo')
});

/** AuthTab - 인증번호 발송 상태 변경 */
watch(() => authStatus.value.isSentAuth, (newState) => {
  if (!newState) {
    if (authStatus.value.timerState === 'stop') {
      authNoValidation.isValid = false;
      authNoValidation.validationMessage =
        '인증번호 입력시간이 초과되었습니다. 인증번호를 다시 발송해 주세요.';
    } else if (userAuthInfo.sendKey) {      
      userAuthInfo.sendKey = null;
      userAuthInfo.authNo = '';
      authNoValidation.isValid = false;
      authNoValidation.validationMessage =
        '변경된 정보가 있습니다. 인증을 다시 진행해 주세요.';
    }
  } 
  if (newState) authNoValidation.validationMessage = '';
});
watch(() => editEmailInfo.isSentAuth, (newState) => {
  if (!newState) {
    if (editEmailInfo.timerState === 'stop') {
      emailValidation.isValid = false;
      if (editEmailInfo.authNo === '') emailValidation.validationMessage = '';
      else emailValidation.validationMessage =
        '인증번호 입력시간이 초과되었습니다. 인증번호를 다시 발송해 주세요.';
    } else if (editEmailInfo.sendKey) {      
      editEmailInfo.sendKey = null;
      editEmailInfo.authNo = '';
      emailValidation.isValid = false;
      emailValidation.validationMessage =
        '변경된 정보가 있습니다. 인증을 다시 진행해 주세요.';
    }
  } 
  if (newState) emailValidation.validationMessage = '';
});

/** AuthTab - 인증수단 변경 */
watch(() => authMethodTab.value, (newTab, oldTab) => {
  if (oldTab === 1 && newTab === 0) {
    authStatus.value.isSentAuth = false; 
    authStatus.value.showAuthNo = false;
    authStatus.value.timerState = 'stop';
    userAuthInfo.authNo = '';
    userAuthInfo.sendKey = null;
  }
});

/** 스낵바 표시 */
const triggerSnackbar = message => {
  snackbar.value.showSnackbar(message);
};

/** 이름 입력 핸들러 */
const inputNameHandler = (value) => {
  authStatus.value.isSentAuth = false;

  if (value === '') {
    nameValidation.isValid = false;
    nameValidation.validationMessage = '';
  } else if (!validateName(value)) {
    nameValidation.isValid = false;
    nameValidation.validationMessage = '이름은 한글, 영문만 사용 가능합니다.';
  } else {
    nameValidation.isValid = true;
    nameValidation.validationMessage = '';        
  }
}
/** 닉네임 입력 핸들러 */
const inputNicknameHandler = (value) => {
  if (value && value.length > 0 && value.length < 21) nicknameValidation.isValid = true;
  else nicknameValidation.isValid = false;
}
/** 휴대폰 번호 입력 핸들러 */
const inputMobileHandler = (value) => {
  authStatus.value.isSentAuth = false;

  if (value === '') {
    mobileValidation.isValid = false;
    mobileValidation.validationMessage = '';
  } else if (!validateMobile(value)) {
    mobileValidation.isValid = false;
    mobileValidation.validationMessage = '올바르지 않은 전화번호입니다.';
  } else {
    mobileValidation.isValid = true;
    mobileValidation.validationMessage = '';
  }
}
/** 인증번호 입력 핸들러 */
const inputAuthHandler = (value) => {
  if (value && value.length > 0) authNoValidation.isValid = true;
  else authNoValidation.isValid = false;
}
/** 아이디 입력 핸들러 */
const inputUserIdHandler = (value) => {
  if (value === '') {
    userIdValidation.isValid = false;
    userIdValidation.validationMessage = '';
  } else if (!validateUserId(value)) {
    userIdValidation.isValid = false;
    userIdValidation.validationMessage =
      '아이디는 5~20자 영문 소문자, 숫자 조합으로 입력해 주세요.';
  } else {
    userIdValidation.isValid = true;
    userIdValidation.validationMessage = '';
  }
}
/** 비밀번호 입력 핸들러 */
const inputPasswordHandler = (value) => {
  if (value === '') {
    userPwdValidation.isValid = false;
    userPwdValidation.validationMessage = '';
  } else if (!validatePassword(value)) {
    userPwdValidation.isValid = false;
    userPwdValidation.validationMessage = 
      '비밀번호는 8~15자 영문, 숫자, 특수문자 조합으로 입력해 주세요.';
  } else {
    userPwdValidation.isValid = true;
    userPwdValidation.validationMessage = '';
  }

  if (myInfoTab.value === 'edit') {
    inputPassCheckHandler({
      userPwd: value,
      pwdCheck: userEditData.pwdCheck,
    });
  }
}
/** 비밀번호 확인 핸들러 */
const inputPassCheckHandler = ({userPwd, pwdCheck}) => {
  const targetPwd = userPwd || userEditData.userPwd;
  if (pwdCheck === '') {
    pwdCheckValidation.isValid = false;
    pwdCheckValidation.validationMessage = '';
    userEditData.loginPwd = '';
  } else if (pwdCheck !== targetPwd) {
    pwdCheckValidation.isValid = false;
    pwdCheckValidation.validationMessage =
      '비밀번호가 일치하지 않습니다. 입력한 비밀번호를 확인해 주세요.';
      userEditData.loginPwd = '';
  } else {
    pwdCheckValidation.isValid = true;
    pwdCheckValidation.validationMessage = '';
    userEditData.loginPwd = targetPwd;
  }
}
const inputEmailHandler = (value) => {  
  editEmailInfo.isSentAuth = false;

  if (value === '') {
    emailValidation.isValid = false;
    emailValidation.validationMessage = '';
  } else if (!emailValidationCheck(value)) {
    emailValidation.isValid = false;
    emailValidation.validationMessage = '올바른 이메일 형식이 아닙니다.';
  } else {
    emailValidation.isValid = true;
    emailValidation.validationMessage = '';
  }
}
/** 통합 입력값 핸들러 */
const inputHandler = ({value, dataType}) => {  
  value = value.trim();
  switch (dataType) {
    case 'name':
      inputNameHandler(value);
      break;
    case 'nickname':
      inputNicknameHandler(value);
      break;
    case 'email':
      inputEmailHandler(value);
      break;
    case 'mobileNo':
      value = value.replace(/\D/g, '');
      inputMobileHandler(value);
      break;
    case 'authNo':
      value = value.replace(/\D/g, '');
      inputAuthHandler(value);
      break;
    case 'userId':
      inputUserIdHandler(value);
      break;
    case 'userPwd':
      inputPasswordHandler(value);
      break;
    case 'pwdCheck':
      inputPassCheckHandler({pwdCheck: value});
      break;
    default:
      break;
  }
  return value;
}
/** 내 정보 요청 */
const refreshUpdatedAt = async ({}) => {  
  try {
    const result = await getUserInfoAPI();
    
    if (result) {
      accountInfo.userId = result.signAccount.loginId;
      accountInfo.name = result.name;
      accountInfo.nickname = result.nickname;
      accountInfo.email = result.email;
      accountInfo.mobileNo = formatMobileNo(result.mobileNo);
      accountInfo.createdAt = formatDateTime(result.createdAt);
      accountInfo.loggedAt = formatDateTime(result.loggedAt);
    }
  } catch (e) {
    DEV_MODE && console.log('Error refreshing updatedAt:', e);
  } finally {
    await myInfoStore.fetchMyInfo();
  }
};
/** 내 정보 내에서 탭 변경 */
const changeMyInfoTab = (newTab) => {
  if (newTab === 'edit') {
    userEditData.nickname = accountInfo.nickname;
  }
  myInfoTab.value = newTab;
};

/** AuthTab - 인증번호 발송 버튼 클릭 */
const clickSendButton = async () => {
  userAuthInfo.authNo = '';
  authStatus.value.isSentAuth = false;

  try {
    const params = {
      type: 'mobile',
    }
    const result = await sendMeAuthAPI({params})

    userAuthInfo.sendKey = result;

    authStatus.value.isSentAuth = true;
    authStatus.value.showAuthNo = true;
    if (myInfoTab.value === 'auth') {
      popupMessage.value =
        '인증번호를 발송했습니다.<br>인증번호가 오지 않으면 정보를<br>다시 확인해 주세요.';
      showPopup.value = true;
    }
    authStatus.value.timerState = 'reset';
    setTimeout(() => {authStatus.value.timerState = 'start'}, 50);
  } catch (e) {
    DEV_MODE && console.log('useJoin clickSendButton error:', e);
  }
}
/** AuthTab - 인증번호 타이머 시간 만료시 */
const timerEnded = () => {
  authStatus.value.timerState = 'stop';
  authStatus.value.isSentAuth = false;
  userAuthInfo.sendKey = null;
};

/** AuthTab - 인증번호 확인 요청 */
const checkAuthNo = async ({authNo, sendKey}) => {
  try {
    const result = await checkAuthNoAPI(authNo, sendKey);
    return result;
  } catch (e) {
    DEV_MODE && console.log('useJoin checkAuthNo error:', e);
  }
};
/** AuthTab - 확인 버튼 클릭 */
const clickConfirm = async () => {
  if (myInfoTab.value === 'auth') {
    if (authMethodTab.value === 0) {
      await checkPassword();
      // 비밀번호 체크 API 가 없음.
    } else if (authMethodTab.value === 1) {
      const response = await checkAuthNo({
        authNo: userAuthInfo.authNo,
        sendKey: userAuthInfo.sendKey,
      }); 
      if (response) {
        triggerSnackbar('인증되었습니다.');
        changeMyInfoTab('edit');
      }
    }
  }
};


/** EditTab - 비밀번호 재설정 버튼 클릭 */
const clickEditPassword = async () => {
  showEditPassword.value = !showEditPassword.value;
};
/** EditTab - 인증번호 입력창 타이머 종료시 */
const modalTimerEnded = ({type}) => {
  if (type === 'mail') {
    editEmailInfo.sendKey = null;
    editEmailInfo.isSentAuth = false;
    editEmailInfo.timerState = 'stop';
  } else if (type === 'mobile') {

  }
};
const isVerifiedEmail = ref(false);
/** EditTab - 이메일 인증 확인 */
const clickEditAuthConfirm = async ({type}) => {
  if (showEditMobileNo.value) {
    if (type === 'mail') {
      const authStatus = await checkAuthNo({
        authNo: editEmailInfo.authNo,
        sendKey: editEmailInfo.sendKey,
      });
      if (!authStatus) {
        popupMessage.value =
          '인증번호를 다시 확인해주세요.';
        showPopup.value = true;
        return;
      } else {
        popupMessage.value =
          '이메일 인증이 완료되었습니다.';
        showPopup.value = true;
        isVerifiedEmail.value = true;
        resetEditEmail();
      }
      return;
    } else if (type === 'mobile') {
      const isExist = await checkMobileDuplicationAPI(userEditData.mobileNo);

      if (isExist) {
        popupMessage.value =
          '이미 사용중인 휴대폰 번호입니다.';
        showPopup.value = true;
        // showEditMobileNo.value = false;
        return;
      }

      const params = {
        mobileNo: userEditData.mobileNo,
        sendKey: editMobileInfo.sendKey,
        authNo: editMobileInfo.authNo
      }
      const result = await mypageResetMobileAPI({params});
      if (result) {
        await refreshUpdatedAt({});
        popupMessage.value =
          '휴대폰 인증이 완료되었습니다.';
        showPopup.value = true;
        showEditMobileNo.value = false;
      }
      return;
    }
  }

  try {
    if (type === 'mail') {
      const authStatus = await checkAuthNo({
        authNo: editEmailInfo.authNo,
        sendKey: editEmailInfo.sendKey,
      });

      if (!authStatus) {
        popupMessage.value =
          '인증번호를 다시 확인해주세요.';
        showPopup.value = true;
        return;
      }

      if (authStatus) {
        const useStatus = await checkEmailDuplication();
        if (useStatus === 'AVAILABLE') {
          const params = {
            email: userEditData.email,
            authNo: editEmailInfo.authNo,
            sendKey: editEmailInfo.sendKey,
          }
          const result = await mypageResetEmailAPI({params});
          if (result) await refreshUpdatedAt({});
          resetEditEmail();
          showEditEmail.value = false;
          // userEditData.email = accountInfo.email;
        } else if (useStatus === 'USED') {
          popupMessage.value =
            '이미 사용중인 이메일 주소입니다.';
          showPopup.value = true;
        } else {
          popupMessage.value = '확인에 실패하였습니다.';
          showPopup.value = true;
        }
      }
    }
  } catch (e) {
    DEV_MODE && console.log('myinfo clickEditAuthConfirm error:', e);
  }
}

/** AuthTab - 비밀번호 인증 API 호출 */
const checkPassword = async () => {
  const data = {
    password: userAuthInfo.userPwd,
  }
  try {
    const result = await checkPasswordAPI({data});
    if (result) {
      triggerSnackbar('인증되었습니다.')
      changeMyInfoTab('edit');
    }
    else {
      popupMessage.value =
        '비밀번호를 확인해 주세요.';
      showPopup.value = true;
    }
  } catch (e) {
    DEV_MODE && console.log('myinfo checkPassword error:', e);
  }
}

/** 비밀번호 재설정 정보 초기화 */
const resetEditPassword = () => {
  userEditData.userPwd = '';
  userEditData.pwdCheck = '';
  userEditData.loginPwd = '';
  userPwdValidation.isValid = false;
  userPwdValidation.validationMessage = '';
  pwdCheckValidation.isValid = false;
  pwdCheckValidation.validationMessage = '';
};

/** 이메일 재설정 정보 초기화 */
const resetEditEmail = () => {
  editEmailInfo.timerState = 'stop';
  editEmailInfo.isSentAuth = false;
  editEmailInfo.showAuthNo = false;
  editEmailInfo.sendKey = null;
  editEmailInfo.authNo = '';
  userEditData.email = '';
  emailValidation.isValid = false;
  emailValidation.validationMessage = '';
};

/** 휴대폰 번호 재설정 정보 초기화 */
const resetEditMobile = () => {
  editMobileInfo.timerState = 'stop';
  editMobileInfo.isSentAuth = false;
  editMobileInfo.showAuthNo = false;
  editMobileInfo.sendKey = null;
  editMobileInfo.authNo = '';
  userEditData.mobileNo = '';
}

/** EditTab - 이메일 중복 검사 */
const checkEmailDuplication = async () => {
  try {
    let status = 'AVAILABLE';
    const result = await checkEmailDuplicationAPI(userEditData.email);
    if (result) status = 'USED';
    return status;
  } catch (e) {
    DEV_MODE && console.log('useJoin checkMobileDuplication error:', e);
    return 'ERROR';
  }
}

const sendAuthMobile = async () => {
  try {
    const result = await sendAuthAPI({
      // name: accountInfo.name,
      mobileNo: userEditData.mobileNo,
      type: 'mobile',
    });
    editMobileInfo.sendKey = result.key;
    editMobileInfo.isSentAuth = true;
    editMobileInfo.showAuthNo = true;
    // popupMessage.value =
    //   '인증번호를 발송했습니다.<br>인증번호가 오지 않으면 정보를<br>다시 확인해 주세요.';
    // showPopup.value = true;
    editMobileInfo.timerState = 'reset';
    setTimeout(() => {editMobileInfo.timerState = 'start'}, 50);
  } catch (e) {
    DEV_MODE && console.log('useJoin clickSendButton error:', e);
  }
}

const clickEditMobileButton = async (event) => {
  if (!accountInfo.email) {
    popupMessage.value =
      '휴대폰 번호를 변경하시는 경우, 이메일 인증이<br>필요합니다. 이메일 등록이 되어있지 않은 경우,<br>이메일을 먼저 등록해 주세요.';
    showPopup.value = true;
    return;
  } else {
    if (!showEditMobileNo.value) showEditMobileNo.value = !event;
    else {
      if (editEmailButtonActive) {
        // await clickEmailSendButton();
        // editEmailInfo.showAuthNo = true;
      }
    }
  }
}

/** EditTab - 비밀번호 재설정 API 호출 */
const editPassword = async () => {
  const params = {
    password: userEditData.loginPwd,
  };
  try {
    const result = await mypageResetInfoAPI({type: 'password', params});

    if (result) {
      popupMessage.value = `비밀번호가 변경되었습니다.`;
      popupButtonText.value = `확인`;
      showPopup.value = true;
    }
  } catch (e) {
    DEV_MODE && console.log('myinfo editPassword error:', e);
  } finally {
    showEditPassword.value = false;
  }
}

/** EditTab - 휴대폰 번호 재설정 API 호출 */
const editMobileNo = async () => {
  const params = {

  };
  try {
    const result = await mypageResetInfoAPI({type: 'mobile', params});
    
  } catch (e) {
    DEV_MODE && console.log('myinfo editMobileNo error:', e);
  }
}


/** EditTab - 닉네임 변경 API 호출 */
const editNickname = async () => {
  const data = {
    nickname: userEditData.nickname,
  };
  try {
    await updateBasicInfoAPI({data});
    await refreshUpdatedAt({});
    triggerSnackbar('닉네임이 변경되었습니다.')
  } catch (e) {
    DEV_MODE && console.log('myinfo editNickname error:', e);
  }
};

/** 팝업 닫기 */
const closePopup = () => showPopup.value = false;

/** EditTab -  */
const clickEmailSendButton = async () => {
  try {
    let key = null;
    if (!showEditMobileNo.value) {
      const result = await sendAuthAPI({
        email: userEditData.email,
        type: 'mail',
      });
      key = result.key;      
    } else {
      key = await sendMeAuthAPI({
        params: {
          type: 'mail'
        }
      });
    }
    editEmailInfo.sendKey = key;
    editEmailInfo.isSentAuth = true;
    editEmailInfo.showAuthNo = true;
    editEmailInfo.timerState = 'reset';
    setTimeout(() => {editEmailInfo.timerState = 'start'}, 50);
  } catch (e) {
    DEV_MODE && console.log('useJoin clickSendButton error:', e);
  }
}

/** EditTab - 정보 수정 모달 확인 버튼 클릭 */
const clickUpdateMyInfo = async () => {
  if (showEditPassword.value) {
    await editPassword();
  } else if (showEditEmail.value) {
    if (editEmailButtonActive) {
      if (!editEmailInfo.isSentAuth) {
        await clickEmailSendButton();
        editEmailInfo.showAuthNo = true;
      } else {
        await clickEditAuthConfirm();
        closeEditModal();
      }
    }
  }
}

/** EditTab - '이메일 재설정' 버튼 클릭 */
const clickEditEmailButton = async (event) => {  
  if (accountInfo.email === null) {
    if (!showEditEmail.value) showEditEmail.value = event;
    if (editEmailButtonActive) {
      await clickEmailSendButton();
      editEmailInfo.showAuthNo = true;
    }
  } else {
    // '이메일 재설정' 버튼 클릭
    if (!showEditEmail.value) showEditEmail.value = event;
    else {
      // '인증번호 발송' 버튼 클릭
      if (editEmailButtonActive) {
        await clickEmailSendButton();
        editEmailInfo.showAuthNo = true;
      }
    }
  }
}

/** 정보 수정 모달 닫기 */
const closeEditModal = () => {
  showEditPassword.value = false;
  showEditEmail.value = false;
  showEditMobileNo.value = false;
  resetEditPassword();
  resetEditEmail();
  resetEditMobile();
  isVerifiedEmail.value = false;
};
</script>

<template>
  <div class="card-container">
    <ViewTab 
      v-if="myInfoTab === 'view'"
      :account-info="accountInfo"
      @change="changeMyInfoTab"
    />

    <AuthTab
      v-if="myInfoTab === 'auth'"
      :account-info="accountInfo"
      :user-auth-info="userAuthInfo"
      :auth-method-tab="authMethodTab"
      :user-pwd-validation="userPwdValidation"
      :mobile-validation="mobileValidation"
      :auth-no-validation="authNoValidation"
      :auth-status="authStatus"
      :send-button-text="sendButtonText"
      :input-handler="inputHandler"
      :timer-ended="timerEnded"
      :is-confirm-disabled="isConfirmDisabled"
      @update:authMethodTab="authMethodTab = $event"
      @send="clickSendButton"
      @confirm="clickConfirm"
    />

    <EditTab
      v-if="myInfoTab === 'edit'"
      :account-info="accountInfo"
      :user-edit-data="userEditData"
      :show-edit-password="showEditPassword"
      :show-edit-email="showEditEmail"
      :edit-email-info="editEmailInfo"
      :selected-domain="selectedDomain"
      :edit-email-button-text="editEmailButtonText"
      :edit-email-button-active="editEmailButtonActive"
      :show-edit-mobile-no="showEditMobileNo"
      :edit-mobile-button-text="editMobileButtonText"
      :nickname-validation="nicknameValidation"
      :email-validation="emailValidation"
      :input-handler="inputHandler"
      :edit-timer-ended="modalTimerEnded"
      :click-edit-auth-confirm="clickEditAuthConfirm"
      @edit-password="clickEditPassword"
      @update:nickname="editNickname"
      @update:showEditEmail="clickEditEmailButton"
      @update:selectedDomain="selectedDomain = $event"
      @update:showEditMobileNo="clickEditMobileButton"
    />

    <DeleteTab 
      v-if="myInfoTab === 'delete'"
      @change="changeMyInfoTab"
    />
  </div>

  <EditModal 
    :account-info="accountInfo"
    :show-edit-password="showEditPassword"
    :show-edit-email="showEditEmail"
    :show-edit-mobile-no="showEditMobileNo"    
    :user-edit-data="userEditData"
    :selected-domain="selectedDomain"
    :edit-email-info="editEmailInfo"
    :edit-email-button-text="editEmailButtonText"
    :edit-email-button-active="editEmailButtonActive"
    @send:email="clickEmailSendButton"
    @update:showEditEmail="clickEditEmailButton"
    @verify="clickEditAuthConfirm"
    @close="closeEditModal"
    @confirm="clickUpdateMyInfo"
    :user-pwd-validation="userPwdValidation"
    :pwd-check-validation="pwdCheckValidation"
    :nickname-validation="nicknameValidation"
    :email-validation="emailValidation"
    :mobile-validation="mobileValidation"
    :auth-no-validation="authNoValidation"
    :input-handler="inputHandler"
    :modal-timer-ended="modalTimerEnded"
    :is-verified-email="isVerifiedEmail"
    :edit-mobile-info="editMobileInfo"
    @send:mobile="sendAuthMobile"
  />

  <PopupModal
    v-if="showPopup" 
    :message="popupMessage" 
    :buttonText="popupButtonText" 
    :isVisible="showPopup"
    @close="closePopup"
  />

  <Snackbar ref="snackbar"/>
</template>

<style scoped>
@media (max-width: 960px) {
  .card-content {
    flex: 1 1 100%; /* 작은 화면에서는 한 줄에 하나씩 배치 */
  }
}

.card-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  user-select: none;

  .v-field__input {
    margin-bottom: 0px !important;
    padding: 0px;
    color: var(--Gray-600, #757575);
    font-family: 'Pretendard';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 14px;
  }

  .v-field__append-inner {
    height: 48px !important;
  }
}
</style>

<style>
.button-section {
  padding: 0px 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .withdraw-button-wrap {
    margin: 8px 0px 20px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    button {
      color: var(--Gray-600, #757575);
      text-align: right;
      font-family: 'Pretendard';
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      text-decoration-line: underline;
    }
  }

  .save-button {
    width: 260px;
  }
}
</style>