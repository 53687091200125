{
  "UI Elements": "ÉLÉMENTS DE L'UI",
  "Forms & Tables": "Formulaires et tableaux",
  "Pages": "Des pages",
  "Charts & Maps": "Graphiques et cartes",
  "Others": "Autres",
  "Typography": "Typographie",
  "Cards": "Cartes",
  "Basic": "De base",
  "Advance": "Avance",
  "Analytic": "Analytique",
  "Card Action": "Action de la carte",
  "Components": "Composants",
  "Alert": "Alerte",
  "Close Alert": "Fermer l'alerte",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Bouton",
  "Calendar": "Calendrier",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress Circular": "Progrès circulaire",
  "Progress Linear": "Progrès Linéaire",
  "Autocomplete": "Saisie automatique",
  "Tooltip": "Info-bulle",
  "Slider": "Glissière",
  "Date Time Picker": "Sélecteur de date et d'heure",
  "Select": "Sélectionner",
  "Switch": "Commutateur",
  "Checkbox": "Case à cocher",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Évaluation",
  "File Input": "Entrée de fichier",
  "Otp Input": "Entrée Otp",
  "Form Layout": "Disposition du formulaire",
  "Form Validation": "Validation de formulaire",
  "Charts": "Graphiques",
  "Apex Chart": "Graphique Apex",
  "Chartjs": "Chartjs",
  "Account Settings": "Paramètres du compte",
  "User Profile": "Profil de l'utilisateur",
  "FAQ": "FAQ",
  "Dialog Examples": "Exemples de dialogue",
  "Pricing": "Tarification",
  "List": "liste",
  "Edit": "Éditer",
  "Nav Levels": "Niveaux de navigation",
  "Level 2.1": "Niveau 2.1",
  "Level 2.2": "Niveau 2.2",
  "Level 3.1": "Niveau 3.1",
  "Level 3.2": "Niveau 3.2",
  "Raise Support": "Augmenter le soutien",
  "Documentation": "Documentation",
  "Analytics": "Analytique",
  "Apps & Pages": "Applications et pages",
  "Email": "Email",
  "Chat": "Bavarder",
  "Invoice": "Facture d'achat",
  "Preview": "Aperçu",
  "Add": "Ajouter",
  "User": "Utilisateur",
  "View": "Vue",
  "Login v1": "Connexion v1",
  "Login v2": "Connexion v2",
  "Login": "Connexion",
  "Register v1": "S'inscrire v1",
  "Register v2": "S'inscrire v2",
  "Register": "S'inscrire",
  "Forget Password v1": "Oubliez le mot de passe v1",
  "Forget Password v2": "Oubliez le mot de passe v2",
  "Forgot Password v1": "Oubliez le mot de passe v1",
  "Forgot Password v2": "Oubliez le mot de passe v2",
  "Forgot Password": "Oubliez le mot de passe",
  "Reset Password v1": "Réinitialiser le mot de passe v1",
  "Reset Password v2": "Réinitialiser le mot de passe v2",
  "Reset Password": "Réinitialiser le mot de passe",
  "Miscellaneous": "Divers",
  "Coming Soon": "Bientôt disponible",
  "Not Authorized": "Pas autorisé",
  "Under Maintenance": "En maintenance",
  "Error": "Erreur",
  "Statistics": "Statistiques",
  "Card Actions": "Actions de la carte",
  "Actions": "Actions",
  "Access Control": "Contrôle d'accès",
  "User Interface": "Interface utilisateur",
  "Icons": "Icône",
  "Chip": "Ébrécher",
  "Dialog": "Dialogue",
  "Expansion Panel": "Panneau d'extension",
  "Combobox": "Boîte combo",
  "Textfield": "Champ de texte",
  "Range Slider": "Curseur Gamme",
  "Menu": "Menu",
  "Snackbar": "Casse-croûte",
  "Tabs": "Onglets",
  "Form Elements": "Éléments de formulaire",
  "Form Layouts": "Dispositions de formulaire",
  "Authentication": "Authentification",
  "Page Not Found - 404": "Page introuvable - 404",
  "Not Authorized - 401": "Non autorisé - 401",
  "Server Error - 500": "Erreur de serveur - 500",
  "2": "2",
  "Gamification": "Gamification",
  "Forms": "Formes",
  "Timeline": "Chronologie",
  "Disabled Menu": "Menu désactivé",
  "Help Center": "Centre d'aide",
  "Verify Email": "Vérifier les courriels",
  "Verify Email v1": "Vérifier l'e-mail v1",
  "Verify Email v2": "Vérifier l'e-mail v2",
  "Two Steps": "Deux étapes",
  "Two Steps v1": "Deux étapes v1",
  "Two Steps v2": "Deux étapes v2",
  "Custom Input": "Entrée personnalisée",
  "Extensions": "Rallonges",
  "Tour": "Tour",
  "Register Multi-Steps": "Enregistrer plusieurs étapes",
  "Wizard Examples": "Exemples de guide",
  "Checkout": "Check-out",
  "Create Deal": "Créer une offre",
  "Property Listing": "Liste des propriétés",
  "Roles & Permissions": "Rôles et autorisations",
  "Roles": "Rôles",
  "Permissions": "Autorisations",
  "Simple Table": "Table simple",
  "Tables": "Tables",
  "Data Table": "Table de données",
  "Apps": "Applications",
  "Misc": "Divers",
  "Wizard Pages": "Pages de l'assistant",
  "Form Wizard": "Assistant de formulaire",
  "Numbered": "Numéroté",
  "Product": "Produit",
  "Category": "Catégorie",
  "Order": "Ordre",
  "Details": "Détails",
  "Customer": "Client",
  "Manage Review": "Gérer la revue",
  "Referrals": "Références",
  "Settings": "Paramètres",
  "Course Details": "Détails du cours",
  "My Course": "Mon cours",
  "Overview": "Aperçu",
  "Academy": "Académie",
  "Logistics": "Logistique",
  "Dashboard": "Tableau de bord",
  "Fleet": "Flotte",
  "Editors": "Éditeurs",
  "Front Pages": "Pages frontales",
  "Landing": "d'atterrissage",
  "checkout": "Check-out",
  "Payment": "Paiement",
  "Swiper": "Swiper",
  "Widgets": "Widgets",
  "5": "5",
  "10": "10",
  "20": "20",
  "25": "25",
  "50": "50",
  "100": "100",
  "$vuetify": {
    "badge": "Badge",
    "noDataText": "Pas de données disponibles",
    "close": "Fermer",
    "open": "Ouvert",
    "dataFooter": {
      "itemsPerPageText": "Objets par page:",
      "itemsPerPageAll": "Tout",
      "pageText": "{0}-{1} of {2}",
      "firstPage": "Première page",
      "prevPage": "Page précédente",
      "nextPage": "Page suivante",
      "lastPage": "Dernière page"
    },
    "pagination": {
      "ariaLabel": {
        "root": "racine",
        "previous": "précédente",
        "next": "suivante",
        "currentPage": "page actuelle",
        "page": "page",
        "first": "première",
        "last": "dernière"
      }
    },
    "input": {
      "clear": "dégager",
      "appendAction": "ajouter une action",
      "prependAction": "préfixer l'action",
      "otp": "otp"
    },
    "fileInput": {
      "counterSize": "Taille du compteur"
    },
    "rating": {
      "ariaLabel": {
        "item": "Objet"
      }
    }
  }
}
