<script setup>
import { onMounted } from 'vue'

const emit = defineEmits()

const popQrBox = ref(true)

onMounted(() => {
})


const close = () => emit('closePopQrBox')

function openGooglePlay() {
  window.open('https://play.google.com/store/apps/details?id=com.twofivedosajuapp&hl=ko');
}

function openAppStore() {
  window.open('https://apps.apple.com/kr/app/%EB%82%AD%EB%A7%8C-%EB%A7%8C%EC%84%B8%EB%A0%A5/id6462405118')
}

watch(popQrBox, (newVal, oldVal) => {
  if(!newVal){
    close()
    popQrBox.value = false
  }
})

</script>

<template>
  <div>
    <v-dialog v-model="popQrBox" max-width="400px" content-class="dialog-st12 qr-popup">
      <div class="pop-inner br16" style="height: 338px">
        <div class="pop-tit-box">
          <div class="pop-tit-sub">낭만만세력 앱 다운로드</div>
          <!-- <div class="close-button" @click="close">Close</div> -->
          <button class="dial-close" @click="close"></button>
        </div>
        <div class="pop-body pd-large">
          <div class="pop-expl">QR 코드를 스캔하여 앱을 다운받아보세요!</div>
          <div class="qr-img-box">
            <div class="">
                <img src="@/assets/images_manse/manse_QR_goToAppStore.png" class="manse_qr_img">
             
            </div>

          </div>

          <div class="btn-store-box">
            <div class="google-play" style="cursor: pointer;" @click="openGooglePlay">
            </div>

            <div class="app-store" style="cursor: pointer;" @click="openAppStore">
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
