export default {
    state: {
        currentBoard: '',
        currentBoardPage: 1,
        currentEssayPage: 1,
        essayFocus: {
            list: false,
            view: false,
        }
    },
    mutations: {
        setCurrentBoard(state, board) {
            state.currentBoard = board;
        },
        setCurrentBoardPage(state, page) {
            state.currentBoardPage = page;
        },
        setCurrentEssayPage(state, page) {
            state.currentEssayPage = page;
        },
        setEssayFocus(state, {key, value}) {
            state.essayFocus[key] = value;
        },
    },
    actions: {
        saveCurrentBoard({commit}, board) {
            commit('setCurrentBoard', board);
        },
        saveCurrentBoardPage({commit}, page) {
            commit('setCurrentBoardPage', page);
        },
        saveCurrentEssayPage({commit}, page) {
            commit('setCurrentEssayPage', page);
        },
        saveEssayFocus({commit}, {key, value}) {
            commit('setEssayFocus', {key, value});
        },
    },
};