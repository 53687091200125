<script setup>
import FullCalendar from '@fullcalendar/vue3'
import moment from 'moment'
import { useCalendar } from '@/composables/useCalendar'
import SajuClockPopup from '@/components/calendar/SajuClockPopup.vue'
import ComLatest from '@/components/manse/ComLatest.vue'

definePage({ meta: { layout: 'manse' } })

const isLoading = ref(false)
const focus = ref('')
const type = ref('month')
const typeToLabel = reactive({
  month: 'Month',
  week: 'Week',
  day: 'Day',
  '4day': '4 Days',
})
const selectedEvent = reactive({})
const selectedElement = ref(null)
const selectedOpen = ref(false)
const events = ref([])
// const selectedDate = ref(new Date());
const todayDate = ref(moment().format('YYYY-MM-DD'))
const curMonthData = ref(null)
const curMonthDataArray = ref([])
const customFormat = ref('MMMM D')
const dayData = ref(null)
const disChnFlag = ref(true)

/** 달력 필터 - 음력 선택 여부 */
const isLunarChecked = ref(true)
/** 달력 필터 - 절기 선택 여부 */
const isSeasonChecked = ref(true)
/** 달력 필터 - 공휴일 선택 여부 */
const isHolidayChecked = ref(true)
/** 달력 필터 - 한글표기 선택 여부 */
const isKorChecked = ref(true)

/** DatePicker 첫 표시 여부 */
const datePickerHasUpdated = ref(false)
/** DatePicker ViewMode 상태값 */
const viewMode = ref('months')
/** DatePicker 표시 상태값 */
const showPicker = ref(false)
/** DatePicker 선택된 날짜 */
const selectedPickerDate = ref(null)

/** 마우스 드래그 시작 X좌표 */
const startX = ref(0)
/** 마우스 드래그 X좌표 이동 거리 */
const translateX = ref(0)
/** 마우스 드래그 동작 상태 */
const isDragging = ref(false)
/** 모바일 터치 시작 X좌표 */
const touchStartX = ref(0)
/** 모바일 터치 시작 Y좌표 */
const touchStartY = ref(0)
const screenWidth = ref(window.innerWidth)
const localeOptions = reactive({
  firstDayOfWeek: 1, // Starts on Monday
  masks: {
    L: 'YYYY년 MM월', // Format in Korean
    l: 'YYYY-MM', // Default format
  },
})
const isPopSajuClock = ref(false)

const curYear = ref(new Date().getFullYear())
const curMonth = ref(new Date().getMonth() + 1) // JavaScript의 월은 0부터 시작하므로 +1
const currentClockRef = ref(null)

const {
  calendarOptions,
  refCalendar,
  viewTitle,
  selectedDate,
  firstSeason,
  firstSeasonMonth,
  prev,
  next,
  isToday,
  changeViewTitle,
} = useCalendar({
  isLunarChecked,
  isSeasonChecked,
  isHolidayChecked,
  isKorChecked,
})

// watch(selectedPickerDate, (newValue) => {
//   console.log('========================================')
//   console.log('newValue:', newValue)
//   console.log('========================================')
// })

/** TODAY 버튼 클릭 */
const today = () => {
  refCalendar.value.getApi().gotoDate(new Date())
  selectedDate.value = moment().format('YYYY-MM-DD')
  changeViewTitle()
}
/** 사주시계 팝업  */
const setSajuClock = (state) => (isPopSajuClock.value = state)

/** DatePicker 달 선택 시 동작 */
// const setFirstDayOfMonth = async (updatePickerDate) => {
//   console.log('========================================')
//   console.log('updatePickerDate:', updatePickerDate)
//   console.log('========================================')

//   if (!updatePickerDate) showPicker.value = false

//   if (!datePickerHasUpdated.value) {
//     datePickerHasUpdated.value = true
//   } else {
//     if (viewMode.value === 'year') {
//       viewMode.value = 'months'
//     } else {
//       showPicker.value = false;
//       viewMode.value = 'months'
//     }
//     // const dateParts = selectedPickerDate.split('-');
//     // const year = parseInt(dateParts[0], 10);
//     // const month = parseInt(dateParts[1], 10);
//     // const firstDayOfMonth = new Date(year, month - 1, 1);
//     // focus.value = firstDayOfMonth;
//   }
// }
// /** viewMode 제어 */
// const updateViewMode = (updateViewMode) => {
//   // 날짜선택('month')은 사용하지 않으므로 년 선택('year')으로 변경되도록 설정
//   if (updateViewMode === 'month') viewMode.value = 'year'
//   else viewMode.value = updateViewMode
// }

/** 마우스 클릭 이벤트 핸들러 - 시작 */
const handleMouseDown = (event) => {
  startX.value = event.clientX
  isDragging.value = true
  window.addEventListener('mousemove', handleMouseMove)
  window.addEventListener('mouseup', handleMouseUp)
}
/** 마우스 클릭 이벤트 핸들러 - 이동 */
const handleMouseMove = (event) => {
  if (!isDragging.value) return
  const deltaX = event.clientX - startX.value
  translateX.value += deltaX
  startX.value = event.clientX
}
/** 마우스 클릭 이벤트 핸들러 - 종료 */
const handleMouseUp = () => {
  if (isDragging.value) {
    isDragging.value = false
    window.removeEventListener('mousemove', handleMouseMove)
    window.removeEventListener('mouseup', handleMouseUp)

    const sliderWidth = window.innerWidth * 0.8
    const threshold = sliderWidth / 12 // Adjust as needed

    if (Math.abs(translateX.value) > threshold) {
      if (translateX.value > 0) {
        prev() // Swipe to the right (previous month)
      } else {
        next() // Swipe to the left (next month)
      }
    }
    translateX.value = 0 // Reset translateX value
  }
}

/** 모바일 터치 이벤트 핸들러 - 시작 */
const handleTouchStart = (event) => {
  // 멀티 터치 이벤트인 경우, 처리 중지
  if (event.touches.length > 1) return

  // 터치 시작 시의 좌표 저장
  touchStartX.value = event.touches[0].clientX
  touchStartY.value = event.touches[0].clientY
}
/** 모바일 터치 이벤트 핸들러 - 스와이프 */
const handleTouchMove = (event) => {
  // 멀티터치 이벤트인 경우, 처리 중지
  if (event.touches.length > 1) return

  // 터치 종료 시의 좌표 저장
  const touchEndX = event.touches[0].clientX
  // X 좌표 변화량 계산
  const deltaX = touchEndX - touchStartX.value

  if (Math.abs(deltaX) > 50) {
    if (deltaX > 0) {
      prev() // 왼쪽에서 오른쪽으로 스와이프
    } else {
      next() // 오른쪽에서 왼쪽으로 스와이프
    }
  }
}

/** 통합 이벤트 핸들러 - 이벤트 시작 */
const handleStart = (event) => {
  const point = event.touches ? event.touches[0] : event
  startX.value = point.clientX
  isDragging.value = true
}
/** 통합 이벤트 핸들러 - 이벤트 이동 */
const handleMove = (event) => {
  if (!isDragging.value) return

  const point = event.touches ? event.touches[0] : event
  const deltaX = point.clientX - startX.value

  if (Math.abs(deltaX) > 50) {
    isDragging.value = false
    if (deltaX > 0) {
      prev() // 왼쪽에서 오른쪽으로 스와이프 또는 드래그 (이전 달)
    } else {
      next() // 오른쪽에서 왼쪽으로 스와이프 또는 드래그 (다음 달)
    }
    translateX.value = 0 // 동작 후 초기화
  }
}
/** 통합 이벤트 핸들러 - 이벤트 종료 */
const handleEnd = () => {
  if (isDragging.value) {
    isDragging.value = false
    translateX.value = 0 // 초기화
  }
}

const printManse = () => {
  alert('사주조회 후 이용하여 주시기 바랍니다.')
}
</script>

<template>
  <div class="calendar-wrap">
    <div class="content-main mb-large">
      <section class="inner-base-calendar">
        <div class="com-tit-exp short" />
        <div>
          <div class="schedule-year-box">
            <v-sheet>
              <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="prev">
                  <VIcon class="schedule-year-icon-arrow" icon="ri-arrow-left-s-line" />
                </v-btn>
                <v-toolbar-title class="txt-year" @click="showPicker = !showPicker">
                  {{ viewTitle }}
                </v-toolbar-title>
                <v-btn fab text small color="grey darken-2" @click="next">
                  <VIcon class="schedule-year-icon-arrow" icon="ri-arrow-right-s-line" />
                </v-btn>
                <button v-if="!isToday()" class="btn-today" @click="today">TODAY</button>
                <button class="btn-clock" @click="setSajuClock(true)">사주시계</button>
                <v-spacer />
              </v-toolbar>
            </v-sheet>

            <!-- <v-dialog v-model="showPicker" width=auto>
              <v-card class="datePicker">
                <v-card-text>
                  <v-date-picker
                    v-model="selectedPickerDate"
                    :view-mode="viewMode"
                    hide-header="true"
                    hide-weekdays="true"
                    @update:month="showPicker = false"
                    @update:year="value = console.log('value:', value)"
                    @update:viewMode="updateViewMode"
                    @update:modelValue="setFirstDayOfMonth"
                  />
                </v-card-text>
              </v-card>
            </v-dialog> -->
          </div>

          <v-row class="schedule-search">
            <v-col class="sch-checkbox">
              <input id="lunar" v-model="isLunarChecked" type="checkbox" class="checkbox" />
              <label class="label font" for="lunar">음력</label>
              <input id="season" v-model="isSeasonChecked" type="checkbox" class="checkbox" />
              <label class="label font" for="season">절기</label>
              <input id="holiday" v-model="isHolidayChecked" type="checkbox" class="checkbox" />
              <label class="label font" for="holiday">공휴일</label>
              <input id="kor_display" v-model="isKorChecked" type="checkbox" class="checkbox" />
              <label class="label font" for="kor_display">한글표기</label>
            </v-col>
            <v-col class="sch-word">
              {{ firstSeason }} (<span class="month">{{ firstSeasonMonth }}</span
              >月)
            </v-col>
          </v-row>

          <div class="sec-sch-body">
            <div class="quick-menu-box">
              <ComLatest @printclick="printManse" />
            </div>
            <v-row
              class="fill-height"
              @mousedown="handleStart"
              @mousemove="handleMove"
              @mouseup="handleEnd"
              @touchstart="handleStart"
              @touchmove="handleMove"
              @touchend="handleEnd"
            >
              <v-col>
                <v-sheet height="700">
                  <FullCalendar ref="refCalendar" class="full-calendar" :options="calendarOptions">
                  </FullCalendar>
                </v-sheet>
              </v-col>
            </v-row>
          </div>
        </div>
      </section>
    </div>

    <div v-if="isLoading" class="loading-full-wrap">
      <div class="inner-box">
        <div ref="loadingContainer" class="loading-container">
          <v-progress-circular :size="50" :width="3" color="primary" indeterminate />
        </div>
      </div>
    </div>

    <SajuClockPopup v-if="isPopSajuClock" ref="currentClockRef" @closeClock="setSajuClock(false)" />
  </div>
</template>

<style lang="scss">
.calendar-wrap {
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.sec-sch-body {
  position: relative;
}
.quick-menu-box {
  position: absolute;
  right: -110px;
  top: 12px;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: transparent;
}
.v-calendar-monthly .v-calendar-weekly__head-weekday {
  height: 38px;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #23252e;
  font-size: 13px;
  font-weight: 500;
  border-bottom: 0.5px solid #e2e2e2;
}
.v-calendar-weekly__day-label {
  margin: 0 !important;
}
.v-calendar-weekly__day-label button.v-btn {
  display: none;
  margin: 0 !important;
}
.datePicker .v-card__text {
  padding: 20px !important;
}

.fc-day-sun {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #eb4f5d;
    }
  }
}
.fc-day-sat {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      color: #5a6ef6;
    }
  }
}
.fc-col-header-cell {
  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      color: inherit;
      font-size: 13px;
      font-weight: 500;
    }
  }
}
.fc .fc-daygrid-day-top {
  display: flex;
  flex-direction: row;
}
.fc .fc-daygrid-day-number {
  padding: 1px;
  position: relative;
  z-index: 4;
}
.fc .fc-daygrid-body-balanced .fc-daygrid-day-events {
  height: 95%;
}
.fc .fc-daygrid-event-harness {
  height: 100%;
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  height: 100%;
}

.custom-day-cell.other {
  position: absolute;
  padding-top: 1px;
  top: 8px;
  left: 12px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  border-radius: 100%;
  cursor: pointer;
}
.custom-day-cell {
  position: absolute;
  padding-top: 1px;
  top: 4px;
  left: 9px;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  border-radius: 100%;
  color: #23252e;
  cursor: pointer;
}
.custom-day-sunday,
.custom-day-holiday {
  color: #eb4f5d;
}
.custom-day-saturday {
  color: #5a6ef6;
}
.custom-day-highlight {
  background-color: #eb4c10;
  color: white;
}
.custom-day-selected {
  background-color: #e1e1e1;
}
.custom-content-cell {
  height: 100%;
}
.custom-event-content {
  width: 100%;
  height: 100%;
  .custom-content-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .content-dayju {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: absolute;
      top: 4px;
      height: 28px;
      color: #23252e;
      font-weight: 400;
      .txt-chn {
        font-size: 18px;
      }
      .txt-kor {
        font-size: 14px;
        margin-left: 5px;
      }
    }
  }
}
.content-lunar {
  font-size: 14px;
  font-weight: 300;
  color: #23252e;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  // top: 36px;
  top: 32%;
  left: 0;
  right: 0;
}
.content-holiday {
  color: #eb4f5d;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 0;
  top: 54%;
  right: 0;
  text-align: center;
  margin-bottom: 0 !important;
}
.content-season {
  color: #23252e;
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  left: 0;
  top: 76%;
  right: 0;
  text-align: center;
  margin-bottom: 0 !important;
}

.fc-header-toolbar {
  margin-bottom: 0 !important;
}
.custom-overlay {
  border-radius: 4px; /* 모서리를 둥글게 할 수 있습니다 */
  box-sizing: border-box; /* padding과 border를 포함하여 크기 계산 */
}
.fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-direction-rtl .fc-daygrid-event.fc-event-start {
  background-color: transparent;
}
table tbody tr {
  height: 3px;
}
</style>

<style lang="scss" scoped>
.full-calendar {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.v-toolbar {
  background-color: #fff;
}
.schedule-year-icon-arrow {
  height: 16px;
  width: 16px;
  background-color: #2c2c2e;
}
.v-toolbar__content > .v-btn:first-child {
  margin-inline-start: 0;
}
.v-toolbar__content > .v-toolbar-title {
  margin-inline-start: 0;
}
.txt-year {
  min-inline-size: auto;
}
</style>
