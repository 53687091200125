<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import useAuth from '@/composables/useAuth'
import useFunctions from '@/composables/useFunctions'
const { ...useFun } = useFunctions()
const { userInfo } = useAuth()

const radioContent = [
  {
    title: '3,000원',
    desc: '',
    value: '3000',
    subtitle: '',
  },
  {
    title: '5,000원',
    subtitle: '+5% 보너스',
    desc: '',
    value: '5000',
  },
  {
    title: '10,000원',
    subtitle: '+10% 보너스',
    desc: '',
    value: '10000',
  },
  {
    title: '25,000원',
    subtitle: '+10% 보너스',
    desc: '',
    value: '25000',
  },
  {
    title: '30,000원',
    subtitle: '+15% 보너스',
    desc: '',
    value: '30000',
  },
]

const selectedRadio = ref('3000')
const goodsAmt = ref(3000)

const updateGoodsName = (value) => {
  const selectedItem = radioContent.find((item) => item.value === value)
  if (selectedItem) {
    goodsAmt.value = `${selectedItem.value}`
  } else {
    goodsAmt.value = 0
  }
}

const router = useRouter()
const goFinish = () => {
  router.push('/mypage/subscrFinish')
}

const popTossPayment = ref(null)
const openPayment = () => {
  goPayment()
  popTossPayment.value = true
}
const close = () => {
  popTossPayment.value = false
}
// ------  결제위젯 초기화 ------
const clientKey = 'test_gck_oEjb0gm23POLLyEMkv1g8pGwBJn5'
const tossPayments = TossPayments(clientKey)
// 회원 결제
const customerKey = '3H3NxXvLfVUfZ_DPFyQ_61'
const widgets = tossPayments.widgets({
  customerKey,
})
const goPayment = async () => {
  // 비회원 결제
  // const widgets = tossPayments.widgets({ customerKey: TossPayments.ANONYMOUS });

  // ------ 주문의 결제 금액 설정 ------
  await widgets.setAmount({
    currency: 'KRW',
    value: goodsAmt.value,
  })

  await Promise.all([
    // ------  결제 UI 렌더링 ------
    widgets.renderPaymentMethods({
      selector: '#payment-method',
      variantKey: 'DEFAULT',
    }),
    // ------  이용약관 UI 렌더링 ------
    widgets.renderAgreement({ selector: '#agreement', variantKey: 'AGREEMENT' }),
  ])
}

const orderId = ref(null)

const doPayment = async () => {
  //orderId.value = 'P_' + userInfo?.id + '_' + goodsAmt.value
  orderId.value = 'P_' + useFun.getNowDate()

  await widgets.requestPayment({
    orderId: '202410101010-1',
    orderName: '포인트 충전' + goodsAmt,
    successUrl: window.location.origin + '/mypage/subscrFinish',
    failUrl: window.location.origin + '/mypage/subscrFinishFail',
    customerEmail: null,
    customerName: userInfo?.name,
    customerMobilePhone: null,
  })
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">낭만 포인트 충전</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 포인트 충전</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-tit-box st-right">
        <div class="tit">보유 낭만 포인트</div>
        <div class="sec-right"><i class="icon-point"></i><span>100,000 p</span></div>
      </div>
      <div class="gap-xxl"></div>
      <div class="my-line-01"></div>
      <div class="gap-xxl"></div>
      <CustomRadios
        class="radio-custom-01 st-point"
        v-model:selected-radio="selectedRadio"
        :radio-content="radioContent"
        :grid-column="{ sm: '4', cols: '12' }"
        @update:selectedRadio="updateGoodsName"
      />
    </div>
    <div class="gap-sm-2"></div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-pt-btm">
        <dl>
          <dt><span class="txt-block">• 충전 포인트</span></dt>
          <dd class="txt1">{{ goodsAmt }} p</dd>
        </dl>
        <dl>
          <dt><span class="txt-block">• 결제 금액</span></dt>
          <dd class="txt2">{{ goodsAmt }} 원</dd>
        </dl>
      </div>
      <div class="gap-xxl"></div>

      <VDialog
        v-model="popTossPayment"
        class="v-dialog-sm dialog-st1"
        max-width="800px"
        @click:outside="close"
      >
        <!-- 결제 UI -->
        <div id="payment-method"></div>

        <!-- 이용약관 UI -->
        <div id="agreement"></div>

        <!-- 결제하기 버튼 -->
        <VBtn type="submit" style="width: 80%; margin: 20px auto 40px auto" @click="doPayment">
          결제하기
        </VBtn>
      </VDialog>

      <div class="com-btn-box">
        <VBtn type="submit" style="width: 260px" @click="openPayment"> 결제하기 </VBtn>
      </div>
    </div>
    <div class="point-info-box">
      <div class="tit"><span>포인트 충전 안내</span> <i class="icon-info"></i></div>
      <ul>
        <li>
          낭만 포인트는 충전 후 7일 이내, 미 사용한 포인트만 결제취소가 가능합니다.
          <button class="btn-txt-line">취소안내 바로가기</button>
        </li>
        <li>낭만 포인트는 선택하신 포인트로 충전 시 추가 보너스 포인트가 지급됩니다.</li>
        <li>
          보너스 포인트로 지급받은 포인트는 환불 및 사용기간, 사용대상에서 제한이 있을 수 있습니다.
        </li>
        <li>
          낭만포인트 웹서비스에서 충전한 포인트는 낭만만세력 앱 서비스에서도 이용이 가능합니다.
        </li>
      </ul>
    </div>
  </div>
</template>
<style>
.v-overlay__content {
  background-color: #fff;
}
</style>
