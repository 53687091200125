<script setup>
import { onMounted, onUnmounted } from "vue";
import { useRouter } from "vue-router";
import { getEssay, patchEssayLike } from "@/api/EowayApi";
import { BoardTitleBox, Pagination } from "@/components/board"
import { IconLike } from "@/components/svg"
import { useStore } from 'vuex'

const props = defineProps({
  board: {
    type: String,
    default: ""
  },
  boardId: {
    type: Number,
    default: 0
  }
});

const router = useRouter();
const store = useStore();

/** 게시물 목록 */
const listData = ref([]);
/** 에세이 정렬 타입 */
const essaySortOptions = [
  { key: 'new', value: 'displayDate', text: '최신순' },
  { key: 'hit', value: 'views', text: '조회순' },
  { key: 'fav', value: 'likes', text: '좋아요순' }
]
/** 선택된 에세이 정렬 */
const selectedSort = ref(essaySortOptions[0].key);
/** 에세이 정렬 방향 상태 */
const sortDirections = ref({
  new: 'desc',
  hit: 'desc',
  fav: 'desc'
})
const totalPages = ref(0);
const totalElements = ref(0);
/** 인디케이터 동작 여부 */
const isLoading = ref(false);
/** 에세이 요청 파라미터 */
const paramData = ref({
  isOpenAppBoard: true,
  page: 1,
  size: 9,
  sort: 'displayDate.desc',
  systemId: import.meta.env.VITE_APP_SYSTEMID,
});

onMounted(async () => {
  store.dispatch('saveCurrentBoard', props.board);
  store.dispatch('saveEssayFocus', {key: 'list', value: true});
  const currentEssayPage = store.state.boards.currentEssayPage;
  
  if(currentEssayPage){
    paramData.value.page = currentEssayPage;
  }
  await getList();
});

onUnmounted(async () => {
  await store.dispatch('saveEssayFocus', {key: 'list', value: false});
})

/** 에세이 목록 요청 */
const getList = async () => {
  isLoading.value = true;
  try {
    const result = await getEssay({body: paramData.value});    

    if (result) {
      listData.value = result.content;
      totalPages.value = result.totalPages;
      totalElements.value = result.totalElements;
    }
  } catch (error) {
    console.error('Failed to fetch list:', error);
    alert('An error occurred while fetching data.');
  } finally {
    isLoading.value = false;
  }
};

/** 에세이 정렬 변경 */
const sort = (sortOption) => {
  if (selectedSort.value === sortOption.key)
    sortDirections.value[sortOption.key] = sortDirections.value[sortOption.key] === 'desc' ? 'asc' : 'desc';
  else sortDirections.value[sortOption.key] = 'desc';

  paramData.value.sort = `${sortOption.value}.${sortDirections.value[sortOption.key]}`;
  selectedSort.value = sortOption.key;

  getList();
};

/** 게시물로 이동  */
const goView = (essayId) => {
  // 에세이 상세 페이지는 따로 만들어야함 (전체화면)
  router.push(`/essay/${essayId}`);
};

/** 좋아요 버튼 클릭 - 이벤트 버블링 중단 */
const handleBubbling = (event, essayId) => {
  event.stopPropagation()
  patchLike(essayId)
}

/** 좋아요 업데이트 */
const patchLike = async (essayId) => {
  const result = await patchEssayLike(essayId)
  const clickedEssay = listData.value.find(item => item.essayId === essayId)
  clickedEssay.likesChecked = result.likeClicked ? 1 : 0
}

/** 페이지 변경 */
const changePage = (page) => {
  paramData.value.page = page;
  getList();
  store.dispatch('saveCurrentEssayPage', page);
};

const divStyle = (item) => {
  if (item.attachFiles && item.attachFiles.length) return null
  return {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    background: `linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.40) 100%), ${item.backgroundColor}`
  };
}
</script>

<template>
  <div class="">
    <BoardTitleBox :board="board">
      <template v-slot:add-on>
        <div class="sort-seach-box">
          <button
            v-for="option in essaySortOptions"
            :key="option.key"
            :class="{ active: selectedSort === option.key }"
            @click="sort(option)"
          >
            {{ option.text }}
          </button>
        </div>
      </template>
    </BoardTitleBox>

    <div class="grid-container">
      <div
        v-for="(item, index) in listData"
        :key="index"
        class="item"
        @click="goView(item.essayId)"
      >
        <div class="img-box">
          <img
            v-if="item.attachFiles && item.attachFiles.length"
            :src="item.attachFiles[0].publishedUri"
            alt="attached file"
          >
          <div v-else :style="divStyle(item)"></div>
          <button class="txt-like" @click="event => handleBubbling(event, item.essayId)">
            <IconLike
              class="icon-like"
              :class="{'active': item.likesChecked}"
            />
          </button>
        </div>
        <div class="txt-tit">
          {{ item.firstTitle }}
        </div>
        <div class="txt-date">
          {{ formatCustomDate(item.displayDate) }}
        </div>
      </div>
    </div>

    <Pagination
      :total-pages="totalPages"
      :current-page="paramData.page"
      @page-changed="changePage"
    />

    <div
      v-if="isLoading"
      class="loading-full-wrap"
    >
      <div class="inner-box">
        <div
          ref="loadingContainer"
          class="loading-container"
        >
          <v-progress-circular
            :size="50"
            :width="3"
            color="primary"
            indeterminate
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  opacity: 0.3;
  position: absolute;background: #fff;top:0;bottom:0;right:0;left:0;
}
</style>