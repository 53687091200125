<script setup>
import { ref, onMounted, watch } from 'vue'
import { saveMemo, delMemo, modMemo } from '@/api/UserGroup'

//popup
import PopGroupDel from '@/pages/saju/components/PopGroupDel.vue'
import fiveMixin from '@/composables/fiveMixin'
import useFunctions from '@/composables/useFunctions'

//Alert
import AlertComponent from '@/pages/manse/components/Alert.vue'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'

import IconArrow02 from '@/components/svg/IconArrow02.vue'

const { ...five } = fiveMixin()
const { ...useFun } = useFunctions()

const isDialogVisibleSetting = ref(true)
const isConfirmDialogVisibleMemo = ref(false)

const emit = defineEmits()

const props = defineProps({
  prSelectedMemo: {
    type: Object,
    default: null,
  },
  prMemoModeType: {
    type: String,
    default: null,
  },
  prSelectedMemoVal: {
    type: Object,
    default: null,
  },
  prTransdata: {
    type: Object,
    default: null,
  },
  prInputdata: {
    type: Object,
    default: null,
  },
})
const content = ref('')
const isPopGroupDelBox = ref(false)

const close = () => {
  emit('close')
}
const saveMemoFirstSubmit = () => {
  close()
  emit('saveMemoFirst', content.value)
}
const updateMemoEdit = () => {
  emit('updateMemoEdit')
}

function saveMemoSubmit() {
  if (content.value.length === 0) {
    showDialogAlert('내용을 입력해주세요.')
    return
  }
  const param = {}
  const paramUser = {}
  paramUser.guestUserId = props.prSelectedMemo.guestUserId
  param.guestUser = paramUser
  param.content = content.value
  saveMemo(param)
    .then(() => {
      showDialogAlert('등록 되었습니다.')
      close()
      const item = {}
      item.guestUserId = props.prSelectedMemo.guestUserId
      emit('saveMemoFirst', content.value)
      emit('listGroupMemo', props.prSelectedMemoVal)
    })
    .catch((error) => {
      alert('65>>' + error?.response?.data?.message)
    })
  //emit('saveMemoFirst', content.value);
}

onMounted(async () => {
  if (props.prSelectedMemoVal.content) {
    if (props.prMemoModeType !== 'reg') content.value = props.prSelectedMemoVal.content
  }
})

const deleteMemoDo = () => {
  //showDialogConfirm('메모를 삭제 하시겠습니까?', '', '삭제', deleteMemoDo, 'N')
  isConfirmDialogVisibleMemo.value = true
}
const closeConfrim = () => {
  isConfirmDialogVisibleMemo.value = false
}
const deleteMemo2 = () => {
  const formParams = {}
  formParams.guestUserMemoIds = props.prSelectedMemoVal.guestUserMemoId

  delMemo(formParams)
    .then(() => {
      closeConfrim()
      close()
      emit('listGroupMemoDel')
    })
    .catch((error) => {
      alert('delMemo error : ' + error.response?.data?.message)
    })
}

const updateMemo = () => {
  if (content.value.length === 0) {
    showDialogAlert('내용을 입력해주세요.')
    return
  }
  const param = {}
  const paramUser = {}
  paramUser.guestUserId = props.prSelectedMemoVal.guestUserId
  param.guestUser = paramUser
  param.content = content.value
  param.guestUserMemoId = props.prSelectedMemoVal.guestUserMemoId
  modMemo(param).then(() => {
    showDialogAlert('수정 되었습니다.')
    close()
    emit('listGroupMemo', '')
  })
}

const dialogAlert = ref(null)
const dialogTitle = ref('')
const dialogText = ref('')

const showDialogAlert = (title, text) => {
  dialogTitle.value = title
  dialogText.value = text
  dialogAlert.value.openAlertDialog()
}

const dialogTitle2 = ref('')
const dialogNextUrl = ref(null)
const dialogConfrim = ref(null)
const dialogCloseOpt = ref(null)

const showDialogConfirm = (title, title2 = '', text = '', nextUrl = null, closeOpt = null) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  if (text) dialogText.value = text
  if (nextUrl) dialogNextUrl.value = nextUrl
  if (closeOpt) dialogCloseOpt.value = closeOpt
  dialogConfrim.value.openConfirmDialog()
}
</script>
<template>
  <VDialog
    v-model="isDialogVisibleSetting"
    class="v-dialog-sm dialog-st1"
    max-width="400px"
    @click:outside="close"
  >
    <VCard class="br16">
      <DialogCloseBtn variant="text" size="default" @click="close" />
      <div class="pop-inner">
        <div class="pop-tit-box">
          <div class="btn-left">
            <button class="btn-back" @click="close()">
              <IconArrow02 />
            </button>
          </div>
          <div class="">메모 작성</div>
          <div class="close-button" @click="close()">Close</div>
        </div>
        <div class="memo-hd-box">
          <div class="tits">
            <div class="name-box">
              <span class="name">{{
                useFun.getUserNameTxt(
                  prSelectedMemo?.guestUser?.guestUserName ||
                    prInputdata?.name ||
                    prSelectedMemo?.guestUserName,
                )
              }}</span>
              <span
                >님 ({{
                  useFun.getGenderTxt(
                    prInputdata?.gender || prSelectedMemo?.gender || prSelectedMemoVal?.gender,
                  )
                }})
              </span>
              <span class="age-box">
                {{ prTransdata?.kor_age || prSelectedMemo?.ageKor || prSelectedMemoVal?.ageKor }}세
                (만{{
                  prTransdata?.man_age || prSelectedMemo?.age || prSelectedMemoVal?.age
                }}세)</span
              >
            </div>
          </div>
          <div class="txts mem-birth-box">
            <div class="">
              <span class="tits orange">양력</span>
              <span class="txts"
                >{{
                  useFun.formatCustomDateTxt(
                    prTransdata?.solar || prSelectedMemo?.birth || prSelectedMemoVal?.birth,
                  )
                }}
                ({{
                  useFun.getDayOfWeekOne(
                    prTransdata?.solar || prSelectedMemo?.birth || prSelectedMemoVal?.birth,
                  )
                }})
                {{
                  useFun.getBirthTimeTxt(
                    prTransdata?.birthTime ||
                      prSelectedMemo?.birthTime ||
                      prSelectedMemoVal?.birthTime,
                  )
                }}
              </span>
            </div>
            <div class="">
              <span class="tits blue">음력</span>
              <span class="txts">{{
                useFun.formatCustomDateTxt(
                  prTransdata?.lunar || prSelectedMemo?.lunar || prSelectedMemoVal?.lunar,
                )
              }}</span>
            </div>
          </div>
        </div>
        <div class="pop-body pd-large-btm">
          <div
            v-if="
              prMemoModeType === 'reg' ||
              prMemoModeType === 'reg_first' ||
              prMemoModeType === 'edit' ||
              prMemoModeType === 'list'
            "
            class="textbox-line"
          >
            <textarea v-model="content" class="textarea" maxlength="2000" />
            <div class="text-count">{{ content?.length }} / 2000</div>
          </div>
          <div v-if="prMemoModeType === 'view'" class="textbox-line no-line">
            {{ content }}
          </div>
          <div class="btn-com">
            <button
              v-if="prMemoModeType === 'edit'"
              class="btn-del st-memo"
              @click="deleteMemoDo()"
            >
              삭제
            </button>
            <button
              v-if="prMemoModeType === 'view'"
              class="btn-nor submit"
              @click="updateMemoEdit()"
            >
              수정
            </button>
            <button v-if="prMemoModeType === 'edit'" class="btn-nor submit" @click="updateMemo()">
              수정
            </button>
            <VBtn
              v-if="prMemoModeType === 'reg' || prMemoModeType === 'list'"
              :class="['btn-nor submit', { 'disabled-button': !content }]"
              :disabled="!content"
              @click="saveMemoSubmit()"
            >
              등록
            </VBtn>
            <VBtn
              v-if="prMemoModeType === 'reg_first'"
              :class="['btn-nor submit', { 'disabled-button': !content }]"
              :disabled="!content"
              @click="saveMemoFirstSubmit()"
            >
              등록
            </VBtn>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>

  <!-- 그룹 삭제확인 메세지 -->
  <PopGroupDel v-if="isPopGroupDelBox" @del="handleDel()" @dcancel="dcancdelPopGroup()" />

  <!--Alert창-->
  <AlertComponent ref="dialogAlert" :title="dialogTitle" :text="dialogText" />
  <VDialog
    v-model="isConfirmDialogVisibleMemo"
    class="v-dialog-alert"
    :class="isConfirmDialogVisibleMemo ? 'active' : ''"
  >
    <div class="back-full-layer">
      <div class="alert-wrap bottom">
        <div class="tit-box">
          <div class="">삭제 하시겠습니까?</div>
        </div>
        <div class="btn-box bottom">
          <button class="btn-nor bottom left cancel" @click="closeConfrim()">취소</button>
          <button class="btn-nor" @click="deleteMemo2()">삭제</button>
        </div>
      </div>
    </div>
  </VDialog>
</template>
