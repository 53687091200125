import { useRouter } from 'vue-router'

export default function useGoLink() {
  const router = useRouter()

  function goLink(path) {
    router.push(path)
  }

  return { goLink }
}
