<script setup>
  const PopGroupCloseBox = true;
  const emit = defineEmits();
  const del = () => {
    emit('del');
  };
  const cancel = () => {
    emit('dcancel');
  };
</script>
<template>
  <VDialog
    v-model="PopGroupCloseBox"
    class="v-dialog-sm dialog-st1"
    max-width="326px"
  >
    <VCard >
      <DialogCloseBtn
        variant="text"
        size="default"
        @click="close"
      />
      <div
        class="pop-inner"
        style="height: 170px;"
      >
        <div class="pop-body h-100">
          <div class="pop-body-tit">
            그룹을 삭제하시겠습니까? <br>저장된 사주명식은 미지정 그룹에서 <br>확인하세요.
          </div>
          <div class="btn-com bottom">
            <button
              class="btn-nor bottom left cancel"
              @click="cancel"
            >
              취소
            </button>
            <button
              class="btn-nor bottom right submit"
              @click="del"
            >
              삭제
            </button>
          </div>
        </div>
      </div>
    </VCard>
  </VDialog>
</template>