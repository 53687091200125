// src/store/modules/queries/users-manager.js
import { createStore } from "vuex";

const state = () => ({
  dates: [],
  fromDate : null,
  toDate: null,
  searchColumn: "id",
  searchKeyword: null,
  searchState: [],
  joinTypeAll: null,
  selectJoinType: [],
  searchJoinType: [],
  searchMobileType: null,
  selectMobileType: [],
  searchManseUsedCnts: [],
  searchSajuListCnts: [],
  orderUserId: null,
  orderManseUsed: null,
  orderSajuList: null,
  orderCreatedAt: null,
  orderLoggedAt: null,
  statusAll: null,
  page: 1,
  size: 10,
});

const mutations = {
  SET_SEARCH_COLUMN(state, column) {
    state.searchColumn = column;
  },
  // 추가적인 모든 필요한 변이들을 포함하여 기존 코드를 이식
};

const actions = {
  // Vue3에서 필요한 모든 비동기 작업을 수행
};

const getters = {
    minDate: (state) => {
        if (state.dates.length === 0) return null;
        const dates = [...state.dates];
        return dates.sort()[0];
      },
      searchColumn: (state) => state.searchColumn,
      filteredSearchState: (state) => {
        // 여기에 searchState를 필터링하는 로직을 구현할 수 있습니다.
        return state.searchState.filter(s => s.isActive);
      },
      activeJoinType: (state) => {
        // selectJoinType 배열에서 활성화된 join type만 반환
        return state.selectJoinType.filter(jt => jt.isActive);
      },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
