<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

const radioContent = [
  {
    title: '베이직',
    desc: '100건',
    value: 'basic',
    price: 990,
    price_3: 2970,
    price_6: 5940,
    price_9: 8910,
    price_12: 11880,
    icon_class: 'ico-basic icon-subscr',
  },
  {
    title: '클래식',
    desc: '200건',
    value: 'classic',
    price: 2200,
    price_3: 2970,
    price_6: 5940,
    price_9: 8910,
    price_12: 11880,
    icon_class: 'ico-classic icon-subscr',
  },
  {
    title: '에너지',
    desc: '300건',
    value: 'power',
    price: 3300,
    price_3: 2970,
    price_6: 5940,
    price_9: 8910,
    price_12: 11880,
    icon_class: 'ico-power icon-subscr',
  },
  {
    title: '하모니',
    desc: '무제한',
    value: 'orchestra',
    price: 6600,
    price_3: 2970,
    price_6: 5940,
    price_9: 8910,
    price_12: 11880,
    icon_class: 'ico-orchestra icon-subscr',
  },
]

const radioContent2 = [
  {
    title: '3개월 이용권',
    //discount: '',
    //per: '',
    //price: '월 900',
    value: 3,
    icon_class: '',
  },
  {
    title: '6개월 이용권',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 6,
    icon_class: '',
  },
  {
    title: '9개월 이용권',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 9,
    icon_class: '',
  },
  {
    title: '12개월 이용권',
    //discount: '11,800원',
    //per: '20%',
    //price: '연 9500',
    value: 12,
    icon_class: '',
  },
]

const selectedRadio = ref('basic')
const selectedRadio2 = ref(3)
const goodsName = ref('베이지 / 월 이용료')
const goodsAmt = ref(900)
const usePeriod = ref('')
const pointAmt = ref(1000)

const calculateEndDate = (duration) => {
  const startDate = new Date() // 현재 날짜
  let endDate = ''

  endDate = new Date(startDate.setMonth(startDate.getMonth() + duration))

  // 날짜 형식을 YYYY.MM.DD로 변환
  return `${endDate.getFullYear()}.${String(endDate.getMonth() + 1).padStart(2, '0')}.${String(
    endDate.getDate(),
  ).padStart(2, '0')}`
}

const updateGoodsName = (value) => {
  updateGoods()
}
const updateGoodsName2 = (value) => {
  updateGoods()
}

onMounted(() => {
  updateGoods() // 컴포넌트가 마운트될 때 초기값에 따라 업데이트
})

const updateGoods = () => {
  const selectedItem = radioContent.find((item) => item.value === selectedRadio.value)
  const selectedItem2 = radioContent2.find((item) => item.value === selectedRadio2.value)

  if (selectedItem) {
    goodsName.value = `${selectedItem.title} / ${selectedItem2.title}`
    if (selectedItem2.value === 3) {
      goodsAmt.value = selectedItem.price_3
    } else if (selectedItem2.value === 6) {
      goodsAmt.value = selectedItem.price_6
    } else if (selectedItem2.value === 9) {
      goodsAmt.value = selectedItem.price_9
    } else if (selectedItem2.value === 12) {
      goodsAmt.value = selectedItem.price_12 // 12개월 가격 추가
    } else {
      goodsAmt.value = selectedItem.price // 기본 가격
    }
    goodsAmt.value = goodsAmt.value - pointAmt.value
  } else {
    goodsName.value = ''
    goodsAmt.value = 0
  }
  usePeriod.value = `${new Date().toLocaleDateString()} ~ ${calculateEndDate(selectedItem2.value)}`
}

const router = useRouter()
const goFinish = () => {
  router.push('/mypage/subscrFinish')
}
// ------  결제위젯 초기화 ------
const clientKey = 'test_ck_DpexMgkW36ZA2M2K2wME3GbR5ozO'
// 회원 결제 (Bill_+금액+기간+userid)
const userid = sessionStorage.getItem('authUserId')
const customerKey = 'Bill_1_1_' + userid
const tossPayments = TossPayments(clientKey)
const payment = tossPayments.payment({
  customerKey,
})

const doBill = async () => {
  await payment.requestBillingAuth({
    method: 'CARD', // 자동결제(빌링)는 카드만 지원합니다
    successUrl: window.location.origin + '/mypage/subscrFinishBill', // 요청이 성공하면 리다이렉트되는 URL
    failUrl: window.location.origin + '/mypage/subscrFinishBillFail', // 요청이 실패하면 리다이렉트되는 URL
    customerEmail: 'customer123@gmail.com',
    customerName: '김토스',
  })
}
//mypage/subscrFinishBill?customerKey=3H3NxXvLfVUfZ_DPFyQ_61&authKey=bln_0dqwov9qwdx
//mypage/subscrFinishBill?customerKey=Bill_15078&authKey=bln_KKEp0AMP7wE
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit">낭만 저장소</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>구독</li>
          <li>낭만 저장소</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-tit-box">
        <div class="tit">구독 서비스</div>
        <div class="path">
          <ul>
            <li>
              낭만 저장소 구독 시 안전하게 저장하고, 언제 어느때나 사주기본 정보와 기본 분석을
              편리하게 이용하실 수 있습니다.
            </li>
          </ul>
        </div>
      </div>
      <div class="gap-xxl"></div>
      <div class="my-line-01"></div>
      <div class="gap-xxl"></div>
      <!-- './src/@core/components/app-form-elements/CustomRadiosWithIconImg.vue' 파일에 있음 -->
      <CustomRadiosWithIconImg
        class="radio-custom-01"
        v-model:selected-radio="selectedRadio"
        :radio-content="radioContent"
        :grid-column="{ sm: '3', cols: '12' }"
        @update:selectedRadio="updateGoodsName"
      />
      <div class="gap-xxxl"></div>
      <CustomRadiosWithIconImg
        class="radio-custom-01 st-repo-btm"
        v-model:selected-radio="selectedRadio2"
        :radio-content="radioContent2"
        :grid-column="{ sm: '3', cols: '12' }"
        @update:selectedRadio="updateGoodsName2"
      />
      <div class="gap-xxl"></div>
      <div class="my-line-01"></div>
      <div class="gap-xxl"></div>
      <div class="text-right">
        <div class="point-use-box">
          <span class="tit"> 낭만 포인트 </span>
          <span class="txt1"> (총 <em>5000P</em> 보유)</span>
          <span>
            <VTextField
              v-model="pointAmt"
              placeholder="낭만포인트"
              variant="underlined"
              size="5"
              type="text"
              style="text-align: right"
              @input="updateGoods()"
            />
          </span>
          <span>P</span>
        </div>
      </div>
    </div>

    <div class="gap-sm-2"></div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-pt-btm-2">
        <dl>
          <dt>• 상품명</dt>
          <dd class="txt1">{{ goodsName }}</dd>
        </dl>
        <dl>
          <dt>• 포인트 사용</dt>
          <dd class="txt2">
            <span>{{ pointAmt }}</span
            >P
          </dd>
        </dl>
        <dl>
          <dt>• 이용 기간</dt>
          <dd class="txt1">{{ usePeriod }}</dd>
        </dl>
        <dl>
          <dt>• 결제 금액</dt>
          <dd class="txt2">
            <span class="point-amt">{{ goodsAmt }}원</span>
          </dd>
        </dl>
      </div>
      <div class="gap-xxl"></div>
      <!-- <div class="bg-box-01">매월 자동결제에 동의합니다.</div> -->
      <div class="gap-xxl"></div>
      <div class="com-btn-box">
        <VBtn type="submit" style="width: 260px"> 결제하기 </VBtn>
      </div>
    </div>
    <div class="point-info-box">
      <div class="tit"><span>결제 안내</span> <i class="icon-info"></i></div>
      <ul>
        <li>
          저장소 요금제는 선택하신 기간동안 까지만 이용이 가능하며, 기간이 종료된 후에는 추가 결제를
          진행한 후 이용이 가능합니다.
        </li>
        <li>
          결제 후 미사용 시 7일 이내에 환불이 가능합니다.
          <button class="btn-txt-line">환불안내 바로가기</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.point-use-box {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .tit {
    font-weight: 700;
    margin: 0 5px 0 0;
    font-size: 1.2rem;
  }
  .txt1 {
    margin-right: 5px;
  }

  .v-field__input {
    text-align: right !important;
  }
}
</style>
