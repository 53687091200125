// composables/useAuth.js
import { useStore } from 'vuex';
const store = useStore()

const memberId = ref(null) 
const memberInfo = ref(null) 
const userInfo = ref({
  id:null
}) 

memberInfo.value = store?.getters;
memberId.value = memberInfo?.value?.["user/id"] || null;

export default function useAuth() {
  
  return {
    userInfo
  }
}
export const getUserLogin = async () => {
  userInfo.value = JSON.parse(sessionStorage.getItem("userInfo"));
  return userInfo?.value?.id;
}
