import ManseLayout from '@/layouts/manse.vue'; // 경로는 프로젝트 구조에 따라 조정

export default [
  {
    path: '/manse/:id/:pgmode',
    name: "manse",
    component: () => import('@/pages/manse/index.vue'),
    layout: ManseLayout, // 레이아웃 정의
  },
];
