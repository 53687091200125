<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

import { MyInquiryList, BoardView, BoardWrite } from '@/components/board'
import IconEmail from '@/components/svg/IconEmail.vue'
import IconHp from '@/components/svg/IconHp.vue'

const boardId = ref(2336) // 기본 문의내역 ID
const board = ref('inquiry') // 기본 보드 이름

const boardMapping = {
  counseling: 2508,
  inquiry: 2336,
}

const List = (boardName) => {
  board.value = boardName // 전달된 boardName으로 board 값을 설정
  boardId.value = boardMapping[boardName] || boardId.value // 매핑된 ID로 설정, 없으면 기존 ID 유지
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit fnt-big">문의내역</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>문의</li>
          <li>문의내역</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box st-time">
      <div class="tit">운영 시간 안내</div>
      <div class="my-exp-box">
        <div class="time-box">
          <div class="txt1">답변시간 평일 13:00 - 18:00 (주말, 공휴일 휴무)</div>
          <div class="txt2">답변시간 이후 접수 건은 운영시간 내 순차적으로 답변해 드립니다.</div>
        </div>
        <div class="email-box">
          <div class="txt"><span>info@dangamsoft.com</span> <IconEmail /></div>
          <div class="txt"><span>070-5165-3635</span><IconHp /></div>
        </div>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-tab-box">
        <button
          class="tab-button"
          :class="{ active: board === 'inquiry' }"
          @click="List('inquiry')"
        >
          1:1 문의
        </button>
        <button
          class="tab-button"
          :class="{ active: board === 'counseling' }"
          @click="List('counseling')"
        >
          명리상담
        </button>
      </div>
      <div class="">
        <MyInquiryList :board="board" :board-id="boardId" />
      </div>
    </div>
  </div>
</template>
<style>
.my-info-wrap .board-inquiry-box {
  display: none;
}
.my-info-wrap .board-tit-box {
  display: none;
}
</style>
