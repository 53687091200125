<template>
<div class="main">
<div class="body">
<h2><strong>[낭만만세력] 개인정보 처리방침</strong></h2>
<p>[낭만만세력]은 「개인정보 보호법」 제30조에 따라 정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p><br>
<section class="section">
<h3><strong>제1조(개인정보의 처리목적)</strong></h3>
<p>낭만만세력이 개인정보 보호법 제32조에 따라 등록 및 공개하는 개인정보의 처리목적은 다음과 같습니다.</p>
<ul>
    <li>개인정보 파일명 : 낭만만세력 개인정보처리방침</li>
    <li>개인정보의 처리목적 : 정보제공</li>
    <li>수집방법 : 홈페이지, 앱</li>
    <li>보유근거 : 고객님의 탈퇴기록 및 수사기관 등의 협조 등에 이용하기 위하여 보존</li>
    <li>보유기간 : 3년</li>
    <li>관련법령 : 신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년</li>
</ul><br>
</section>

<section class="section">
<h3><strong>제2조(정보주체와 법정대리인의 권리·의무 및 그 행사방법)</strong></h3>
<ul class="no-bullets">
    <li><span class="circle-num">①</span> 정보주체는 낭만만세력에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
    <li><span class="circle-num">②</span> 제1항에 따른 권리 행사는 낭만만세력에 대해 「개인정보 보호법」 시행령 제41조제1항에 따라 서면, 전자우편, FAX 등을 통하여 하실 수 있으며 낭만만세력은 이에 대해 지체 없이 조치하겠습니다.</li>
    <li><span class="circle-num">③</span> 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
    <li><span class="circle-num">④</span> 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</li>
    <li><span class="circle-num">⑤</span> 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
    <li><span class="circle-num">⑥</span> 낭만만세력은 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
</ul><br>
</section>
<section class="section">
<h3><strong>제3조(처리하는 개인정보의 항목 작성)</strong></h3>
<p><span class="circle-num">①</span> 낭만만세력은 다음의 개인정보 항목을 처리하고 있습니다.</p>
<ul>
    <li>필수항목 : 이름, 휴대전화번호, 로그인아이디, 비밀번호, 서비스 이용 기록, 접속 로그, 접속 IP 정보</li>
</ul><br>
</section>
<section class="section">
<h3><strong>제4조(개인정보의 파기)</strong></h3>
<ul class="no-bullets">
    <li><span class="circle-num">①</span> 낭만만세력은 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
    <li><span class="circle-num">②</span> 정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
        <ul>
            <li>법령 근거</li>
            <li>보존하는 개인정보 항목</li>
        </ul>
    </li>
    <li><span class="circle-num">③</span> 개인정보 파기의 절차 및 방법은 다음과 같습니다.
        <ol>
            <li>파기절차 : 낭만만세력은 파기 사유가 발생한 개인정보를 선정하고, 낭만만세력의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
            <li>파기방법 : 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</li>
        </ol>
    </li>
</ul><br>
</section>
<section class="section">
<h3><strong>제5조(개인정보의 안전성 확보 조치)</strong></h3>
<p>낭만만세력은 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다.</p>
<ul>
    <li>개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</li>
</ul><br>
</section>
<section class="section">
<h3><strong>제6조(개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항)</strong></h3>
<ul class="no-bullets">
    <li><span class="circle-num">①</span> 낭만만세력은 이용자에게 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</li>
    <li><span class="circle-num">②</span> 쿠키는 웹사이트를 운영하는데 이용되는 서버(https)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.
        <ul>
            <li>가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
            <li>나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다. 다만, 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
        </ul>
    </li>
</ul><br>
</section>
<section class="section">
<h3><strong>제7조(개인정보 보호책임자)</strong></h3>
<p><span class="circle-num">①</span> 낭만만세력은 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
<p>▶ 개인정보 보호책임자</p>
<ul>
    <li>성명 : 이진정</li>
    <li>연락처 : 070-5165-3635, work@dangamsoft.com</li>
    <li>※ 개인정보 보호 담당부서로 연결됩니다.</li>
</ul>
<p>▶ 개인정보 보호 담당부서</p>
<ul>
    <li>부서명 : 기획</li>
    <li>연락처 : 070-5165-3635</li>
</ul><br>
</section>
<section class="section">
<h3><strong>제8조(개인정보 열람청구)</strong></h3>
<p>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 낭만만세력은 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
<p>▶ 개인정보 열람청구 접수·처리 부서</p>
<ul>
    <li>부서명 : 기획팀</li>
    <li>담당자 : 이진정</li>
    <li>연락처 : 070-5165-3635</li>
</ul><br>
</section>
<section class="section">
<h3><strong>제9조(권익침해 구제방법)</strong></h3>
<p>정보주체는 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다.</p>
<ol>
    <li>개인정보분쟁조정위원회 : (국번없이) 1833-6972 (<a href="https://www.kopico.go.kr" target="_blank">www.kopico.go.kr</a>)</li>
    <li>개인정보침해신고센터 : (국번없이) 118 (<a href="https://privacy.kisa.or.kr" target="_blank">privacy.kisa.or.kr</a>)</li>
    <li>대검찰청 : (국번없이) 1301 (<a href="https://www.spo.go.kr" target="_blank">www.spo.go.kr</a>)</li>
    <li>경찰청 : (국번없이) 182 (<a href="https://cyberbureau.police.go.kr" target="_blank">cyberbureau.police.go.kr</a>)</li>
</ol>
<p>「개인정보보호법」제35조(개인정보의 열람), 제36조(개인정보의 정정·삭제), 제37조(개인정보의 처리정지 등)의 규정에 의한 요구에 대하여 공공기관의 장이 행한 처분 또는 부작위로 인하여 권리 또는 이익의 침해를 받은 자는 행정심판법이 정하는 바에 따라 행정심판을 청구할 수 있습니다.</p>
<p>※ 행정심판에 대해 자세한 사항은 중앙행정심판위원회 (<a href="https://www.simpan.go.kr" target="_blank">www.simpan.go.kr</a>) 홈페이지를 참고하시기 바랍니다.</p><br>
</section>
<section class="section">
<h3><strong>제10조(개인정보 처리방침 변경)</strong></h3>
<ul>
    <li><span class="circle-num">①</span> 이 개인정보처리방침은 2024년 2월 20일부터 적용됩니다.</li>
</ul><br> 
</section>
</div>
</div>
<!-- </div> -->
</template>
<script setup>
definePage({ meta: { layout: 'empty' } })
</script>

<style lang="scss" scoped>
.main{
    font-family: Pretendard;
    color: #333;
    margin: 50px 150px 50px 150px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .body{
        width: 900px;
    }
}
.section{
    color: #333;
    margin-bottom: 30px;

    ul {
    list-style-type: disc;
    padding-left: 30px;
    margin: 0;
    }
    ol {
    list-style-type: decimal;
    padding-left: 30px; 
    margin: 0; 
    }

    li {
    // font-size: 16px;
    line-height: 1.5;
    margin-bottom: 5px;
    }
    .circle-num{
        font-size: 10px;
    }
    .no-bullets{
        list-style-type: none !important;
    }
}

</style>