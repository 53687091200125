<script setup>
import { Inputbox, PopupModal, Timer } from '@/components/auth';
import useReset from '@/composables/useReset';
import lockIcon from '../../assets/images_manse/icon_password_lg.svg';

definePage({ meta: { layout: 'auth' } });

const {
  resetStep,
  resetUserData,
  displayText,
  authNotice,
  newPassword,
  userIdValidation,
  nameValidation,
  mobileValidation,
  authNoValidation,
  userPwdValidation,
  pwdCheckValidation,
  sendButtonText,
  isSendDisabled,
  isSentAuth,
  showAuthNo,
  isConfirmDisabled,  
  timerState,
  showPopup,
  popupMessage,
  popupButtonText,
  inputHandler,
  onClickSend,
  onClickConfirm,
  timerEnded,
  closePopup,
} = useReset();

let scrollPosition = 0;
const open = () => {
    scrollPosition = window.scrollY; // 현재 스크롤 위치 저장
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollPosition}px`; // 스크롤 위치 고정
    document.body.style.width = '100%'; // 가로 스크롤 방지
  };
const close = () => {
  document.body.style.position = ''; // 원래 상태로 복구
  document.body.style.top = '';
  window.scrollTo(0, scrollPosition); // 저장한 스크롤 위치로 복구
};
// 팝업 열렸을 때, 스크롤 방지
watch(
  () => showPopup.value,
  (popupNewValue) => {
    if (popupNewValue) open();
    else close();
  }
);
</script>

<template>
  <div class="inner">
    <div class="content">
      <div class="icon">
        <component :is="lockIcon" />
      </div>
      <div class="form-title">{{ displayText.formTitle }}</div>
      <div class="form-description" v-html="displayText.formDescription"></div>

      <div v-if="resetStep === 1" class="input-group">
        <Inputbox
          v-model="resetUserData.loginId"
          data-type="userId"
          type="text"
          placeholder="아이디를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="userIdValidation.validationMessage"
        />
        <Inputbox
          v-model="resetUserData.name"
          data-type="name"
          type="text"
          placeholder="이름을 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="nameValidation.validationMessage"
        />
        <Inputbox 
          v-model="resetUserData.mobileNo"
          :max-length="11"
          data-type="mobileNo"
          type="text"
          placeholder="휴대폰 번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="mobileValidation.validationMessage"
        >
          <template #outer>
            <button 
              v-if="true"
              class="btn action-btn"
              :class="{ active: !isSendDisabled }"
              :disabled="isSendDisabled"
              @click="onClickSend">
              {{ sendButtonText }}
            </button>
          </template>
        </Inputbox>
        <Inputbox
          v-if="showAuthNo"
          v-model="resetUserData.authNo"
          data-type="authNo"
          type="text"
          placeholder="인증번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="authNoValidation.validationMessage"
        >
          <template #inner>
            <Timer
              v-if="isSentAuth"
              :timer-state="timerState"
              :duration="180"
              @timer-ended="timerEnded"
            />
          </template>
        </Inputbox>
        <div v-if="isSentAuth" class="auth-notice" v-html="authNotice"></div>
      </div>

      <div v-if="resetStep === 2" class="input-group">
        <Inputbox
          v-model="newPassword.userPwd"
          :max-length="15"
          data-type="userPwd"
          type="password"
          placeholder="비밀번호를 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="userPwdValidation.validationMessage"
        />
        <Inputbox
          v-model="newPassword.pwdCheck"
          :max-length="15"
          data-type="pwdCheck"
          type="password"
          placeholder="비밀번호를 한 번 더 입력해 주세요."
          :clearable="true"
          :input-handler="inputHandler"
          :validationMessage="pwdCheckValidation.validationMessage"
        />
      </div>

      <ul v-if="resetStep === 2">
        <li>8~15자의 영문, 숫자, 특수문자 조합으로 입력해 주세요.</li>
        <li>연속적인 숫자 추출하기 쉬운 개인정보 및 아이디와 비슷한 비밀번호는 사용하지 않는 것을 권장합니다.</li>
      </ul>


      <button 
        class="confirm-btn btn"
        :class="{ active: isConfirmDisabled}"
        :disabled="!isConfirmDisabled"
        @click="onClickConfirm"
      >
        {{ displayText.confirmButton }}
      </button>
    </div>
  </div>

  <PopupModal
    v-if="showPopup" 
    :message="popupMessage" 
    :buttonText="popupButtonText" 
    :isVisible="showPopup"
    @close="closePopup"
    @confirm="confirmPopup"
  />
</template>