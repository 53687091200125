<template>
  <VSnackbar
    v-model="isSnackbarVisible"
    :timeout="snackbarTimeout"
    class="custom-snackbar"
    rounded
    location="bottom center"
    min-width="0"
    color="snackbar"
  >
     <!-- variant="tonal" -->
    <div class="message">{{ snackbarMessage }}</div>
  </VSnackbar>
</template>

<script setup>
import { ref } from 'vue';
import { defineExpose } from 'vue';

// 스낵바 상태 관리
const isSnackbarVisible = ref(false);
const snackbarMessage = ref('');
const snackbarTimeout = ref(3000);

// showSnackbar 함수 정의
function showSnackbar(message, timeout = 3000) {
  snackbarMessage.value = message;
  snackbarTimeout.value = timeout;
  isSnackbarVisible.value = true;
}

// 외부에서 showSnackbar 호출 가능하게 설정
defineExpose({ showSnackbar });
</script>

<style scoped lang="scss">

.custom-snackbar {
  z-index: 1000;
  align-items: center;
  justify-items: center;
}
.message{
    color: #ffffff;
    font-size: 14px;
  }
</style>
