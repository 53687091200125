export const generateHeaders = () => {
  const token = sessionStorage.getItem('refresh_token');
  const uid = sessionStorage.getItem('authUserId');
  if (token === null) {
    alert('로그인 후 이용 가능합니다.');
    window.location('/login');
    return false;
  }
  return {
    Authorization: `Bearer ${token}`,
    authUserId: uid,
  };
}
