<script setup>
  import { getGrouplist } from '@/api/UserGroup';
  import {ref, onMounted} from 'vue';
  import { DEV_MODE } from '@/main';

  const props = defineProps({
    prSelectedGroup: Object
  })

  const isDialogVisibleChange = ref(true);
  const emit = defineEmits();

  const isExpanded = ref(false);
  const shouldShowExpandButton = ref(false);
  const groupSetList = ref(null)

  const close = () => {
    emit('close');
    DEV_MODE&&console.log('close가 호출됨');
  };
  const listData = ref({});
  const getGroupList = async () => {
    try {
      const params = {};
      const data = await getGrouplist(params); // 그룹리스트 조회
      listData.value = data;
    } catch (error) {
      console.error('Failed to load users:', error);
    }
  }
  onMounted(async () => {
    await getGroupList();
    nextTick(() => {
        checkScroll();
      });
    console.log('selected그룹',props.prSelectedGroup)
  });
  const change = (selectItem) => {
    const selectedGroup = selectItem.userGroupId;
    const filteredData = listData.value.filter((item) => item.userGroupId === selectedGroup);
    emit('change', filteredData);
  }
  const openSetting = () => {
    emit('openSetting', 'type1');
  }

  const checkScroll = () => {
  if (groupSetList.value) {
    const fixedHeight = 258;
    const hasScroll = groupSetList.value.scrollHeight > fixedHeight;
    shouldShowExpandButton.value = hasScroll;
    DEV_MODE&&console.log('높이:', hasScroll);
  }
};

const expandList = () => {
  isExpanded.value = true;
  DEV_MODE&&console.log('버튼 눌림', isExpanded.value );
};
</script>
<template>
  <VDialog
    v-model="isDialogVisibleChange"
    class="v-dialog-sm dialog-st1 custom-dialog"
    :class="{expanded: isExpanded}"
    min-width="600px"
    hide-overlay
  >
    <VCard class="br16">
      <!-- <DialogCloseBtn
        variant="text"
        size="default"
        @click="close"
      /> -->
      <!-- <button class="dial-close" @click="close"></button> -->
      <div
        class="saju-pop-inner"
        :class="{expanded: isExpanded}"
      >
        <div class="pop-tit-box1" style="margin-bottom: 16px;">
          <button @click="openGroupAdd(0)" class="btn-group-setting">
            <i class="icon-setting xs" />그룹관리
          </button>
          <div class="title-text">
            그룹 선택
          </div>
          <!-- <div
            class="close-button"
            @click="close()"
          >
            Close
          </div> -->
          <button class="dial-close" @click="close"></button>
        </div>
        <div
          class="saju-pop-body1"
          :class="{expanded: isExpanded}"
        >
          <div
            class="pop-search-box1 "
            :class="{expanded: isExpanded}"
          >
            <div
              class="pop-group-list scroll"
              :class="{expanded: isExpanded}"
              ref="groupSetList">
              <div
                v-for="(item, index) in listData"
                :key="index"
                class="group-item"
                :class="{ 'active': prSelectedGroup?.userGroup?.userGroupId === item.userGroupId}"
                @click="change(item)"
              >
                {{ item.name }} ({{ item.memberCount }})
              </div>
            </div>
          </div>
          <button
            v-if="shouldShowExpandButton && !isExpanded"
            @click.stop="expandList"
            class="expand-btn"
            >
          펼쳐보기 +
        </button>
          <div class="notice-group">
            <span class="warn-text">*변경할 그룹을 선택해주세요. 선택된 그룹으로 바로 적용되므로 유의해주세요.</span>
          </div>
        </div>
      </div>
  </VCard>

  </VDialog>
</template>

<style lang="css" scoped>
/* .custom-dialog{
  display: flex;
  width: 600px;
  padding: 20px 0px 32px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
} */
</style>