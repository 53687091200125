<script setup>
import { ref, computed } from 'vue';
definePage({ meta: { layout: 'empty' } });

const userAgent = ref(navigator.userAgent);

const isIOS = computed(() => ['iPhone', 'iPad', 'iPod'].some(device => userAgent.value.includes(device)));
const isAndroid = computed(() => userAgent.value.includes('Android'));

onMounted(()=>{
    goToAppStore()
}  
)

const goToAppStore = () => {
  let appStoreLink = null;

  if (isIOS.value) {
    // iOS 앱 스토어 링크
    appStoreLink = 'https://apps.apple.com/kr/app/%EB%82%AD%EB%A7%8C-%EB%A7%8C%EC%84%B8%EB%A0%A5/id6462405118';
  } else if (isAndroid.value) {
    // Google Play 스토어 링크
    appStoreLink = 'https://play.google.com/store/apps/details?id=com.twofivedosajuapp&hl=ko';
  }

  if (appStoreLink) {
    // 앱 스토어 링크로 이동
    window.location.href = appStoreLink;
  } else {
    // 기기를 구분할 수 없는 경우 또는 지원하지 않는 기기인 경우
    alert('지원하지 않는 기기입니다.');
    console.warn('지원하지 않는 사용자 기기:', userAgent.value);
  }
};
</script>
