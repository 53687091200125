<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

import articleApi from '@/api/ArticleApi'
import { formatCustomDate } from '@/composables/functions'
import ConfirmComponent from '@/pages/manse/components/Confirm.vue'
import IconFile from '@/components/svg/IconFile.vue'

const store = useStore()
const route = useRoute()
const router = useRouter()

const id = ref(null)
const boardId = ref(null)

const boardMapping = {
  counseling: 2508,
  inquiry: 2336,
}

const listData = ref({})
const comment = ref({})
const memberId = ref({})
const categoryCtn = ref({})

onMounted(async () => {
  id.value = route.params?.id
  boardId.value = route.params?.boardId
  await getView()
})

const getView = async () => {
  try {
    const result = await articleApi().get(boardMapping[boardId.value], id.value, {})

    if (!result) {
      router.back()
      return
    }
    listData.value = result?.data
    comment.value = listData.value.comments[0]
    categoryCtn.value = listData.value.boardCategory.categoryCtn
    // memberInfo.value = store.getters[""]
    memberId.value = store.getters['user/id']
  } catch (error) {
    alert(error)
  }
}

const goList = () => {
  router.push('/mypage/inquiryList')
}

const delConfirm = async () => {
  showDialogConfirm('삭제하시겠습니까?', '삭제된 정보는 다시 볼 수 없습니다.', '삭제', async () => {
    await del()
  })
}

const del = async () => {
  //if (confirm('삭제하시겠습니까?')) {
  try {
    const result = await articleApi().delete(boardMapping[boardId.value], id.value, {})
    if (result) {
      goList()
    }
  } catch (error) {
    alert(error.response.data?.message)
  }
  //}
}
const dialogTitle = ref('')
const dialogTitle2 = ref('')
const dialogText = ref('')
const dialogNextUrl = ref(null)

const dialogConfrim = ref(null)

const showDialogConfirm = (title, title2 = '', text = '', onConfirm = () => {}) => {
  dialogTitle.value = title
  dialogTitle2.value = title2
  dialogText.value = text
  dialogNextUrl.value = onConfirm // 함수로 전달

  dialogConfrim.value.openConfirmDialog()
}

const newText = 'new' // 표시할 텍스트

const isNew = computed(() => {
  const createdAt = new Date(listData.value.createdAt)
  const today = new Date()

  // 날짜를 비교하기 위해 시간 부분을 0으로 설정
  createdAt.setHours(0, 0, 0, 0)
  today.setHours(0, 0, 0, 0)

  return createdAt.getTime() === today.getTime() // 날짜 비교
})

// 상태에 따른 텍스트 반환
const getStatusText = (status) => {
  const statusTextMap = {
    PENDING: '답변대기',
    IN_PROGRESS: '답변작성중',
    COMPLETED: '답변완료',
  }
  return statusTextMap[status] || ''
}
</script>

<template>
  <div class="my-info-wrap">
    <div class="my-tit-box com-bg-my-box h-small">
      <div class="tit fnt-big">문의내역상세</div>
      <div class="path">
        <ul>
          <li>홈</li>
          <li>마이페이지</li>
          <li>문의</li>
          <li>문의내역상세</li>
        </ul>
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box">
      <div class="my-inquiry-user">
        <div class="top-box">
          <div class="tit-box">
            <span class="tit">[{{ categoryCtn }}]</span>
            <span class="txt">{{ listData.articleTtl }}</span>
            <span class="new" v-if="isNew">{{ newText }}</span>
          </div>
          <div class="ans-box">
            <span> {{ getStatusText(listData.inquiryStatus) }}</span>
          </div>
        </div>
        <div class="date-box">{{ formatCustomDate(listData.createdAt) }}</div>
        <div class="gap-line-01"></div>
        <div class="cont-box">
          <div class="" style="white-space: pre" v-html="listData.articleCtn" />
        </div>
        <!-- <div class="alert-box">
          <span class="tit">답변 알림</span>
          <span class="txt">휴대폰 번호 : 010-1234-5678 / 이메일 : dangam12345@naver.com</span>
        </div>
        <div class=""></div> -->
      </div>
    </div>
    <div class="myinfo-box com-bg-my-box mg-none" v-if="comment">
      <div class="my-inquiry-admin">
        <div class="top-box">
          <div class="ans-name">낭만만세력 운영진</div>
          <div class="btn-box">
            <!-- <VBtn type="submit" class="custom-btn h-sm" @click="handleSubmit"> 재문의하기 </VBtn> -->
          </div>
        </div>
        <div class="date-box">{{ formatCustomDate(comment?.createdAt) }}</div>
        <div class="cont-box">
          <div class="" style="white-space: pre" v-html="comment?.name" />
        </div>
        <!-- <div class="file-box">
          <span class="tit">첨부파일</span>
          <span class="txt">
            <button class="btn-icon"><IconFile /> 낭만 만세력 운영가이드v1.jpg</button>
          </span>
        </div> -->
      </div>
    </div>
    <div class="gap-xxlg"></div>
    <div class="com-btn-box">
      <VBtn color="info" @click="goList" variant="outlined" class="custom-btn"> 목록 </VBtn>
      <VBtn type="submit" color="info" class="custom-btn" @click="delConfirm()"> 삭제 </VBtn>
    </div>
  </div>

  <ConfirmComponent
    ref="dialogConfrim"
    :title="dialogTitle"
    :title2="dialogTitle2"
    :text="dialogText"
    :nextUrl="dialogNextUrl"
  />
</template>
<style>
.mypage-body .board-inquiry-box {
  display: none;
}
.mypage-body .board-tit-box {
  display: none;
}
</style>
