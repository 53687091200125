<!-- src/pages/MyPage.vue -->
<script setup>
definePage({ meta: { layout: 'mypage' } })

</script>

<template>
  <div class="">
    테스트 마이페이지 2
  </div>
</template>
