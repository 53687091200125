export const galleryBackData = [
    // 합
    {
        backContent: [
            {title:'천간 합(合)이란', content:`글자대로 10개의 천간(天干)들이 서로 합(合)하는 것을 의미합니다. 
양의 기운과 음의 기운이 서로 합하려고 하는 것으로 서로 끈끈하게 하나가 되고자 하는 성분으로 볼 수 있습니다. 

합의 글자가 있다고 해도 모두 합으로 해석되지는 않습니다. 구성 조건에 따라 합이 될 수도, 성분으로만 남을 수도 있습니다. 

더불어 나(일간)에게 도움이 되는 합인지 나를 잃어버리고 상대의 힘에 끌려가 버리는 합인지 사주 전체 구성을 세밀히 분석하며 해석하게 됩니다.  
<div class="comment-area">
<div class="comment-line"></div>
 <span class="small-txt">* 천간 : 내 사주팔자 중 위쪽에 위치한 4글자를 천간(天干)이라고 합니다.</span> 
 <span class="small-txt">* 천간 위치 : 내 사주팔자 구성 중 위편에 있는 4글자를 천간(天干)이라고 합니다. </span>
 <span class="small-txt">* 천간의 종류 :  갑(甲), 을(乙,) 병(丙), 정(丁,) 무(戊), 기(己), 경(庚), 신(申, 임(壬), 계(癸)</span>
 </div>
 `},
            {title: '지지 합(合)이란', content: `12개의 지지(地支)들이 서로 합(合)하는 것을 의미합니다. 
지지에는 여러 종류의 합이 있습니다.

합의 글자가 있다고 해도 모두 합으로 해석되지는 않습니다. 구성 조건에 따라 합이 될 수도, 성분으로만 남을 수도 있습니다. 
 <div class="comment-area">
<div class="comment-line"></div>
 <span class="small-txt">* 지지 : 내 사주팔자 중 아래에 위치한 4글자를 지지(地支)라고 합니다.</span> `},
        ]
    },
    // 충
    {
        backContent: [
            {title: '천간 충(沖)이란', content: `서로 밀어내거나 부딪힘이 있는 천간이 만나 서로 충돌하는 것을 의미하며, 천간에는 4개의 충이 있습니다. 

물과 불, 나무와 쇠처럼 반대되는 기운 간의 충을 의미합니다. 

충은 충돌의 과정을 겪지만 견제와 변화의 역할을 한다면 충을 통해 더욱 새로운 질로 나아가는 긍정적 의미로 해석될 수 있습니다.

때문에 합은 좋고 충은 나쁘다는 개념으로 볼 수 없습니다.
충의 여부 또한 글자가 있는 것만으로 해석할 수 없고, 어떤 자리에 놓여있는지 주변 오행들과의 관계성을 통해 종합적으로 판단하게 됩니다.`},
            {title: '지지 충(干沖)이란', content: `지지 간의 충돌을 의미하며, 지지에는 6개의 충이 있습니다.`},
        ]
    },
    // 형/파/해
    {
        backContent: [
            {title: '천간 형(刑)이란', content: `천간끼리는 형(刑)이 없습니다.`},
            {title: '지지 형(刑)이란', content: `지지에 형을 뜻하는 세글자가 모인 것을 삼형, 
삼형 중에서 두 글자가 모인 것을 형, 
같은 글자 두 개가 모여 형을 발생시키는 것을 자형으로 표현합니다.

‘벌을 받는다. 갇힌다’ 등의 의미를 갖는 형살은 형벌을 받는다는 표현을 쓸 만큼 고뇌와 고통이 있다는 것을 뜻합니다.
고통속에서 잘못된 것들을 바로 잡아 가고 새롭게 나아가는 인고의 결과를 만들어간다는 것을 상징하고 있습니다. 

긍정의 힘으로 이끌어간다면 수술을 통해 병을 제거하거나, 뼈를 깍는 노력을 통해 최고의 영역에 우뚝 서게 되는 작용으로 이어질 수 있습니다.
강한 면모가 있는 만큼 법관, 군인, 경찰, 카리스마와 리더십을 갖춘 영역에서 활동할 수 있고 반대의 경우가 될 수도 있습니다.

형의 구성과 해석은 사주 원국의 종합적인 분석을 통해 면밀하게 살펴볼 수 있습니다.`},
            {title: '천간 파(破)란', content: ` 천간끼리는 형(刑)의 작용력이 없습니다. `},
            {title: '* 지지 파(破)란.', content: `‘‘파한다’라는 뜻으로 끝낸다, 분리, 단절을 의미하고 있습니다. 
합,형,충의 작용력에 비해 작지만 섬세한 분석에 활용되고 있습니다.

부정적인 것들과의 이별은 좋은 작용이지만, 작은 일로 끝날 일을 키우게 된다면 분리, 단절이 될 수 있으므로 잘 살펴서 예방할 것을 뜻하고 있습니다.

이동, 변화 등의 이벤트가 있을 때 파의 작용 여부를 살펴볼 수 있습니다.`},
            {title: '천간 해(害).', content: `천간끼리는 해(害)살이 없습니다.`},
            {title: '지지 해(害)살은', content: `‘해살은’ 큰 작용력으로 해석하지는 않지만 내면의 작용력을 살피고자 활용하고 있습니다.

주고 받는 것 없이 괜한 불만과 원망이 생기는 마음으로 해석할 수 있습니다. 
사주 안에 구성된 해살이 어떤 상황에서 적용되는가를 살펴 해소 방안을 찾는 지혜를 발휘할 수 있습니다.`},
        ]
    },
    // 신살
    {
        backContent: [
            {title: '천간 신살(新煞)이란', content: `천간끼리는 신살이 없습니다.`},
            {title: '지지 신살(神殺)', content: `사주 명식을 집으로 비유하면 신살은 집 내부의 장식이나 장치와 같은 매우 의미 있는 해석 요소입니다. 
격국의 높고 낮음과 부귀빈천(富貴貧賤)을 분석할 때 우선 사주의 희신을 찾고, 인간 삶에서의 희노애락(喜怒哀樂)과 운세의 길흉(吉凶)을 알기 위해서 신살론(神殺論)을 대입하고 있습니다.

신살(神殺)의 숫자는 300여 종에 달할 만큼 무분별하게 많으며 중복되는 신살 또한 많아서, 사주 감명에 필요한 신살을 골라 누구나 이해할 수 있도록 12가지 신살이 대표격으로 해석에 응용되고 있습니다.

사주 감명에 합형충파해(合刑沖破害)만 올바르게 적용해도 문제가 없으나, 신살(神殺)은 합충(合沖) 생극제화(生剋制化)의 연장선에 해당하기에 모든 역학인들은 신살(神殺)의 작용을 진중하게 살펴서 대입하고 있습니다.
<div class="comment-area">
<div class="comment-line"></div>
 <span class="small-txt"> * 격국 : 그 사람이 갖추고 있는 품위, 기품을 뜻합니다. 사주에서 가장 큰 힘을 갖고 있는 기운을 격국으로 해석하고 있습니다.</span>
 <span class="small-txt"> * 희신(喜神) : 나에게 길한 작용력 즉, 나에게 도움 되게 작용하는 기운을 뜻합니다.</span>
 <span class="small-txt"> * 생극제화(生剋制化) : 사주팔자 간 도움을 주고(생) 제어(극)하는 관계를 뜻합니다. 서로 생하는 순환구조를 비롯하여 생과 극이 균형 잡혀있는 경우 좋은 사주 구성으로 해석되고 있습니다.</span>
`},
        ]
    },
    // 육친
    {
        backContent: [
            {title: '육친(六親)이란', content: `육친은 내 사주의 8글자에 나타난 혈연관계, 즉 부, 모, 형, 제, 처, 자를 뜻하고 있습니다. 
여섯 가지 요소로 구분하여 살펴보기 때문에 육친이라고 표현하고 있습니다. 

사주의 주인인 나와 사주 안에 구성되어있는 인연의 관계를 해석하는 요소로 활용되고 있습니다.`},
        ]
    },
    // 원진/귀문/공망/태월
    {
        backContent: [
            {title: '원진(怨嗔)이란', content: `원진이란 한자에서 뜻하고 있는 말과 같이 원망할 원, 성낼 진으로 원망하고 성을 낸다는 뜻을 담고 있습니다. 

사주 안에 원진 관계가 있게 되면 해당 혈연 관계 또는 해당 인연과 불편함이 있다는 해석을 적용하게 됩니다. 
원진의 관계 살펴, 해당 육친 또는 상황으로부터 지혜롭게 대처할 수 있는 방법을 분석해볼 수 있습니다. `},
            {title: '귀문(鬼門)이란', content: `귀문관살이라고 표현되는 귀문이란 한자 뜻으로는 귀신이 드나드는 문과 같습니다. 
귀신이 드나든다는 표현을 적용할 만큼 귀신같은 능력을 갖고 있다는 해석으로도 통용되고 있습니다.

무언가를 감지해내는 능력이 탁월할 수 있어 귀문관살의 구성을 면밀히 살펴 통찰력과 직관력, 섬세함, 예술적 성향 등을 해석하고 있습니다. 
귀문과 원진은 겹치는 부분이 많습니다. 

긍정 요소와 부정적인 요소를 분석하기 위해 귀문의 특성이 해당 사주에 필요한 요소인지를 종합적으로 판단하는 분석력이 필요합니다. `},
{title: '공망(空亡)이란', content: `천간은 총 10개이고 지지는 총 12개인데 천간과 지지를 나란히 짝을 지어 나열하면 2개의 지지가 짝을 이루지 못하게 됩니다. 이렇게 천간과 짝을 이루지 못하고 남겨진 지지를 공망이라고 합니다.

    길흉이 없다는 뜻으로 해석하거나, 있지만 없는 것과 같이 ‘비워졌다’라는 뜻으로도 해석하고 있습니다.
    
    천간 : 갑, 을, 병, 정, 무, 기, 경, 신, 임, 계
    지지 : 자, 축, 인, 묘, 진, 사, 오, 미, 신, 유, 술, 해
    여기서 천간과 짝을 이루지 못한 술해가 공망이 됩니다.
    
    천간과 1대1로 매칭해서 남는 지지가 사주에 있는 경우 공망으로 해석됩니다. 
    자신의 사주 일간과 지지를 한쌍으로 시작해서 천간의 끝인 계수와 짝을 이루고 남는 지지 두 개를 공망으로 구분하는 구조입니다. 
    
    공망은 인연(육친)과의 관계 해석 및 십성 등에 대입하여 해석하고 있습니다.`},
        ]
    },
]